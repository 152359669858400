export default class Withdrawals {
  constructor(client) {
    this.client = client;
  }

  getAllCustomers() {
    return this.client.get("/customers_list");
  }

  fetchApproval(id) {
    return this.client.get(`payment/verify-withdrawal-request/${id}`);
  }
}
