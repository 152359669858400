import { createSlice } from "@reduxjs/toolkit";
import { deleteAdmin } from "../../actions/admin";

export const deleteAdminSlice = createSlice({
  name: "deleteAdmin",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
    adminDetails: null,
  },
  reducers: {
    clearState: state => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      state.adminDetails = null;
      return state;
    },
  },

  extraReducers: {
    [deleteAdmin.pending]: state => {
      state.loading = true;
    },
    [deleteAdmin.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.error = false;
      state.errors = null;
      state.loading = false;
      state.adminDetails = payload;
      return state;
    },
    [deleteAdmin.rejected]: (state, { payload }) => {
      state.errors = payload.error;
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});

export const { clearState, updateAdminDetails } = deleteAdminSlice.actions;

export const signinSelector = state => state.deleteAdmin;
