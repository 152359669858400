import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, Input } from "reactstrap";

export const Filter = ({ column, getSearchData, handleReset }) => {
  const [showButtons, setShowButtons] = useState(false);
  return (
    <div
      style={{ marginTop: 5 }}
      onPointerEnter={() => setShowButtons(true)}
      onPointerLeave={() => setShowButtons(false)}
    >
      <Dropdown isOpen={showButtons} className="" direction="up">
        {column.canFilter &&
          column.id !== "LobbyIcon" &&
          column.render("Filter")}
        {showButtons && (
          <DropdownMenu
            up={true}
            style={{
              padding: 0,
              background: "none",
              boxShadow: "none",
              marginLeft: "-30px",
            }}
          >
            <DropdownItem>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  style={{ padding: "5px 10px" }}
                  className="btn btn-secondary"
                  onClick={getSearchData}
                >
                  Search
                </button>
                <button
                  style={{ padding: "5px 10px", marginLeft: 10 }}
                  className="btn btn-danger"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </DropdownItem>
          </DropdownMenu>
        )}
      </Dropdown>
    </div>
  );
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  return (
    <Input
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`search (${length}) ...`}
    />
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};
