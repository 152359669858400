import { createSlice } from "@reduxjs/toolkit";
import { getAllRoles } from "../../actions/admin";

export const getAllRoleSlice = createSlice({
  name: "getAllRoles",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
    allRoles: [],
  },
  reducers: {
    clearState: state => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      state.adminDetails = null;
      return state;
    },
  },
  extraReducers: {
    [getAllRoles.pending]: state => {
      state.loading = true;
    },
    [getAllRoles.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.error = false;
      state.errors = null;
      state.loading = false;
      state.allRoles = payload;
      return state;
    },
    [getAllRoles.rejected]: (state, { payload }) => {
      state.errors = payload.error;
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});

export const { clearState } = getAllRoleSlice.actions;

export const getRolesSelector = state => state.getAllRoles;
