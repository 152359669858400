// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Col,
  Row,
  Label,
  Form,
  Input,
  FormFeedback,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Card } from "antd";

import * as Yup from "yup";
import { useFormik } from "formik";

import api from "../../../api";

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "../GroupsTable";
import CustomModal from "../../../reusables/CustomModal";

const BingoTypes = () => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [gameTypes, setBingoTypes] = useState([]);
  const [customModal, setCustomModal] = useState(null);
  const [error, setError] = useState(null);
  const [getBingoTypesLoading, setGetBingoTypesLoading] = useState(false);
  const [createBingoTypesLoading, setCreateBingoTypesLoading] = useState(false);
  const [createBingoTypesError, setCreateBingoTypesError] = useState(false);
  const [deleteBingoTypeLoading, setDeleteBingoTypeLoading] = useState(false);
  const [editBingoType, setEditBingoType] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Bingo Type",
        accessor: "game_type",
      },
      {
        Header: "Reward Round Count",
        accessor: "reward_round_count",
      },
      {
        Header: "Row Count",
        accessor: "row_count",
      },
      {
        Header: "Column Count",
        accessor: "column_count",
      },
      {
        Header: "Bingo Duration (secs)",
        accessor: "game_duration",
      },
      {
        Header: "Bingo Algo Code",
        accessor: "game_algo_code",
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  setOpenAddGroupModal(true);
                  setEditBingoType(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleDeleteBingoType(data);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getAllBingoTypes();
  }, []);

  useEffect(() => {
    error &&
      setTimeout(() => {
        setError(null);
      }, 3000);
  });

  const getAllBingoTypes = () => {
    setGetBingoTypesLoading(true);
    api.games
      .getAllBingoTypes()
      .then(res => {
        setGetBingoTypesLoading(false);
        if (res.status === 200) {
          setBingoTypes(res.data.data);
        }
      })
      .catch(err => {
        setGetBingoTypesLoading(false);
        setError(err.response?.data.message || err.message);
      });
  };

  const createBingoType = payload => {
    setCreateBingoTypesLoading(true);
    api.games
      .createBingoType(payload)
      .then(res => {
        setCreateBingoTypesLoading(false);
        if (res.status === 200) {
          getAllBingoTypes();
          setCustomModal(
            <CustomModal
              title="Succes!"
              content="Bingo Type Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setEditBingoType(false);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setEditBingoType(false);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoTypesLoading(false);
        setCreateBingoTypesError(err.response?.data.message || err.message);
      });
  };

  const handleEditBingoType = (id, payload) => {
    setCreateBingoTypesLoading(true);
    api.games
      .updateBingoType(id, payload)
      .then(res => {
        setCreateBingoTypesLoading(false);
        if (res.status === 200) {
          getAllBingoTypes();
          setCustomModal(
            <CustomModal
              title="Succes!"
              content="Bingo Type Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setEditBingoType(false);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setEditBingoType(false);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoTypesLoading(false);
        setCreateBingoTypesError(err.response?.data.message || err.message);
      });
  };

  const deleteBingoType = id => {
    setDeleteBingoTypeLoading(true);
    api.games
      .deleteBingoType(id)
      .then(res => {
        setDeleteBingoTypeLoading(false);
        if (res.status === 200) {
          getAllBingoTypes();
          setCustomModal(
            <CustomModal
              title="Succes!"
              content="Bingo Type Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setDeleteBingoTypeLoading(false);
        setError(err.response?.data.message || err.message);
      });
  };

  const handleDeleteBingoType = data => {
    setCustomModal(
      <CustomModal
        title="Delete Bingo Type?"
        loading={deleteBingoTypeLoading}
        content={<p>Do you want to delete this game type?</p>}
        onOk={() => {
          deleteBingoType(data.game_type_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      game_type: editBingoType?.game_type || "",
      reward_round_count: editBingoType?.reward_round_count || "",
      row_count: editBingoType?.row_count || "",
      column_count: editBingoType?.column_count || "",
      game_algo_code: editBingoType?.game_algo_code || "",
      game_duration: editBingoType?.game_duration || "",
    },
    validationSchema: Yup.object({
      game_type: Yup.string().required("Please enter game type"),
      game_duration: Yup.number().required("Please enter game duration"),
      reward_round_count: Yup.number().required(
        "Please enter total number of Calls"
      ),
      row_count: Yup.number().required("Please enter number of rows"),
      column_count: Yup.number().required("Please enter number of columns"),
      game_algo_code: Yup.number().required("Please select algorithm code"),
    }),
    onSubmit: values =>
      editBingoType
        ? handleEditBingoType(editBingoType.game_type_id, values)
        : createBingoType({
            ...values,
            game_algo_code: Number(values.game_algo_code),
          }),
  });

  useEffect(() => {
    error &&
      setTimeout(() => {
        setError(null);
      }, 3000);
    createBingoTypesError &&
      setTimeout(() => {
        setCreateBingoTypesError(null);
      }, 3000);
  }, [error, createBingoTypesError]);

  //meta title
  document.title = "Bingo Types";

  return (
    <div className="page-content">
      {error && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!error}
          toggle={() => setError(null)}
        >
          {error}
        </Alert>
      )}
      {customModal}
      <div className="container-fluid">
        <Breadcrumbs title="Bingo" breadcrumbItem="Bingo Types" />

        <Card loading={getBingoTypesLoading}>
          <TableContainer
            columns={columns}
            data={gameTypes}
            addButtonText="Create Bingo Type"
            isGlobalFilter={true}
            isAddOptions={true}
            customPageSize={10}
            setShowDetailsModal={() => {}}
            className="custom-header-css"
            addNewGroup={() => setOpenAddGroupModal(true)}
          />
        </Card>
      </div>

      <Modal
        size="lg"
        isOpen={openAddGroupModal}
        toggle={() => setOpenAddGroupModal(prev => !prev)}
      >
        {createBingoTypesError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!createBingoTypesError}
            toggle={() => setCreateBingoTypesError(null)}
          >
            {createBingoTypesError}
          </Alert>
        )}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add New Bingo Type
          </h5>
          <button
            type="button"
            onClick={() => {
              setEditBingoType(false);
              setOpenAddGroupModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="mb-3">
              <Label htmlFor="game_type">
                <b>Bingo Type</b>
              </Label>
              <Input
                type="text"
                className="form-control"
                id="game_type"
                name="game_type"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.game_type || ""}
                invalid={
                  validation.touched.game_type && validation.errors.game_type
                    ? true
                    : false
                }
              />
              {validation.touched.game_type && validation.errors.game_type ? (
                <FormFeedback type="invalid">
                  {validation.errors.game_type}
                </FormFeedback>
              ) : null}
            </div>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="reward_round_count">
                    <b>Reward Round Count</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="reward_round_count"
                    name="reward_round_count"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.reward_round_count || ""}
                    invalid={
                      validation.touched.reward_round_count &&
                      validation.errors.reward_round_count
                        ? true
                        : false
                    }
                  />
                  {validation.touched.reward_round_count &&
                  validation.errors.reward_round_count ? (
                    <FormFeedback type="invalid">
                      {validation.errors.reward_round_count}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="game_algo_code">
                    <b>Bingo Algorithm Code</b>
                  </Label>
                  <Input
                    type="select"
                    className="form-control"
                    id="game_algo_code"
                    name="game_algo_code"
                    placeholder="Enter code"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.game_algo_code || ""}
                    invalid={
                      validation.touched.game_algo_code &&
                      validation.errors.game_algo_code
                        ? true
                        : false
                    }
                  >
                    <option defaultValue value="">
                      Select one...
                    </option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </Input>
                  {validation.touched.game_algo_code &&
                  validation.errors.game_algo_code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.game_algo_code}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="row_count">
                    <b>Row Count</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="row_count"
                    name="row_count"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.row_count || ""}
                    invalid={
                      validation.touched.row_count &&
                      validation.errors.row_count
                        ? true
                        : false
                    }
                  />
                  {validation.touched.row_count &&
                  validation.errors.row_count ? (
                    <FormFeedback type="invalid">
                      {validation.errors.row_count}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="column_count">
                    <b>Column Count</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="column_count"
                    name="column_count"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.column_count || ""}
                    invalid={
                      validation.touched.column_count &&
                      validation.errors.column_count
                        ? true
                        : false
                    }
                  />
                  {validation.touched.column_count &&
                  validation.errors.column_count ? (
                    <FormFeedback type="invalid">
                      {validation.errors.column_count}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="game_duration">
                    <b>Bingo Duration</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="game_duration"
                    name="game_duration"
                    placeholder="Enter duration (secs)"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.game_duration || ""}
                    invalid={
                      validation.touched.game_duration &&
                      validation.errors.game_duration
                        ? true
                        : false
                    }
                  />
                  {validation.touched.game_duration &&
                  validation.errors.game_duration ? (
                    <FormFeedback type="invalid">
                      {validation.errors.game_duration}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setEditBingoType(false);
              setOpenAddGroupModal(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            loading={createBingoTypesLoading ? "true" : "false"}
            disabled={createBingoTypesLoading}
            onClick={validation.handleSubmit}
          >
            Save changes
          </button>
        </div>
      </Modal>
    </div>
  );
};
BingoTypes.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default BingoTypes;
