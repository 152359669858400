export default class Slots {
  constructor(client) {
    this.client = client;
  }

  // All Slots
  getAllSlotGames(limit, page) {
    return this.client.get(
      `/partner/get-slot-games-with-search?limit=${limit}&page=${page}`
    );
  }
  // toggle game state
  toggleGame(payload) {
    return this.client.post(`/partner/toggle-game-slot-state`, payload);
  }
  // update game order
  updateGameOrder(payload) {
    return this.client.post(`partner/update-game-slot-order`, payload);
  }
}
