import ngflag from "../assets/images/flags/nigeria.png";
import ghanaflag from "../assets/images/flags/ghana.png";
import drcFlag from "../assets/images/flags/drc.png";
import zambiaflag from "../assets/images/flags/zambia.png";
import usflag from "../assets/images/flags/us.jpg";

const languages = {
  ng: {
    label: "Nigeria",
    flag: ngflag,
  },
  gh: {
    label: "Ghana",
    flag: ghanaflag,
  },
  cd: {
    label: "DR Congo",
    flag: drcFlag,
  },
  zm: {
    label: "Zambia",
    flag: zambiaflag,
  },
  global: {
    label: "Global",
    flag: usflag,
  },
};

export default languages;
