// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Col,
  Row,
  Label,
  Form,
  Input,
  FormFeedback,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import api from "../../../api";

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "../GroupsTable";
import { Card } from "antd";
import CustomModal from "../../../reusables/CustomModal";

const RTP = () => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [gameRTPs, setBingoRTPs] = useState([]);
  const [getBingoRTPLoading, setGetBingoRTPLoading] = useState(false);
  const [getRTPError, setGetRTPError] = useState(null);
  const [createBingoRTPLoading, setCreateBingoRTPLoading] = useState(false);
  const [createRTPError, setCreateRTPError] = useState(null);
  const [editBingoRTP, setEditBingoRTP] = useState(null);
  const [deleteBingoRTPLoading, setDeleteBingoRTPLoading] = useState(false);
  const [customModal, setCustomModal] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "RTP Name",
        accessor: "rtp_name",
      },
      {
        Header: "Min Players",
        accessor: "min_player",
      },
      {
        Header: "Max Players",
        accessor: "max_player",
      },
      // {
      //   Header: "Bets Per Game",
      //   accessor: "bets_per_game",
      // },
      {
        Header: "RTP Percentage",
        accessor: "rtp_percentage",
      },
      {
        Header: "Actions",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  setOpenAddGroupModal(true);
                  setEditBingoRTP(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleDeleteRTP(data);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      rtp_name: editBingoRTP?.rtp_name || "",
      min_player: editBingoRTP?.min_player || "",
      max_player: editBingoRTP?.max_player || "",
      rtp_percentage: editBingoRTP?.rtp_percentage || "",
      // bets_per_game: editBingoRTP?.bets_per_game || "",
    },
    validationSchema: Yup.object({
      rtp_name: Yup.string().required("Please enter RTP name"),
      min_player: Yup.number().required("Please enter minimum players"),
      max_player: Yup.number()
        .required("Please enter minimum players")
        .moreThan(Yup.ref("min_player")),
      rtp_percentage: Yup.number().required("Please enter RTP percentage"),
      // bets_per_game: Yup.number().required("Please enter bets per game"),
    }),
    onSubmit: values => {
      const editValues = values;
      editBingoRTP
        ? handleEditRTP(editBingoRTP.game_rtp_settings_id, editValues)
        : createRTP(values);
    },
  });

  const getAllBingoRTPs = () => {
    setGetBingoRTPLoading(true);
    api.games
      .getAllBingoRTP()
      .then(res => {
        setGetBingoRTPLoading(false);
        if (res.status === 200) {
          setBingoRTPs(res.data.data);
        }
      })
      .catch(err => {
        setGetBingoRTPLoading(false);
        setGetRTPError(err.response?.data.message || err.message);
      });
  };

  const createRTP = payload => {
    setCreateBingoRTPLoading(true);
    api.games
      .createBingoRTP(payload)
      .then(res => {
        setCreateBingoRTPLoading(false);
        if (res.status === 201) {
          getAllBingoRTPs();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo RTP Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoRTPLoading(false);
        setCreateRTPError(err.response?.data.message || err.message);
      });
  };

  const handleEditRTP = (id, payload) => {
    setCreateBingoRTPLoading(true);
    api.games
      .updateBingoRTP(id, payload)
      .then(res => {
        setCreateBingoRTPLoading(false);
        if (res.status === 200) {
          getAllBingoRTPs();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo RTP Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                setEditBingoRTP(null);
                validation.handleReset();
              }}
              closeModal={() => {
                setEditBingoRTP(null);
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoRTPLoading(false);
        setCreateRTPError(err.response?.data.message || err.message);
      });
  };

  const deleteBingoRTP = id => {
    setDeleteBingoRTPLoading(true);
    api.games
      .deleteBingoRTP(id)
      .then(res => {
        setDeleteBingoRTPLoading(false);
        if (res.status === 200) {
          getAllBingoRTPs();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo RTP Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setDeleteBingoRTPLoading(false);
        setGetRTPError(err.response?.data.message || err.message);
      });
  };

  const handleDeleteRTP = data => {
    setCustomModal(
      <CustomModal
        title="Delete Bingo RTP?"
        loading={deleteBingoRTPLoading}
        content={<p>Do you want to delete this RTP setting?</p>}
        onOk={() => {
          deleteBingoRTP(data.game_rtp_settings_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  useEffect(() => {
    getAllBingoRTPs();
  }, []);
  useEffect(() => {
    getRTPError &&
      setTimeout(() => {
        setGetRTPError(null);
      }, 3000);
  });
  useEffect(() => {
    createRTPError &&
      setTimeout(() => {
        setCreateRTPError(null);
      }, 3000);
  });

  //meta title
  document.title = "Bingo RTP";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Bingo" breadcrumbItem="Bingo RTP" />
        {getRTPError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!getRTPError}
            toggle={() => setGetRTPError(null)}
          >
            {getRTPError}
          </Alert>
        )}
        <Card loading={getBingoRTPLoading}>
          <TableContainer
            columns={columns}
            data={gameRTPs}
            setShowDetailsModal={() => {}}
            addButtonText="Create RTP Setting"
            isGlobalFilter={true}
            isAddOptions={true}
            customPageSize={10}
            className="custom-header-css"
            addNewGroup={() => setOpenAddGroupModal(true)}
          />
        </Card>
      </div>

      {customModal}

      <Modal
        size="lg"
        isOpen={openAddGroupModal}
        toggle={() => {
          setEditBingoRTP(null);
          setOpenAddGroupModal(false);
        }}
      >
        {createRTPError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!createRTPError}
            toggle={() => setCreateRTPError(null)}
          >
            {createRTPError}
          </Alert>
        )}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add New RTP Setting
          </h5>
          <button
            type="button"
            onClick={() => {
              setEditBingoRTP(null);
              setOpenAddGroupModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="mb-3">
              <Label htmlFor="rtp_name">
                <b>RTP Name</b>
              </Label>
              <Input
                type="text"
                className="form-control"
                id="rtp_name"
                name="rtp_name"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.rtp_name || ""}
                invalid={
                  validation.touched.rtp_name && validation.errors.rtp_name
                    ? true
                    : false
                }
              />
              {validation.touched.rtp_name && validation.errors.rtp_name ? (
                <FormFeedback type="invalid">
                  {validation.errors.rtp_name}
                </FormFeedback>
              ) : null}
            </div>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="min_player">
                    <b>Min. Players</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="min_player"
                    name="min_player"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.min_player || ""}
                    invalid={
                      validation.touched.min_player &&
                      validation.errors.min_player
                        ? true
                        : false
                    }
                  />
                  {validation.touched.min_player &&
                  validation.errors.min_player ? (
                    <FormFeedback type="invalid">
                      {validation.errors.min_player}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="max_player">
                    <b>Max. Players</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="max_player"
                    name="max_player"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.max_player || ""}
                    invalid={
                      validation.touched.max_player &&
                      validation.errors.max_player
                        ? true
                        : false
                    }
                  />
                  {validation.touched.max_player &&
                  validation.errors.max_player ? (
                    <FormFeedback type="invalid">
                      {validation.errors.max_player}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="rtp_percentage">
                    <b>RTP Percentage</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="rtp_percentage"
                    name="rtp_percentage"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.rtp_percentage || ""}
                    invalid={
                      validation.touched.rtp_percentage &&
                      validation.errors.rtp_percentage
                        ? true
                        : false
                    }
                  />
                  {validation.touched.rtp_percentage &&
                  validation.errors.rtp_percentage ? (
                    <FormFeedback type="invalid">
                      {validation.errors.rtp_percentage}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              {/* <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="bets_per_game">
                    <b>Bets per game</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="bets_per_game"
                    name="bets_per_game"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.bets_per_game || ""}
                    invalid={
                      validation.touched.bets_per_game &&
                      validation.errors.bets_per_game
                        ? true
                        : false
                    }
                  />
                  {validation.touched.bets_per_game &&
                  validation.errors.bets_per_game ? (
                    <FormFeedback type="invalid">
                      {validation.errors.bets_per_game}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col> */}


              {/* <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="game_profile">
                    <b>Bingo Profile</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="game_profile"
                    name="game_profile"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.game_profile || ""}
                    invalid={
                      validation.touched.game_profile &&
                      validation.errors.game_profile
                        ? true
                        : false
                    }
                  />
                  {validation.touched.game_profile &&
                  validation.errors.game_profile ? (
                    <FormFeedback type="invalid">
                      {validation.errors.game_profile}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col> */}
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setEditBingoRTP(null);
              setOpenAddGroupModal(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            loading={createBingoRTPLoading ? "true" : "false"}
            onClick={validation.handleSubmit}
          >
            Save changes
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default RTP;
