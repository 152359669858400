import { createSlice } from "@reduxjs/toolkit";
import { registerNewAdmin } from "../../actions/admin";

export const registerAdminSlice = createSlice({
  name: "registerAdmin",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
  },
  reducers: {
    clearState: state => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
  },
  extraReducers: {
    [registerNewAdmin.pending]: state => {
      state.loading = true;
    },
    [registerNewAdmin.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
    [registerNewAdmin.rejected]: (state, { payload }) => {
      state.errors = payload.error;
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});
export const { clearState } = registerAdminSlice.actions;

export const registerAdminSelector = state => state.registerAdmin;
