// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Col,
  Row,
  Label,
  Form,
  Alert,
  Input,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "../GroupsTable";
import api from "../../../api";
import CustomModal from "../../../reusables/CustomModal";
import { Card } from "antd";

const JackpotConfigs = () => {
  const [allJackpotConfigs, setAllJackpotConfigs] = useState([]);
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [patternParams, setPatternParams] = useState([]);
  const [customModal, setCustomModal] = useState(null);
  const [gamePatterns, setBingoPatterns] = useState([]);

  const [getConfigsError, setGetConfigsError] = useState(null);
  const [getConfigsLoading, setGetConfigsLoading] = useState(false);
  const [createConfigLoading, setCreateConfigLoading] = useState(false);
  const [createConfigError, setCreateConfigError] = useState(false);
  const [deleteConfigLoading, setDeleteConfigLoading] = useState(false);
  const [editConfig, setEditConfig] = useState(null);

  const [distributionBreakdown, setDistributionBreakdown] = useState({});

  useEffect(() => {
    getAllJackpotConfigs();
    getAllBingoPatterns();
  }, []);

  const getAllJackpotConfigs = () => {
    setGetConfigsLoading(true);
    api.games
      .getAllJackpotConfigs()
      .then(res => {
        setGetConfigsLoading(false);
        if (res.status === 200) {
          setAllJackpotConfigs(res.data.data.jp_configs);
        }
      })
      .catch(err => {
        setGetConfigsLoading(false);
        setGetConfigsError(err.response?.data.message || err.message);
      });
  };
  const getAllBingoPatterns = () => {
    api.games
      .getAllJackpotPatterns()
      .then(res => {
        if (res.status === 200) {
          setBingoPatterns(res.data.data);
        }
      })
      .catch(err => {
        setGetConfigsError(err.response?.data.message || err.message);
      });
  };

  const createConfig = payload => {
    setCreateConfigLoading(true);
    api.games
      .createJackpotConfig(payload)
      .then(res => {
        setCreateConfigLoading(false);
        if (res.status === 201) {
          getAllJackpotConfigs();
          setCustomModal(
            <CustomModal
              title="Succes!"
              content="Jackpot Config Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateConfigLoading(false);
        setCreateConfigError(err.response?.data.message || err.message);
      });
  };

  const handleEditConfig = (id, payload) => {
    setCreateConfigLoading(true);
    api.games
      .updateJackpotConfig(id, payload)
      .then(res => {
        setCreateConfigLoading(false);
        if (res.status === 200) {
          getAllJackpotConfigs();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Jackpot Config Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                setEditConfig(null);
                validation.handleReset();
              }}
              closeModal={() => {
                setEditConfig(null);
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateConfigLoading(false);
        setCreateConfigError(err.response?.data.message || err.message);
      });
  };

  const deleteJackpotConfig = id => {
    setDeleteConfigLoading(true);
    api.games
      .deleteJackpotConfig(id)
      .then(res => {
        setDeleteConfigLoading(false);
        if (res.status === 200) {
          getAllJackpotConfigs();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Jackpot Config Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setDeleteConfigLoading(false);
        setGetConfigsError(err.response?.data.message || err.message);
      });
  };

  const handleDeleteConfig = data => {
    setCustomModal(
      <CustomModal
        title="Delete Jackpot Configuration?"
        loading={deleteConfigLoading}
        content={<p>Do you want to delete this jackpot configuration?</p>}
        onOk={() => {
          deleteJackpotConfig(data.game_jackpot_config_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Config Name",
        accessor: "jackpot_config_name",
      },
      {
        Header: "Jackpot Type",
        accessor: "jackpot_type",
      },

      {
        Header: "Prize Type",
        accessor: "prize_type",
      },
      {
        Header: "Pattern Type",
        accessor: "games_jackpot_pattern",
        Cell: data => data.value.pattern_name,
      },
      {
        Header: "Prize Distribution",
        accessor: "prize_distribution",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: data => (data.value / 100).toLocaleString() || "N/A",
      },
      {
        Header: "Distribution Breakdown",
        accessor: "position_distribution_breakdown",
        Cell: data =>
          data.value.map(breakdown => (
            <p key={breakdown.position_name}>
              {breakdown.position_type}:{" "}
              {breakdown.dist_type === "amount"
                ? (breakdown.position_value / 100).toLocaleString()
                : breakdown.position_value}{" "}
              ({breakdown.dist_type})
            </p>
          )),
      },
      {
        Header: "Actions",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  setOpenAddGroupModal(true);
                  setEditConfig(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleDeleteConfig(data);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  //meta title
  document.title = "Jackpot Configurations";

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      jackpot_config_name: editConfig?.jackpot_config_name || "",
      jackpot_type: editConfig?.jackpot_type || "",
      prize_type: editConfig?.prize_type || "",
      prize_distribution: editConfig?.prize_distribution || "",
      jackpot_distribution_type:
        editConfig?.position_distribution_breakdown[0]?.dist_type || "",
      game_jackpot_pattern_id: editConfig?.game_jackpot_pattern_id || "",
      amount: editConfig?.amount / 100 || "",
      minimum_guarantee: editConfig?.minimum_guarantee || 0,
    },
    validationSchema: Yup.object({
      jackpot_config_name: Yup.string().required("Please enter config name"),
      game_jackpot_pattern_id: Yup.string().required(
        "Please select pattern type"
      ),
      jackpot_type: Yup.string().required("Please select jackpot type"),
      prize_type: Yup.string().required("Please select prize type"),
      prize_distribution: Yup.string().required(
        "Please select prize distribution"
      ),
      jackpot_distribution_type: Yup.string().required(
        "Please select distribution type"
      ),
      amount: Yup.string(),
      minimum_guarantee: Yup.number(),
    }),
    onSubmit: values => {
      const totalBreakdownValues = Object.values(distributionBreakdown)
        .map(brkdwn => brkdwn.value)
        .reduce((a, b) => Number(a) + Number(b), 0);

      if (
        Object.values(distributionBreakdown).length !== patternParams.length
      ) {
        return setCreateConfigError(
          "Enter all values for jackpot distribution breakdown!"
        );
      }

      if (values.jackpot_type === "fixed" && !values.amount) {
        return setCreateConfigError("Enter an amount for fixed jackpots!");
      }

      if (
        values.jackpot_distribution_type === "percent" &&
        totalBreakdownValues !== 100
      ) {
        return setCreateConfigError(
          "Total breakdown amount must be equal to 100 percent!"
        );
      }
      if (
        values.jackpot_distribution_type === "amount" &&
        totalBreakdownValues !== values.amount
      ) {
        return setCreateConfigError(
          "Sum of breakdown amounts must be equal to total amount!"
        );
      }

      const payload = {
        ...values,
        amount: String(values.amount),
        position_distribution: Object.values(distributionBreakdown),
      };
      editConfig
        ? handleEditConfig(editConfig.game_jackpot_config_id, payload)
        : createConfig(payload);
    },
  });

  const confirmCancel = () => {
    setCustomModal(
      <CustomModal
        title="Cancel Create Jackpot Config?"
        content={
          <>
            <p>Do you want to stop creating jackpot configuration?</p>
            <p>Config might not be saved</p>
          </>
        }
        onOk={() => {
          setEditConfig(null);
          validation.handleReset();
          setDistributionBreakdown({});
          setCustomModal(null);
          setOpenAddGroupModal(false);
        }}
        okText="Okay"
        closeModal={() => setCustomModal(null)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  useEffect(() => {
    if (!editConfig) return;
    const breakdownObj = {};
    for (let param of editConfig.position_distribution_breakdown) {
      const {
        position_name,
        position_type: slug,
        position_value: value,
        value_description,
      } = param;
      breakdownObj[position_name] = {
        name: position_name,
        slug,
        description:value_description,
        value:
          editConfig.position_distribution_breakdown[0]?.dist_type === "amount"
            ? value / 100
            : value,
      };
    }
    console.log(breakdownObj, editConfig);
    setDistributionBreakdown(breakdownObj);
  }, [editConfig?.position_distribution_breakdown]);

  useEffect(() => {
    setPatternParams(
      gamePatterns.find(
        pattern =>
          pattern.game_jackpot_pattern_id ===
          validation.values.game_jackpot_pattern_id
      )?.pattern_params || []
    );
  }, [validation.values.game_jackpot_pattern_id]);

  useEffect(() => {
    createConfigError &&
      setTimeout(() => {
        setCreateConfigError(null);
      }, 3000);
    getConfigsError &&
      setTimeout(() => {
        setGetConfigsError(null);
      }, 3000);
  }, [getConfigsError, createConfigError]);

  const country = localStorage.getItem("country") || "ng";
  const getCountryCurrency = country => {
    switch (country) {
      case "ng":
        return "NGN";
      case "gh":
        return "GHC";
      case "zm":
        return "ZMW";
      case "cd":
        return "CDA";
      default:
        return "NGN";
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Bingo" breadcrumbItem="Jackpot Configurations" />
        {getConfigsError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!getConfigsError}
            toggle={() => setGetConfigsError(null)}
          >
            {getConfigsError}
          </Alert>
        )}

        <Card loading={getConfigsLoading}>
          <TableContainer
            columns={columns}
            data={allJackpotConfigs}
            setShowDetailsModal={() => {}}
            isGlobalFilter={true}
            addButtonText="Add New Config"
            isAddOptions={true}
            customPageSize={10}
            className="custom-header-css"
            addNewGroup={() => setOpenAddGroupModal(true)}
          />
        </Card>
      </div>

      {customModal}

      <Modal size="lg" isOpen={openAddGroupModal} toggle={confirmCancel}>
        {createConfigError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!createConfigError}
            toggle={() => setCreateConfigError(null)}
          >
            {createConfigError}
          </Alert>
        )}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add New Configuration
          </h5>
          <button
            type="button"
            onClick={confirmCancel}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col xs={12} style={{ padding: "0 20px" }}>
                <div className="mb-3">
                  <Label className="form-label">
                    <b>Config Name</b>
                  </Label>
                  <Input
                    name="jackpot_config_name"
                    id="jackpot_config_name"
                    type="text"
                    placeholder="Enter config name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.jackpot_config_name || ""}
                    invalid={
                      validation.touched.jackpot_config_name &&
                      validation.errors.jackpot_config_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.jackpot_config_name &&
                  validation.errors.jackpot_config_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.jackpot_config_name}
                    </FormFeedback>
                  ) : null}
                </div>

                <Row>
                  <Col lg={6}>
                    <div className="mt-2 mb-3">
                      <Label htmlFor="game_jackpot_pattern_id">
                        <b>Bingo Pattern</b>
                      </Label>
                      <Input
                        type="select"
                        id="game_jackpot_pattern_id"
                        name="game_jackpot_pattern_id"
                        placeholder="Enter config name"
                        onChange={e => {
                          setDistributionBreakdown({});
                          validation.handleChange(e);
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.game_jackpot_pattern_id || ""}
                        invalid={
                          validation.touched.game_jackpot_pattern_id &&
                          validation.errors.game_jackpot_pattern_id
                            ? true
                            : false
                        }
                      >
                        <option defaultValue value="">
                          Select one...
                        </option>
                        {gamePatterns.map(pattern => (
                          <option
                            key={pattern.game_jackpot_pattern_id}
                            value={pattern.game_jackpot_pattern_id}
                          >
                            {pattern.pattern_name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.game_jackpot_pattern_id &&
                      validation.errors.game_jackpot_pattern_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.game_jackpot_pattern_id}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mt-2 mb-3">
                      <Label htmlFor="jackpot_type">
                        <b>Jackpot Type</b>
                      </Label>
                      <div className="form-input">
                        <Input
                          type="select"
                          name="jackpot_type"
                          id="jackpot_type"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.jackpot_type || ""}
                          invalid={
                            validation.touched.jackpot_type &&
                            validation.errors.jackpot_type
                              ? true
                              : false
                          }
                        >
                          <option defaultValue value="">
                            Select one...
                          </option>
                          <option value="game wagering">game wagering</option>
                          <option value="fixed">fixed</option>
                        </Input>
                        {validation.touched.jackpot_type &&
                        validation.errors.jackpot_type ? (
                          <FormFeedback type="invalid">
                            {validation.errors.jackpot_type}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mt-2 mb-3">
                      <Label htmlFor="prize_type">
                        <b>Prize Type</b>
                      </Label>
                      <div className="form-input">
                        <Input
                          type="select"
                          name="prize_type"
                          id="prize_type"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.prize_type || ""}
                          invalid={
                            validation.touched.prize_type &&
                            validation.errors.prize_type
                              ? true
                              : false
                          }
                        >
                          <option defaultValue value="">
                            Select one...
                          </option>
                          <option value="real funds">real funds</option>
                          <option value="bonus funds">bonus funds</option>
                          {validation.values.jackpot_type === "fixed" && (
                            <option value="loyalty points">
                              loyalty points
                            </option>
                          )}
                          {validation.values.jackpot_type === "fixed" && (
                            <option value="tangible">tangible</option>
                          )}
                        </Input>
                        {validation.touched.prize_type &&
                        validation.errors.prize_type ? (
                          <FormFeedback type="invalid">
                            {validation.errors.prize_type}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mt-2 mb-3">
                      <Label htmlFor="prize_type">
                        <b>Prize Distribution</b>
                      </Label>
                      <div className="form-input">
                        <Input
                          type="select"
                          name="prize_distribution"
                          id="prize_distribution"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.prize_distribution || ""}
                          invalid={
                            validation.touched.prize_distribution &&
                            validation.errors.prize_distribution
                              ? true
                              : false
                          }
                        >
                          <option defaultValue value="">
                            Select one...
                          </option>
                          <option value="split between players">
                            split between players
                          </option>
                          <option value="split between tickets">
                            split between tickets
                          </option>
                        </Input>
                        {validation.touched.prize_distribution &&
                        validation.errors.prize_distribution ? (
                          <FormFeedback type="invalid">
                            {validation.errors.prize_distribution}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Col>

                  {validation.values.jackpot_type === "fixed" &&
                    (validation.values.prize_type === "real funds" ||
                      validation.values.prize_type === "bonus funds") && (
                      <Col lg={6}>
                        <div className="mt-2 mb-3">
                          <Label htmlFor="amount">
                            <b>Amount</b>
                          </Label>
                          <div className="form-input">
                            <Input
                              type="number"
                              className="form-control"
                              id="amount"
                              name="amount"
                              placeholder="amount"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.amount || ""}
                              invalid={
                                validation.touched.amount &&
                                validation.errors.amount
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.amount &&
                            validation.errors.amount ? (
                              <FormFeedback type="invalid">
                                {validation.errors.amount}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </Col>
                    )}

                  {validation.values.prize_type === "loyalty points" && (
                    <Col lg={6}>
                      <div className="mt-2 mb-3">
                        <Label htmlFor="amount">
                          <b>Loyalty Point</b>
                        </Label>
                        <div className="form-input">
                          <Input
                            type="number"
                            className="form-control"
                            id="amount"
                            name="amount"
                            placeholder="loyalty point value"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount || ""}
                            invalid={
                              validation.touched.amount &&
                              validation.errors.amount
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  )}

                  {validation.values.prize_type === "tangible" && (
                    <Col lg={6}>
                      <div className="mt-2 mb-3">
                        <Label htmlFor="amount">
                          <b>Tangible Item</b>
                        </Label>
                        <div className="form-input">
                          <Input
                            type="number"
                            className="form-control"
                            id="amount"
                            name="amount"
                            placeholder="tangible item"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount || ""}
                            invalid={
                              validation.touched.amount &&
                              validation.errors.amount
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  )}

                  <Col lg={6}>
                    <div className="mt-2 mb-3">
                      <Label htmlFor="jackpot_distribution_type">
                        <b>Distribution Type</b>
                      </Label>
                      <div className="form-input">
                        <Input
                          type="select"
                          name="jackpot_distribution_type"
                          id="jackpot_distribution_type"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={
                            validation.values.jackpot_distribution_type || ""
                          }
                          invalid={
                            validation.touched.jackpot_distribution_type &&
                            validation.errors.jackpot_distribution_type
                              ? true
                              : false
                          }
                        >
                          <option defaultValue value="">
                            Select one...
                          </option>
                          <option value="percent">percent</option>
                          {validation.values.jackpot_type === "fixed" && (
                            <option value="amount">amount</option>
                          )}
                        </Input>
                        {validation.touched.jackpot_distribution_type &&
                        validation.errors.jackpot_distribution_type ? (
                          <FormFeedback type="invalid">
                            {validation.errors.jackpot_distribution_type}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Col>

                  {validation.values.jackpot_type === "game wagering" && (
                    <Col lg={6}>
                      <div className="mt-2 mb-3">
                        <Label htmlFor="minimum_guarantee">
                          <b>Min. Guarantee</b>
                        </Label>
                        <div className="form-input">
                          <Input
                            type="number"
                            name="minimum_guarantee"
                            id="minimum_guarantee"
                            placeholder={getCountryCurrency(country)}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.minimum_guarantee || ""}
                            invalid={
                              validation.touched.minimum_guarantee &&
                              validation.errors.minimum_guarantee
                                ? true
                                : false
                            }
                          />

                          {validation.touched.minimum_guarantee &&
                          validation.errors.minimum_guarantee ? (
                            <FormFeedback type="invalid">
                              {validation.errors.minimum_guarantee}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  )}

                  {patternParams.length > 0 && (
                    <div>
                      <div className="mt-3">
                        <Label htmlFor="prize_type">
                          <b>Distribution Breakdown</b>
                        </Label>
                        <Row>
                          {patternParams.map(param => (
                            <Col className="mb-3" lg={6} key={param.name}>
                              <div
                                style={{
                                  marginTop: "15px",
                                  alignItems: "center",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <p style={{ width: "80px" }}>
                                  {" "}
                                  {param.name} wins
                                </p>
                                <Input
                                  type="number"
                                  className="form-control"
                                  style={{
                                    width: 200,
                                    display: "inline-block",
                                    margin: "0 5px",
                                  }}
                                  value={
                                    distributionBreakdown[`${param.name}`]
                                      ?.value || ""
                                  }
                                  onChange={e =>
                                    setDistributionBreakdown(prev => ({
                                      ...prev,
                                      [param.name]: {
                                        ...param,
                                        value: e.target.value,
                                      },
                                    }))
                                  }
                                  placeholder={
                                    validation.values
                                      .jackpot_distribution_type === "percent"
                                      ? "%"
                                      : getCountryCurrency(country)
                                  }
                                />{" "}
                              </div>
                              {(editConfig?.prize_distribution == "tangible" ||
                                validation.values.prize_type ===
                                  "tangible") && (
                                <div
                                  style={{
                                    marginTop: "15px",
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <p style={{ width: "80px" }}>Description</p>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    style={{
                                      width: 200,
                                      display: "inline-block",
                                      margin: "0 5px",
                                    }}
                                    value={
                                      distributionBreakdown[`${param.name}`]
                                        ?.description || ""
                                    }
                                    onChange={e => {
                                      console.log(
                                        distributionBreakdown[`${param.name}`]
                                      );
                                      setDistributionBreakdown(prev => ({
                                        ...prev,
                                        [param.name]: {
                                          ...distributionBreakdown[
                                            `${param.name}`
                                          ],
                                          description: e.target.value,
                                        },
                                      }));
                                    }}
                                  />
                                </div>
                              )}
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
          </Form>
        </div>

        {createConfigError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!createConfigError}
            toggle={() => setCreateConfigError(null)}
          >
            {createConfigError}
          </Alert>
        )}

        <div className="modal-footer">
          <button
            type="button"
            onClick={confirmCancel}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            loading={createConfigLoading ? "true" : "false"}
            disabled={createConfigLoading}
            onClick={validation.handleSubmit}
          >
            Save changes
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default JackpotConfigs;
