export default class Deposits {
  constructor(client) {
    this.client = client;
  }

  approveWithdrawal(payload) {
    return this.client.post("/payment/new-approve-withdraw", payload);
  }

  getAllProcessors() {
    return this.client.get(`/payment/payment-methods-for-withdrawal`);
  }
  finalizePaystackWithdrawal(payload) {
    return this.client.post("/payment/finalize-paystack-withdraw", payload);
  }
  paystackResendOtp(payload) {
    return this.client.post("/payment/paystack-resend-otp", payload);
  }
}
