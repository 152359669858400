import React, { useEffect, useMemo, useRef, useState } from "react";
import api from "../../api";
import { Alert } from "reactstrap";
import { Card, Form, Input, InputNumber, Modal } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../Withdrawals/GroupsTable";
import CustomModal from "../../reusables/CustomModal";
import { CSVLink } from "react-csv";

const Deposits = () => {
  const [deposits, setDeposits] = useState([]);
  const [getDepositsLoading, setGetDepositsLoading] = useState(false);
  const [getDepositsError, setGetDepositsError] = useState(null);
  const [manualDeposit, setManualDeposit] = useState(false);
  const [revertDeposit, setRevertDeposit] = useState(false);
  const [customModal, setCustomModal] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone No.",
        accessor: "phone_number",
      },
      {
        Header: "Reference",
        accessor: "user_wallet_deposits_id",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Local Amount",
        accessor: "local_amount_deposits",
        Cell: data => (data.value / 100).toLocaleString(),
      },
      {
        Header: "Date",
        accessor: "deposits_date",
        Cell: data => new Date(data.value).toLocaleString(),
      },
      {
        Header: "Type",
        accessor: "type",
      },
    ],

    []
  );

  const allDataColumn = [
    {
      label: "User",
      key: "name",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Account Level",
      key: "account_level",
    },
    {
      label: "Base Amount",
      key: "base_amount",
    },
    {
      label: "Local Amount",
      key: "local_amount",
    },
    {
      label: "Deposit Type",
      key: "type",
    },
    {
      label: "Date",
      key: "creation_date",
    },
  ];

  const openDepositDetailsModal = deposit => {
    setCustomModal(
      <CustomModal
        title={<b>Deposit Details</b>}
        content={
          <div>
            <p>
              <b>Transaction Reference:</b> {deposit.transaction_reference}
            </p>
            <p>
              <b>Base Amount:</b> {deposit.base_amount_deposits / 100}
            </p>
            <p>
              <b>Local Amount:</b>{" "}
              {(deposit.local_amount_deposits / 100).toLocaleString()}
            </p>
            <p>
              <b>Transaction Date:</b>{" "}
              {new Date(deposit.deposits_date).toString()}
            </p>
            <p>
              <b>Country:</b>{" "}
              {deposit.user_deposits_option.config_details.country}
            </p>
            <p>
              <b>Deposit Type Name:</b>{" "}
              {deposit.user_deposits_option.deposit_type_name}
            </p>
            <p>
              <b>Deposit Type Status:</b>{" "}
              {deposit.user_deposits_option.deposits_options_status}
            </p>

            <p>
              <b>User Name:</b> {deposit.user_wallet.user_profile.first_name}{" "}
              {deposit.user_wallet.user_profile.last_name}
            </p>
            <p>
              <b>User Email:</b> {deposit.user_wallet.user_profile.email}
            </p>
            <p>
              <b>Phone Number:</b> {deposit.user_wallet.user_profile.phone_num}
            </p>
            <p>
              <b>Account Level:</b>{" "}
              {deposit.user_wallet.user_profile.account_level}
            </p>
            <p>
              <b>Account Status:</b>{" "}
              {deposit.user_wallet.user_profile.account_status}
            </p>
            <p>
              <b>Is Verified:</b>{" "}
              {deposit.user_wallet.user_profile.is_verified.toString()}
            </p>
            <p>
              <b>User ID:</b> {deposit.user_wallet.user_profile.user_id}
            </p>
          </div>
        }
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const getAllDeposits = (limit, page, search) => {
    setGetDepositsLoading(true);
    api.commerce
      .getAllDeposits(limit, page, search)
      .then(res => {
        setGetDepositsLoading(false);
        if (res.status === 200) {
          setDeposits(res.data.data.deposits);
          setPageCount(res.data.data.totalPages);
        }
      })
      .catch(err => {
        setGetDepositsLoading(false);
        setGetDepositsError(err.response?.data.message || err.message);
      });
  };

  const updateUserWallet = ({
    phone_number,
    amount,
    reference,
    payment_method = "bank transfer",
  }) => {
    api.commerce
      .updateUserWallet({ amount, phone_number, payment_method, reference })
      .then(res => {
        if (res.status === 200) {
          setCustomModal(
            <CustomModal
              title="Success!"
              content="User Wallet Updated Successfully!"
              onOk={() => {
                setManualDeposit(false);
                form.resetFields();
                setCustomModal(null);
              }}
              closeModal={() => {
                setManualDeposit(false);
                form.resetFields();
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setActivateUserError(err.response?.data.message || err.message);
      });
  };

  const revertManualDeposit = ({
    reference,
  }) => {
    api.commerce
      .revertUserDeposit({ reference })
      .then(res => {
        if (res.status === 200) {
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Deposit Reverted Successfully!"
              onOk={() => {
                setRevertDeposit(false);
                form.resetFields();
                setCustomModal(null);
              }}
              closeModal={() => {
                setRevertDeposit(false);
                form.resetFields();
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setActivateUserError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    getDepositsError &&
      setTimeout(() => {
        setGetDepositsError(null);
      }, 3000);
  });

  const exportButton = (
    <div>
      <button
        style={{ marginRight: 20 }}
        className="btn btn-danger"
        onClick={() => setRevertDeposit(true)}
      >
        Revert Deposit
      </button>
      <button
        style={{ marginRight: 20 }}
        className="btn btn-secondary"
        onClick={() => setManualDeposit(true)}
      >
        Manual Deposit
      </button>
      <CSVLink
        id="csvDownloadBtn"
        key="all-users-download"
        data={deposits.map(deposit => ({
          ...deposit,
          name:
            deposit.user_wallet.user_profile.first_name +
            " " +
            deposit.user_wallet.user_profile.last_name,
          email: deposit.user_wallet.user_profile.email,
          account_level: deposit.user_wallet.user_profile.account_level,
          type: deposit.user_deposits_option.deposit_type_name,
          base_amount: deposit.base_amount_deposits / 100,
          local_amount: deposit.local_amount_deposits / 100,
          creation_date: new Date(deposit.deposits_date).toLocaleString(),
        }))}
        headers={allDataColumn}
        filename={`all-deposits-data.csv`}
        target="_blank"
      >
        <button className="btn btn-primary">Export Deposits</button>
      </CSVLink>
    </div>
  );

  useEffect(() => {
    getAllDeposits(pageSize, pageIndex + 1, filters);
  }, [pageIndex, pageSize]);

  useEffect(() => {
    getAllDeposits(pageSize, 1, filters);
  }, [filters]);
  const [form] = Form.useForm();

  const handleManualDeposit = () => {
    form.validateFields().then(values => {
      updateUserWallet({ ...values });
    });
  };

  const handleRevertDeposit = () => {
    form.validateFields().then(values => {
      revertManualDeposit({ ...values });
    });
  };

  //meta title
  document.title = "Deposits";

  return (
    <div className="page-content">
      {getDepositsError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getDepositsError}
          toggle={() => setGetDepositsError(null)}
        >
          {getDepositsError}
        </Alert>
      )}
      {manualDeposit && (
        <Modal
          visible={manualDeposit}
          title="Manual Deposit"
          onCancel={() => setManualDeposit(false)}
          footer={[
            <button
              key="cancel"
              onClick={() => setManualDeposit(false)}
              className="btn btn-danger"
            >
              Cancel
            </button>,
            <button
              key="deposit"
              style={{ marginLeft: 20 }}
              className="btn btn-secondary"
              onClick={handleManualDeposit}
            >
              Deposit
            </button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="phone_number"
              label="Phone Number"
              rules={[{ required: true, message: "Please enter phone number" }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <InputNumber style={{ width: "100%" }} size="large" />
            </Form.Item>
            <Form.Item
              name="reference"
              label="Payment Reference"
              rules={[
                { required: true, message: "Please enter payment reference" },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="payment_method"
              label="Payment Method"
              rules={[
                { required: true, message: "Please enter payment method" },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {revertDeposit && (
        <Modal
          visible={revertDeposit}
          title="Revert Deposit"
          onCancel={() => setRevertDeposit(false)}
          footer={[
            <button
              key="cancel"
              onClick={() => setRevertDeposit(false)}
              className="btn btn-danger"
            >
              Cancel
            </button>,
            <button
              key="deposit"
              style={{ marginLeft: 20 }}
              className="btn btn-secondary"
              onClick={handleRevertDeposit}
            >
              Revert
            </button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="reference"
              label="Payment Reference"
              rules={[
                { required: true, message: "Please enter payment reference" },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {customModal}
      <div className="container-fluid">
        <Breadcrumbs title="Deposits" breadcrumbItem="All Deposits" />
        <Card loading={getDepositsLoading}>
          <TableContainer
            columns={columns}
            data={deposits.map(deposit => ({
              ...deposit,
              email: deposit.user_wallet?.user_profile.email,
              phone_number: deposit.user_wallet?.user_profile.phone_num,
              type: deposit.user_deposits_option.deposit_type_name,
              status: deposit.payment_deposit.deposit_status
            }))}
            addButtonText={null}
            isGlobalFilter={true}
            isAddOptions={false}
            pageCount={pageCount}
            customPageSize={pageSize}
            setShowDetailsModal={val => openDepositDetailsModal(val.original)}
            className="custom-header-css"
            addNewGroup={() => {}}
            exportButton={exportButton}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            controlledPageIndex={pageIndex}
          />
        </Card>
      </div>
    </div>
  );
};
export default Deposits;
