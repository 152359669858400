import React, { useEffect, useMemo, useState } from "react";
import api from "../../api";
import { Alert } from "reactstrap";
import { Card } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "./GroupsTable";
import CustomModal from "../../reusables/CustomModal";
import { CSVLink } from "react-csv";

const WalletWithdrawals = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [getWithdrawalsLoading, setGetWithdrawalsLoading] = useState(false);
  const [getWithdrawalsError, setGetWithdrawalsError] = useState(null);
  const [customModal, setCustomModal] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone No.",
        accessor: "phone_number",
      },
      {
        Header: "Local Amount",
        accessor: "user_withdrawal_request",
        Cell: data => (data.value.local_request_amount / 100).toLocaleString(),
      },
      {
        Header: "Base Amount",
        accessor: "withdrawal_id",
        Cell: data =>
          data.row.original.user_withdrawal_request.base_request_amount / 100,
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Date",
        accessor: "withdrawal_date",
        Cell: data => new Date(data.value).toLocaleString(),
      },
    ],

    []
  );

  const getAllWithdrawals = (limit, page, search) => {
    setGetWithdrawalsLoading(true);
    api.commerce
      .getAllWithdrawals(limit, page, search)
      .then(res => {
        setGetWithdrawalsLoading(false);
        if (res.status === 200) {
          setWithdrawals(res.data.data.withdrawals);
          setPageCount(res.data.data.totalPages);
        }
      })
      .catch(err => {
        setGetWithdrawalsLoading(false);
        setGetWithdrawalsError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    getWithdrawalsError &&
      setTimeout(() => {
        setGetWithdrawalsError(null);
      }, 3000);
  });

  const openDepositDetailsModal = data => {
    setCustomModal(
      <CustomModal
        title={<b>Withdrawal Details</b>}
        content={
          <div>
            <p>
              <b>Transaction Reference:</b> {data.transaction_reference}
            </p>
            <p>
              <b>Base Amount:</b>{" "}
              {data.user_withdrawal_request.base_request_amount / 100}
            </p>
            <p>
              <b>Local Amount:</b>{" "}
              {(
                data.user_withdrawal_request.local_request_amount / 100
              ).toLocaleString()}
            </p>
            <p>
              <b>Request Date:</b> {new Date(data.withdrawal_date).toString()}
            </p>
            <p>
              <b>Country:</b> {data.user_profile.locale_country_profile.country}
            </p>
            <p>
              <b>Withdrawal Status:</b> {data.withdawal_status}
            </p>

            <p>
              <b>User Name:</b> {data.user_profile.first_name}{" "}
              {data.user_profile.last_name}
            </p>
            <p>
              <b>User Email:</b> {data.user_profile.email}
            </p>
            <p>
              <b>Phone Number:</b> {data.user_profile.phone_num}
            </p>
            <p>
              <b>Account Level:</b> {data.user_profile.account_level}
            </p>
            <p>
              <b>Account Status:</b> {data.user_profile.account_status}
            </p>
            <p>
              <b>Is Verified:</b> {data.user_profile.is_verified.toString()}
            </p>
            <p>
              <b>User ID:</b> {data.user_profile.user_id}
            </p>
          </div>
        }
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const acceptedWithdrawalHeaders = [
    {
      label: "User",
      key: "user",
    },
    {
      label: "Account Level",
      key: "account_level",
    },
    {
      label: "Status",
      key: "transaction_status",
    },
    {
      label: "Base Amount",
      key: "base_amount",
    },
    {
      label: "Local Amount",
      key: "local_amount",
    },
    {
      label: "Date",
      key: "withdrawal_date",
    },
  ];

  const exportButton = (
    <CSVLink
      id="csvDownloadBtn"
      key="all-users-download"
      data={withdrawals.map(withdrawal => ({
        ...withdrawal,
        user:
          withdrawal.user_profile.first_name +
          " " +
          withdrawal.user_profile.last_name,
        withdrawal_date: new Date(withdrawal.withdrawal_date).toLocaleString(),
        local_amount:
          withdrawal.user_withdrawal_request.local_request_amount / 100,
        base_amount:
          withdrawal.user_withdrawal_request.base_request_amount / 100,
      }))}
      headers={acceptedWithdrawalHeaders}
      filename={`all-withdrawals-data-${localStorage.getItem("country")}.csv`}
      target="_blank"
    >
      <button className="btn btn-primary">Export Withdrawals</button>
    </CSVLink>
  );

  useEffect(() => {
    getAllWithdrawals(pageSize, pageIndex + 1, "");
  }, [pageIndex, pageSize]);

  useEffect(() => {
    getAllWithdrawals(pageSize, 1, filters);
  }, [filters]);

  //meta title
  document.title = "Wallet Withdrawals";

  return (
    <div className="page-content">
      {getWithdrawalsError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getWithdrawalsError}
          toggle={() => setGetWithdrawalsError(null)}
        >
          {getWithdrawalsError}
        </Alert>
      )}
      {customModal}
      <div className="container-fluid">
        <Breadcrumbs title="Withdrawals" breadcrumbItem="Wallet Withdrawals" />
        <Card loading={getWithdrawalsLoading}>
          <TableContainer
            columns={columns}
            data={withdrawals.map(withdrawal => ({
              ...withdrawal,
              email: withdrawal.user_profile.email,
              phone_number: withdrawal.user_profile.phone_num,
              status: withdrawal.transaction_status
            }))}
            addButtonText={null}
            setShowDetailsModal={val => openDepositDetailsModal(val.original)}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={pageSize}
            className="custom-header-css"
            addNewGroup={() => {}}
            exportButton={exportButton}
            pageCount={pageCount}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            controlledPageIndex={pageIndex}
          />
        </Card>
      </div>
    </div>
  );
};
export default WalletWithdrawals;
