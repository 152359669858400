import React, { useState, useEffect } from "react";
import { Calendar, Badge, Card, message, Modal, Table } from "antd";
import api from "../../api";
import "./index.css";
import { Button } from "reactstrap";

function LiveCalendar() {
  const [gameEvents, setGameEvents] = useState([]);
  const [getLiveBingosLoading, setGetLiveBingosLoading] = useState(false);
  const [calendarDetailsModal, setCalendarDetailsModal] = useState(null);

  const getAllLiveBingos = () => {
    setGetLiveBingosLoading(true);
    api.games
      .getAllBingoEvents()
      .then(res => {
        setGetLiveBingosLoading(false);
        if (res.status === 200) {
          setGameEvents([
            ...res.data.data.live.events,
            ...res.data.data.upcoming.events,
            ...res.data.data.canceled.events,
            ...res.data.data.completed.events,
          ]);
        }
      })
      .catch(err => {
        setGetLiveBingosLoading(false);
        message.error(err.response?.data.message || err.message);
      });
  };

  const calendarObj = {};
  gameEvents && gameEvents.forEach(event => {
    const year = new Date(event.game_start).getFullYear();
    const month = new Date(event.game_start).getMonth();
    const day = new Date(event.game_start).getDay();

    calendarObj[year]
      ? calendarObj[year][month]
        ? calendarObj[year][month][day]
          ? calendarObj[year][month][day].push(event)
          : (calendarObj[year][month][day] = [event])
        : (calendarObj[year][month] = { [day]: [event] })
      : (calendarObj[year] = { [month]: { [day]: [event] } });
  });

  const getListData = value => {
    const year = value.year();
    const month = value.month();
    const day = value.day();

    return !!calendarObj[year] &&
      !!calendarObj[year][month] &&
      !!calendarObj[year][month][day]
      ? calendarObj[year][month][day]
      : [];
  };

  const getMonthData = value => {
    const year = value.year();
    const month = value.month();

    return !!calendarObj[year] && !!calendarObj[year][month]
      ? getMonthsSingleArray(Object.values(calendarObj[year][month]))
      : [];
  };

  const getMonthsSingleArray = arr => {
    const result = [];
    for (let i of arr) {
      for (let j of i) {
        result.push(j);
      }
    }
    return result;
  };

  const monthCellRender = value => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num.length} games</section>
      </div>
    ) : null;
  };

  const dateCellRender = value => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map(item => (
          <li key={item.game_events_id}>
            <Badge
              color={colors[Math.floor(Math.random() * 12)]}
              text={item.game_name}
            />
          </li>
        ))}
      </ul>
    );
  };

  const handleDateChange = val => {
    const data = getListData(val);
    setCalendarDetailsModal({ date: val.format("dddd, MMMM Do YYYY"), data });
  };

  useEffect(() => {
    getAllLiveBingos();
  }, []);

  return (
    <div style={{ marginTop: 80 }}>
      <Card loading={getLiveBingosLoading}>
        <Calendar
          dateCellRender={dateCellRender}
          monthCellRender={monthCellRender}
          onSelect={handleDateChange}
        />
      </Card>

      {calendarDetailsModal && (
        <Modal
          width={700}
          title={calendarDetailsModal.date}
          visible={!!calendarDetailsModal}
          onCancel={() => setCalendarDetailsModal(null)}
          footer={[
            <Button onClick={() => setCalendarDetailsModal(null)} key="cancel">
              Cancel
            </Button>,
          ]}
        >
          <Table
            dataSource={calendarDetailsModal.data}
            columns={tableColumns}
          />
        </Modal>
      )}
    </div>
  );
}

export default LiveCalendar;

const tableColumns = [
  {
    title: "Bingo Name",
    dataIndex: "game_name",
    key: "game_name",
  },
  {
    title: "Bingo Type",
    dataIndex: "game_type",
    key: "game_type",
  },
  {
    title: "Bingo Group",
    dataIndex: "group_name",
    key: "group_name",
  },
  {
    title: "Bingo Duration",
    dataIndex: "game_duration",
    key: "game_duration",
    sorter: (a, b) => a.game_duration - b.game_duration,
  },

  {
    title: "Start Date",
    dataIndex: "game_start",
    key: "game_start",
    sorter: (a, b) => new Date(a.game_start) - new Date(b.game_start),
    render: data => new Date(data).toLocaleString(),
  },
  {
    title: "Status",
    dataIndex: "game_status",
    key: "game_status",
  },
];

const colors = [
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
];
