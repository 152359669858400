import moment from "moment";
import React, { useEffect } from "react";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Alert,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
function EditAdminModal(props) {
  const {
    isOpen,
    success,
    addAdminLoading,
    addAdminError,
    allRoles,
    validation,
    toggle,
  } = props;
  console.log(validation.values.dob)
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        Edit Admin
      </ModalHeader>
      <ModalBody>
        {addAdminError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!addAdminError}
            toggle={() => dispatch(clearAdminState())}
          >
            {addAdminError.message || addAdminError}
          </Alert>
        )}
        <Form>
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Role</Label>
                <Input
                  type="select"
                  name="role_id"
                  id="role_id"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.role_id || ""}
                  invalid={
                    validation.touched.role_id && validation.errors.role_id
                      ? true
                      : false
                  }
                >
                  <option value="">Select One</option>
                  {allRoles.map(role => (
                    <option key={role.role_id} value={role.role_id}>
                      {role.role}
                    </option>
                  ))}
                </Input>
                {validation.touched.role_id && validation.errors.role_id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.role_id}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Phone Number</Label>
                <Input
                  name="phone"
                  id="phone"
                  type="tel"
                  placeholder="Enter phone number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">First Name</Label>
                <Input
                  name="fname"
                  id="fname"
                  type="text"
                  placeholder="Enter first name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.fname || ""}
                  invalid={
                    validation.touched.fname && validation.errors.fname
                      ? true
                      : false
                  }
                />
                {validation.touched.fname && validation.errors.fname ? (
                  <FormFeedback type="invalid">
                    {validation.errors.fname}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Last Name</Label>
                <Input
                  name="lname"
                  id="lname"
                  type="text"
                  placeholder="Enter last name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lname || ""}
                  invalid={
                    validation.touched.lname && validation.errors.lname
                      ? true
                      : false
                  }
                />
                {validation.touched.lname && validation.errors.lname ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lname}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Date of Birth</Label>
                <Input
                  name="dob"
                  id="dob"
                  type="date"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={(validation.values.dob) || ""}
                  invalid={
                    validation.touched.dob && validation.errors.dob
                      ? true
                      : false
                  }
                />
                {validation.touched.dob && validation.errors.dob ? (
                  <FormFeedback type="invalid">
                    {validation.errors.dob}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          {addAdminError && (
            <Alert
              color="danger"
              fade={true}
              isOpen={!!addAdminError}
              toggle={() => dispatch(clearAdminState())}
            >
              {addAdminError.message || addAdminError}
            </Alert>
          )}
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  onClick={validation.handleSubmit}
                  className="btn btn-success save-user"
                  disabled={addAdminLoading}
                  loading={addAdminError ? "true" : "false"}
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default EditAdminModal;
