import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

//redux
import { useSelector, useDispatch } from "react-redux";

const Layout = props => {
  const dispatch = useDispatch();

  // const { leftSideBarType, leftSideBarTheme } = useSelector(state => ({
  //   leftSideBarType: state.Layout.leftSideBarType,
  //   layoutWidth: state.Layout.layoutWidth,
  //   topbarTheme: state.Layout.topbarTheme,
  //   leftSideBarTheme: state.Layout.leftSideBarTheme,
  // }));

  const [sidebarType, changeSidebarType] = useState("default");

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const toggleMenuCallback = () => {
    if (sidebarType === "default") {
      changeSidebarType("condensed");
    } else if (sidebarType === "condensed") {
      changeSidebarType("default");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={"dark"}
          type={sidebarType}
          isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default withRouter(Layout);
