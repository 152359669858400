import axios from "axios";
// import store from "../redux/configureStore";
import Admin from "./admin";
import Deposits from "./deposits";
import Withdrawals from "./withdrawals";
import Bingos from "./games";
import Users from "./users";
import Tickets from "./tickets";
import Commerce from "./commerce";
import Slots from "./slots";

const country = localStorage.getItem("country") || "ng";

const token = localStorage.getItem("betty_token");

const baseUrl = process.env.REACT_APP_BASE_URL;

// ("https://5zljr2hxw9.execute-api.eu-west-2.amazonaws.com");

const coreAxiosInstance = axios.create({
  baseURL: `${baseUrl}/core/${country}/${process.env.REACT_APP_API_VERSION}`,
  headers: {
    authorization: "Bearer " + token,
  },
});

const commerceAxiosInstance = axios.create({
  baseURL: `${baseUrl}/commerce/${country}/${process.env.REACT_APP_API_VERSION}`,
  headers: {
    authorization: "Bearer " + token,
  },
});

const gameAxiosInstance = axios.create({
  baseURL: `${baseUrl}/games/${country}/${process.env.REACT_APP_API_VERSION}`,
  headers: {
    authorization: "Bearer " + token,
  },
});
const slotsAxiosInstance = axios.create({
  baseURL: `${baseUrl}/core/${country}/${process.env.REACT_APP_API_VERSION}`,
  headers: {
    authorization: "Bearer " + token,
  },
});

const commsAxiosInstance = axios.create({
  baseURL: `${baseUrl}/comms/${country}/${process.env.REACT_APP_API_VERSION}`,
  headers: {
    authorization: "Bearer " + token,
  },
});

export default {
  admin: new Admin(coreAxiosInstance),
  games: new Bingos(gameAxiosInstance),
  slots: new Slots(slotsAxiosInstance),
  users: new Users(coreAxiosInstance),
  withdrawals: new Withdrawals(commerceAxiosInstance),
  deposits: new Deposits(commerceAxiosInstance),
  tickets: new Tickets(commerceAxiosInstance),
  commerce: new Commerce(coreAxiosInstance),
  coreHttpClient: coreAxiosInstance,
  gamesHttpClient: gameAxiosInstance,
  slotsHttpClient: slotsAxiosInstance,
  commerceHttpClient: commerceAxiosInstance,
  commsAxiosInstance: commsAxiosInstance,
};
