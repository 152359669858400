// src/components/filter.
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, TimePicker } from "antd";
import { Link } from "react-router-dom";

import {
  Modal,
  Col,
  Row,
  Form,
  Input,
  Alert,
  UncontrolledTooltip,
  FormFeedback,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "../GroupsTable";
import CustomModal from "../../../reusables/CustomModal";
import api from "../../../api";
import CalendarTypes from "./CalendarTypes";

const BingoGroups = () => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);

  const [customModal, setCustomModal] = useState(null);
  const [getCalendarsLoading, setGetCalendarsLoading] = useState(false);
  const [allCalendars, setCalendars] = useState([]);
  const [getCalendarError, setGetCalendarError] = useState(null);
  const [editCalendar, setEditCalendar] = useState(null);
  const [createCalendarError, setCreateCalendarError] = useState(null);
  const [createCalendarLoading, setCreateCalendarLoading] = useState(false);
  const [editGameBreak, setEditGameBreak] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(null);
  const [showGameBreakModal, setShowGameBreakModal] = useState(null);
  const [showEditBreakModal, setEditGameBreakModal] = useState(false);
  const [gameBreaksLoading, setGameBreaksLoading] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [editStartTime, setEditStartTime] = useState("");
  const [editEndTime, setEditEndTime] = useState("");
  const [configId, setConfigId] = useState("");
  const [allGameBreaks, setGameBreaks] = useState([]);
  const [gameBreaksError, setGameBreaksError] = useState(null);
  const [gameProfiles, setBingoProfiles] = useState([]);

  const format = "HH:mm";

  const columns = [
    {
      Header: "Bingo Profile",
      accessor: "game_name",
      Cell: data => (
        <p onClick={() => setShowDetailsModal(data.row.original)}>
          {data.value}
        </p>
      ),
    },
    {
      Header: "Calendar Type",
      accessor: "calender_type",
      Cell: data => (
        <p onClick={() => setShowDetailsModal(data.row.original)}>
          {data.value}
        </p>
      ),
    },
    {
      Header: "Actions",
      Cell: cellProps => {
        return (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const data = cellProps.row.original;
                setOpenAddGroupModal(true);
                setEditCalendar(data);
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const data = cellProps.row.original;
                handleDeleteCalendar(data);
              }}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
            <Button
              className="btn-secondary"
              onClick={() => {
                const data = cellProps.row.original;
                setShowDetailsModal(data);
              }}
            >
              Add Details
            </Button>
            <Button
              className="btn btn-warning"
              onClick={() => {
                const data = cellProps.row.original;
                setConfigId(data.calendar_config_id);
                console.log(data);
                if (data.calender_type == "recurring") {
                  getRecurringId(data.calendar_config_id);
                }else if(data.calender_type == "single"){
                  getSingleId(data.calendar_config_id);
                }else if (data.calender_type == "monthly") {
                  getMonthlyId(data.calendar_config_id);
                }
              }}
            >
              Game Breaks
            </Button>
          </div>
        );
      },
    },
  ];

  const gameBreakColumns = [
    {
      Header: "Start Time",
      accessor: "break_start_time",
      Cell: data => <p>{data.value}</p>,
    },
    {
      Header: "End Time",
      accessor: "break_end_time",
      Cell: data => <p>{data.value}</p>,
    },
    {
      Header: "Actions",
      Cell: cellProps => {
        return (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const data = cellProps.row.original;
                setEditGameBreakModal(true);
                setEditGameBreak(data);
                setEditStartTime(
                  data?.break_start_time?.substring(0, t.length - 3)
                );
                setEditEndTime(
                  data?.break_end_time?.substring(0, t.length - 3)
                );
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const data = cellProps.row.original;
                handleDeleteGameBreak(data);
              }}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        );
      },
    },
  ];

  const getAllCalendars = () => {
    setGetCalendarsLoading(true);
    api.games
      .getAllBingoCalendars()
      .then(res => {
        setGetCalendarsLoading(false);
        if (res.status === 200) {
          setCalendars(res.data.data);
        }
      })
      .catch(err => {
        setGetCalendarsLoading(false);
        setGetCalendarError(err.response?.data.message || err.message);
      });
  };

  const getAllProfiles = () => {
    api.games
      .getAllBingoProfiles()
      .then(res => {
        if (res.status === 200) {
          setBingoProfiles(res.data.data);
        }
      })
      .catch(err => {
        setGetCalendarError(err.response?.data.message || err.message);
      });
  };

  const createCalendar = payload => {
    setCreateCalendarLoading(true);
    api.games
      .createBingoCalendar(payload)
      .then(res => {
        setCreateCalendarLoading(false);
        if (res.data.status === "success") {
          getAllCalendars();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Calendar Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateCalendarLoading(false);
        setCreateCalendarError(err.response?.data.message || err.message);
      });
  };

  const updateCalendar = (id, payload) => {
    setCreateCalendarLoading(true);
    api.games
      .updateBingoCalendar(id, payload)
      .then(res => {
        setCreateCalendarLoading(false);
        if (res.status === 200) {
          getAllCalendars();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Calendar Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateCalendarLoading(false);
        setCreateCalendarError(err.response?.data.message || err.message);
      });
  };

  const deleteCalendar = id => {
    api.games
      .deleteBingoCalendar(id)
      .then(res => {
        if (res.status === 200) {
          getAllCalendars();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Calendar Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateCalendarError(err.response?.data.message || err.message);
      });
  };

  const handleDeleteCalendar = data => {
    setCustomModal(
      <CustomModal
        title="Delete Calendar?"
        content={<p>Do you want to delete this calendar?</p>}
        onOk={() => {
          deleteCalendar(data.calendar_config_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  // Game breaks

  const getRecurringId = id => {
    api.games
      .getBingoCalendarRecurring(id)
      .then(res => {
        if (res.status === 200) {
          setConfigId(res.data.data.cal_config_recurr_id);
          getGameBreaks(res.data.data.cal_config_recurr_id);
        }
      })
      .catch(err => {
        setGameBreaksLoading(false);
        setGameBreaksError(err.response?.data.message || err.message);
      });
  };

   const getSingleId = id => {
     api.games
       .getBingoCalendarSingle(id)
       .then(res => {
         if (res.status === 200) {
           setConfigId(res.data.data.cal_config_single_id);
           getGameBreaks(res.data.data.cal_config_single_id);
         }
       })
       .catch(err => {
         setGameBreaksLoading(false);
         setGameBreaksError(err.response?.data.message || err.message);
       });
   };

    const getMonthlyId = id => {
      api.games
        .getBingoCalendarMonthly(id)
        .then(res => {
          if (res.status === 200) {
            setConfigId(res.data.data.cal_config_monthly_id);
            getGameBreaks(res.data.data.cal_config_monthly_id);
          }
        })
        .catch(err => {
          setGameBreaksLoading(false);
          setGameBreaksError(err.response?.data.message || err.message);
        });
    };
  const createGameBreak = () => {
    const s_time = new Date(startTime);
    const e_time = new Date(endTime);

    const st_time = s_time.toLocaleString("en-US", {
      hour: "numeric",
      hourCycle: "h23",
      minute: "numeric",
    });

    const en_time = e_time.toLocaleString("en-US", {
      hour: "numeric",
      hourCycle: "h23",
      minute: "numeric",
    });

    const payload = {
      break_start_time: st_time.split(" ")[0],
      break_end_time: en_time.split(" ")[0],
      cal_config_recurr_id: configId,
    };
    setGameBreaksLoading(true);
    api.games
      .createGameBreak(payload)
      .then(res => {
        setGameBreaksLoading(false);
        if (res.data.status === "success") {
          getGameBreaks(configId);
          setStartTime("");
          setEndTime("");
        }
      })
      .catch(err => {
        setGameBreaksLoading(false);
        setGameBreaksError(err.response?.data.message || err.message);
      });
  };

  const getGameBreaks = id => {
    setGameBreaksLoading(true);
    api.games
      .getAllGameBreaks(id)
      .then(res => {
        setGameBreaksLoading(false);
        if (res.status === 200) {
          setGameBreaks(res.data.data);
          setShowGameBreakModal(true);
        }
      })
      .catch(err => {
        setGameBreaksLoading(false);
        setGameBreaksError(err.response?.data.message || err.message);
      });
  };

  const updateGameBreak = () => {
    const payload = {
      break_start_time: editStartTime,
      break_end_time: editEndTime,
      cal_config_recurr_id: configId,
    };
    setGameBreaksLoading(true);
    api.games
      .updateGameBreak(editGameBreak?.game_break_id, payload)
      .then(res => {
        setGameBreaksLoading(false);
        if (res.data.status === "success") {
          getGameBreaks(configId);
          setEditGameBreakModal(false);
          setStartTime("");
          setEndTime("");
        }
      })
      .catch(err => {
        setGameBreaksError(false);
        setCreateCalendarError(err.response?.data.message || err.message);
      });
  };

  const deleteGameBreak = id => {
    api.games
      .deleteGameBreak(id)
      .then(res => {
        if (res.status === 200) {
          getGameBreaks(configId);
          setCustomModal(false);
        }
      })
      .catch(err => {
        setGameBreaksError(err.response?.data.message || err.message);
      });
  };

  const handleDeleteGameBreak = data => {
    setCustomModal(
      <CustomModal
        title="Delete Game Break?"
        content={<p>Do you want to delete this Game break?</p>}
        onOk={() => {
          deleteGameBreak(data.game_break_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      game_profile_id: editCalendar?.game_profile_id || "",
      calender_type: editCalendar?.calender_type || "",
    },
    validationSchema: Yup.object({
      game_profile_id: Yup.string().required("Please select game profile"),
      calender_type: Yup.string().required("Please select calendar type"),
    }),
    onSubmit: values => {
      editCalendar
        ? updateCalendar(editCalendar.calendar_config_id, values)
        : createCalendar(values);
    },
  });

  useEffect(() => {
    getAllCalendars();
    getAllProfiles();
  }, []);

  useEffect(() => {
    getCalendarError &&
      setTimeout(() => {
        setGetCalendarError(null);
      }, 3000);
    createCalendar &&
      setTimeout(() => {
        setCreateCalendarError(null);
      }, 3000);
  });

  //meta title
  document.title = "Bingo Calendar";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Bingo" breadcrumbItem="Bingo Calendar" />

        {!!showDetailsModal && (
          <CalendarTypes
            visible={!!showDetailsModal}
            closeModal={() => setShowDetailsModal(null)}
            gameCalendar={showDetailsModal}
          />
        )}

        {getCalendarError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!getCalendarError}
            toggle={() => setGetCalendarError(null)}
          >
            {getCalendarError}
          </Alert>
        )}

        {customModal}

        <Card loading={getCalendarsLoading}>
          <TableContainer
            columns={columns}
            data={allCalendars}
            addButtonText="Add New Calendar"
            isGlobalFilter={true}
            setShowDetailsModal={() => {}}
            isAddOptions={true}
            customPageSize={10}
            className="custom-header-css"
            addNewGroup={() => setOpenAddGroupModal(true)}
          />
        </Card>
      </div>

      <Modal
        size="lg"
        isOpen={openAddGroupModal}
        toggle={() => {
          setEditCalendar(null);
          setShowDetailsModal(null);
          setOpenAddGroupModal(false);
        }}
      >
        {createCalendarError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!createCalendarError}
            toggle={() => setCreateCalendarError(null)}
          >
            {createCalendarError}
          </Alert>
        )}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add New Calendar
          </h5>
          <button
            type="button"
            onClick={() => {
              setEditCalendar(null);
              setShowDetailsModal(null);
              setOpenAddGroupModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col lg={6}>
                    <p className="mt-4">
                      <b>Bingo Profile</b>
                    </p>
                    <div className="mb-3">
                      <Input
                        type="select"
                        name="game_profile_id"
                        id="game_profile_id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.game_profile_id || ""}
                        invalid={
                          validation.touched.game_profile_id &&
                          validation.errors.game_profile_id
                            ? true
                            : false
                        }
                      >
                        <option value="">Select one...</option>
                        {gameProfiles.map(profile => (
                          <option
                            key={profile.game_profile_id}
                            value={profile.game_profile_id}
                          >
                            {profile.game_name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.game_profile_id &&
                      validation.errors.game_profile_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.game_profile_id}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <p className="mt-4">
                      <b>Calendar Type</b>
                    </p>
                    <div className="mb-3">
                      <Input
                        type="select"
                        name="calender_type"
                        id="calender_type"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.calender_type || ""}
                        invalid={
                          validation.touched.calender_type &&
                          validation.errors.calender_type
                            ? true
                            : false
                        }
                      >
                        <option value="">Select one...</option>
                        <option value="single">single</option>
                        <option value="recurring">recurring</option>
                        <option value="monthly">monthly</option>
                      </Input>
                      {validation.touched.calender_type &&
                      validation.errors.calender_type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.calender_type}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setShowDetailsModal(null);
              setEditCalendar(false);
              setOpenAddGroupModal(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={validation.handleSubmit}
            loading={createCalendarLoading ? "true" : "false"}
          >
            Save changes
          </button>
        </div>
      </Modal>
      <Modal
        size="lg"
        isOpen={showGameBreakModal}
        toggle={() => {
          setShowGameBreakModal(null);
        }}
      >
        {gameBreaksError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!gameBreaksError}
            toggle={() => setGameBreaksError(null)}
          >
            {gameBreaksError}
          </Alert>
        )}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Game Breaks
          </h5>
          {!gameBreaksError && (
            <button
              type="button"
              onClick={() => {
                setEditCalendar(null);
                setShowGameBreakModal(null);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
        <div className="modal-body">
          {allGameBreaks.length > 0 && (
            <TableContainer
              columns={gameBreakColumns}
              data={allGameBreaks}
              addButtonText="Add Game Break"
              isGlobalFilter={true}
              setShowDetailsModal={() => {}}
              isAddOptions={false}
              customPageSize={10}
              className="custom-header-css"
              isNoPagination={true}
              // addNewGroup={() => setOpenAddGroupModal(true)}
            />
          )}
          {allGameBreaks.length === 0 && (
            <h5 style={{ textAlign: "center", marginBottom: 30 }}>
              No Game Breaks found
            </h5>
          )}
          <div>
            <TimePicker
              placeholder="Start time"
              size="large"
              format={format}
              value={startTime}
              onChange={val => setStartTime(val)}
            />
            <TimePicker
              placeholder="End time"
              size="large"
              format={format}
              value={endTime}
              onChange={val => setEndTime(val)}
              style={{ marginLeft: 30 }}
            />
            <button
              type="button"
              onClick={() => {
                createGameBreak();
              }}
              className="btn btn-secondary "
              style={{ marginLeft: 20 }}
            >
              Add Game Break
            </button>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setEditCalendar(false);
              setShowGameBreakModal(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        size="lg"
        isOpen={showEditBreakModal}
        toggle={() => {
          setEditGameBreakModal(null);
        }}
      >
        {gameBreaksError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!gameBreaksError}
            toggle={() => setGameBreaksError(null)}
          >
            {gameBreaksError}
          </Alert>
        )}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Edit Game Break
          </h5>
          <button
            type="button"
            onClick={() => {
              setEditGameBreak(null);
              setEditGameBreakModal(null);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            <input
              placeholder="Start time"
              size="large"
              value={editStartTime}
              onChange={e => setEditStartTime(e.target.value)}
              style={{
                height: "35px",
                fontSize: "14px",
                width: "120px",
                padding: 10,
              }}
            />
            <input
              placeholder="End time"
              size="large"
              value={editEndTime}
              onChange={e => setEditEndTime(e.target.value)}
              style={{
                height: "35px",
                fontSize: "14px",
                width: "120px",
                marginLeft: 30,
                padding: 10,
              }}
            />
            <button
              type="button"
              onClick={() => {
                updateGameBreak();
              }}
              className="btn btn-secondary "
              style={{ marginLeft: 20 }}
            >
              Edit Game Break
            </button>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setEditGameBreak(null);
              setEditGameBreakModal(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};
BingoGroups.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default BingoGroups;
