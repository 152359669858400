import React, { useState, useEffect } from "react";
import {
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Alert,
  Button,
  Form,
  UncontrolledTooltip,
  Input,
  Progress,
} from "reactstrap";
import api from "../../api";
import { useFormik } from "formik";
import { DatePicker } from "antd";
import moment from "moment";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { DeleteFilled } from "@ant-design/icons";
import TableContainer from "./GroupsTable";
const WhiteList = ({ editBingoProfile, openWhiteList, setOpenWhiteList }) => {
  const [startDate, setStartDate] = useState(Date.now());
  const [open, setOpen] = useState(false);
  const [createCalendarScheduleError, setCreateCalendarScheduleError] =
    useState(null);
  const [whiteList, setWhiteList] = useState([]);

  useEffect(() => {
    getAllWhiteList();
  }, [editBingoProfile]);

  const getAllWhiteList = () => {
    api.games
      .getWhiteList(editBingoProfile?.game_profile_id)
      .then(res => {
        if (res.status === 200) {
          setWhiteList(res.data.data);
        }
      })
      .catch(res => {
        alert("error fetching whitelist");
      });
  };

  const deleteFromWhiteList = id => {
    api.games
      .deleteFromWhiteList(id)
      .then(res => {
        if (res.status === 200) {
          getAllWhiteList();
        }
      })
      .catch(err => {
        alert("Error Adding To Whitelist");
      });
  };
  const AddToWhiteList = payload => {
    api.games
      .AddToWhiteList(payload)
      .then(res => {
        if (res.status === 200) {
          getAllWhiteList();
          setOpen(false);
          return alert("Added Successfully");
        }
      })
      .catch(err => {
        alert("Error Adding To Wishlist");
      });
  };

  const country = localStorage.getItem("country") || "ng";
  const getCountryOffset = country => {
    switch (country) {
      case "ng":
        return "+01:00";
      case "gh":
        return "+00:00";
      case "zm":
        return "+02:00";
      case "cd":
        return "+01:00";
      default:
        return "+01:00";
    }
  };

  const handleSingleDateChange = val => {
    const startDate = moment(val).format("YYYY-MM-DD HH:mm:ss");
    setStartDate(startDate);
  };

  let start_date = startDate + getCountryOffset(country);

  const WhiteListValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      game_profile_id: editBingoProfile?.game_profile_id,
      user_id: "",
      date: startDate,
    },
    validationSchema: Yup.object({
      user_id: Yup.string().required("Please input a user id"),
    }),

    onSubmit: values => {
      AddToWhiteList(values);
    },
  });

  const freeColumns = [
    {
      Header: "User Id",
      accessor: "user_id",
      filterable: true,
    },
    {
      Header: "Date",
      filterable: true,
      Cell: cellProps => {
        return (
          <div className="d-flex gap-3">
            {moment(cellProps.row.original.date).toLocaleString()}
          </div>
        );
      },
    },

    {
      Header: "Actions",
      Cell: cellProps => {
        return (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const data = cellProps.row.original;
                deleteFromWhiteList(data?.free_game_whitelist_id);
              }}
            >
              <DeleteFilled className="font-size-12" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <Modal size="md" isOpen={openWhiteList}>
      <ModalHeader toggle={() => setOpenWhiteList(false)} tag="h4">
        Whitelist
      </ModalHeader>
      <ModalBody>
        <div>
          {open && (
            <div>
              <h6 style={{ marginTop: "20px" }}>Whitelist User</h6>
              <Col span={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputState">
                    <b>User ID</b>
                  </Label>
                  <Input
                    type="text"
                    name="user_id"
                    id="user_id"
                    className="form-control"
                    onChange={WhiteListValidation.handleChange}
                    onBlur={WhiteListValidation.handleBlur}
                    value={WhiteListValidation.values.user_id || ""}
                  ></Input>
                  {WhiteListValidation.touched.user_id &&
                  WhiteListValidation.errors.user_id ? (
                    <FormFeedback type="invalid">
                      {WhiteListValidation.errors.user_id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <div span={6}>
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  className="mb-3"
                >
                  <Label htmlFor="formrow-InputState">
                    <b>Date</b>
                  </Label>
                  <DatePicker
                    size="large"
                    onChange={handleSingleDateChange}
                    format="YYYY-MM-DD hh:mm a"
                    value={moment(startDate)}
                    showTime={{
                      format: "hh:mm a",
                    }}
                  />

                  {WhiteListValidation.touched.date &&
                  WhiteListValidation.errors.date ? (
                    <FormFeedback type="invalid">
                      {WhiteListValidation.errors.date}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <Alert
                color="danger"
                fade={true}
                isOpen={!!createCalendarScheduleError}
                toggle={() => setCreateCalendarScheduleError(null)}
              >
                {createCalendarScheduleError}
              </Alert>
              <div className="modal-footer">
                <Button
                  type="button"
                  onClick={WhiteListValidation.handleSubmit}
                  className="btn btn-success "
                  // disabled={editBingoProfile}
                >
                  Add
                </Button>
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          <TableContainer
            columns={freeColumns}
            data={whiteList}
            setShowDetailsModal={() => {}}
            isGlobalFilter={true}
            free={false}
            isAddUserList={false}
            isWhiteList={!open}
            handleAddWhiteList={() => {
              setOpen(true);
            }}
            handleUserClick={() => {}}
            handleWhiteList={() => {}}
            customPageSize={10}
            className="custom-header-css"
          />
          {/* {whiteList.length > 0 ? (
            whiteList?.map(l => {
              return (
                <div
                  key={l.user_id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div>
                    <span style={{ fontSize: "13px" }}>User Id: </span>
                    <span style={{ fontSize: "12px" }}>{l.user_id}</span>
                  </div>{" "}
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      deleteFromWhiteList(l?.free_game_whitelist_id);
                    }}
                  >
                    <DeleteFilled className="font-size-12" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </div>
              );
            })
          ) : (
            <div>empty</div>
          )} */}
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={() => setOpenWhiteList(false)}
            className="btn btn-danger"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WhiteList;
