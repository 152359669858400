import React, { useEffect, useMemo, useState } from "react";
import api from "../../api";
import { Alert, Input, UncontrolledTooltip } from "reactstrap";
import { Card, Switch } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "./SlotsTable";
import { Link } from "react-router-dom";
import CustomModal from "../../reusables/CustomModal";

const SlotsManagement = () => {
  const [slots, setSlots] = useState([]);
  const [getSlotsLoading, setGetSlotsLoading] = useState(false);
  const [getSlotsError, setGetSlotsError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [slotOrder, setSlotOrder] = useState("");
  const [slot, setSlot] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Icon",
        accessor: "LobbyIcon",
        Cell: data => (
          <img
            src={data.value}
            style={{ objectFit: "cover", width: 50, height: 50 }}
          />
        ),
      },
      {
        Header: "Game Name",
        accessor: "GameName",
      },
      {
        Header: "Game Code",
        accessor: "GameCode",
      },
      {
        Header: "Order",
        accessor: "order",
      },
      {
        Header: "Status",
        accessor: "enabled",
        Cell: data => (
          <Switch
            checkedChildren="Active"
            checked={data.value}
            onChange={() => onChange(data.row.original.GameCode)}
          />
        ),
      },
      {
        Header: "Actions",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  setShowModal(true);
                  setSlot(data);
                  setSlotOrder(data.order);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],

    []
  );

  const onChange = gameCode => {
    api.slots
      .toggleGame({ game_code: gameCode })
      .then(res => {
        if (res.status === 200) {
          getAllSlots(pageSize, pageIndex + 1);
        }
      })
      .catch(err => {
        setGetSlotsError(err.response?.data.message || err.message);
      });
  };

  const onChangeSlotOrder = value => setSlotOrder(value);

  const onUpdateSlotOrder = () => {
    api.slots
      .updateGameOrder({ game_code: slot.GameCode, order: slotOrder })
      .then(res => {
        if (res.status === 200) {
          setShowModal(false);
          getAllSlots(pageSize, pageIndex + 1);
        }
      })
      .catch(err => {
        setShowModal(false);
        setGetSlotsError(err.response?.data.message || err.message);
      });
  };

  const getAllSlots = (limit, page) => {
    setGetSlotsLoading(true);
    api.slots
      .getAllSlotGames(limit, page)
      .then(res => {
        setGetSlotsLoading(false);
        if (res.status === 200) {
          setSlots(res.data.data.directory.sort((a, b) => b.order - a.order));
          setPageCount(res.data.data.totalPages);
        }
      })
      .catch(err => {
        setGetSlotsLoading(false);
        setGetSlotsError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    getSlotsError &&
      setTimeout(() => {
        setGetSlotsError(null);
      }, 3000);
  });

  useEffect(() => {
    getAllSlots(pageSize, pageIndex + 1, "");
  }, [pageIndex, pageSize]);

  useEffect(() => {
    getAllSlots(pageSize, 1, filters);
  }, [filters]);

  //meta title
  document.title = "Slots";

  return (
    <div className="page-content">
      {getSlotsError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getSlotsError}
          toggle={() => setGetSlotsError(null)}
        >
          {getSlotsError}
        </Alert>
      )}
      <CustomModal
        title={<b>{slot?.GameName}</b>}
        content={
          <div>
            <h5>Slot Order</h5>
            <Input
              type="number"
              value={slotOrder}
              onChange={e => {
                onChangeSlotOrder(e.target.value);
              }}
            />
          </div>
        }
        closeModal={() => setShowModal(false)}
        cancelText="Cancel"
        okText="Submit"
        onOk={onUpdateSlotOrder}
        visible={showModal}
      />
      <div className="container-fluid">
        <Breadcrumbs title="Slots" breadcrumbItem="Slots Management" />
        <Card loading={getSlotsLoading}>
          <TableContainer
            columns={columns}
            data={slots}
            customPageSize={pageSize}
            setShowDetailsModal={() => {}}
            className="custom-header-css"
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            controlledPageIndex={pageIndex}
            pageCount={pageCount}
          />
        </Card>
      </div>
    </div>
  );
};
export default SlotsManagement;
