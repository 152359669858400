// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Col,
  Row,
  Label,
  Form,
  Input,
  FormFeedback,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import api from "../../../api";

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "../GroupsTable";
import { Card } from "antd";
import CustomModal from "../../../reusables/CustomModal";

const CustomRTP = () => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [gameRTPs, setBingoRTPs] = useState([]);
  const [customRTPs, setCustomBingoRTPs] = useState([]);
  const [getBingoRTPLoading, setGetBingoRTPLoading] = useState(false);
  const [getRTPError, setGetRTPError] = useState(null);
  const [createBingoRTPLoading, setCreateBingoRTPLoading] = useState(false);
  const [createRTPError, setCreateRTPError] = useState(null);
  const [editBingoRTP, setEditBingoRTP] = useState(null);
  const [deleteBingoRTPLoading, setDeleteBingoRTPLoading] = useState(false);
  const [customModal, setCustomModal] = useState(null);
  const [gameProfiles, setBingoProfiles] = useState([]);

  const columns = [
    {
      Header: "RTP Name",
      accessor: "game_rtp_settings_id",
      Cell: data => {
        const gameRtp = gameRTPs.find(
          one => one.game_rtp_settings_id === data.value
        );
        return gameRtp?.rtp_name || "N/A";
      },
    },
    {
      Header: "Game Profile",
      accessor: "game_profile_id",
      Cell: data => {
        const gameProfile = gameProfiles.find(
          one => one.game_profile_id === data.value
        );
        return gameProfile?.game_name || "N/A";
      },
    },
    {
      Header: "RTP Percentage",
      accessor: "rtp_percentage",
    },
    {
      Header: "Actions",
      Cell: cellProps => {
        return (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const data = cellProps.row.original;
                setOpenAddGroupModal(true);
                setEditBingoRTP(data);
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const data = cellProps.row.original;
                handleDeleteRTP(data);
              }}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        );
      },
    },
  ];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      rtp_name: editBingoRTP?.rtp_name || "",
      number_of_tickets_sold: editBingoRTP?.number_of_tickets_sold || "",
      rtp_percentage: editBingoRTP?.rtp_percentage || "",
      game_profile_id: editBingoRTP?.game_profile_id || "",
    },
    validationSchema: Yup.object({
      rtp_name: Yup.string().required("Please enter RTP name"),
      number_of_tickets_sold: Yup.number().required(
        "Please enter number of tickets sold"
      ),
      rtp_percentage: Yup.number().required("Please enter RTP percentage"),
      game_profile_id: Yup.string().required("Please select game profile"),
    }),
    onSubmit: values => {
      const editValues = values;
      delete editValues.rtp_name;
      delete values.rtp_percentage;
      editBingoRTP
        ? handleEditRTP(editBingoRTP.game_rtp_settings_id, editValues)
        : createRTP(values);
    },
  });

  const getAllCustomBingoRTPs = () => {
    setGetBingoRTPLoading(true);
    api.games
      .getAllCustomBingoRTP()
      .then(res => {
        setGetBingoRTPLoading(false);
        if (res.status === 200) {
          setCustomBingoRTPs(res.data.data);
        }
      })
      .catch(err => {
        setGetBingoRTPLoading(false);
        setGetRTPError(err.response?.data.message || err.message);
      });
  };

  const getAllBingoRTPs = () => {
    setGetBingoRTPLoading(true);
    api.games
      .getAllBingoRTP()
      .then(res => {
        setGetBingoRTPLoading(false);
        if (res.status === 200) {
          setBingoRTPs(res.data.data);
        }
      })
      .catch(err => {
        setGetBingoRTPLoading(false);
        setGetRTPError(err.response?.data.message || err.message);
      });
  };

  const getAllBingoProfiles = () => {
    api.games
      .getAllBingoProfiles()
      .then(res => {
        if (res.status === 200) {
          setBingoProfiles(res.data.data);
        }
      })
      .catch(err => {
        setGetRTPError(err.response?.data.message || err.message);
      });
  };

  const createRTP = payload => {
    setCreateBingoRTPLoading(true);
    api.games
      .createCustomBingoRTP(payload)
      .then(res => {
        setCreateBingoRTPLoading(false);
        if (res.status === 201) {
          getAllBingoRTPs();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Custom RTP Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoRTPLoading(false);
        setCreateRTPError(err.response?.data.message || err.message);
      });
  };

  const handleEditRTP = (id, payload) => {
    setCreateBingoRTPLoading(true);
    api.games
      .updateCustomBingoRTP(id, payload)
      .then(res => {
        setCreateBingoRTPLoading(false);
        if (res.status === 200) {
          getAllBingoRTPs();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo RTP Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                setEditBingoRTP(null);
                validation.handleReset();
              }}
              closeModal={() => {
                setEditBingoRTP(null);
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoRTPLoading(false);
        setCreateRTPError(err.response?.data.message || err.message);
      });
  };

  const deleteBingoRTP = id => {
    setDeleteBingoRTPLoading(true);
    api.games
      .deleteCustomBingoRTP(id)
      .then(res => {
        setDeleteBingoRTPLoading(false);
        if (res.status === 200) {
          getAllBingoRTPs();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo RTP Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setDeleteBingoRTPLoading(false);
        setGetRTPError(err.response?.data.message || err.message);
      });
  };

  const handleDeleteRTP = data => {
    setCustomModal(
      <CustomModal
        title="Delete Bingo RTP?"
        loading={deleteBingoRTPLoading}
        content={<p>Do you want to delete this RTP setting?</p>}
        onOk={() => {
          deleteBingoRTP(data.game_rtp_settings_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  useEffect(() => {
    getAllBingoRTPs();
    getAllBingoProfiles();
    getAllCustomBingoRTPs();
  }, []);
  useEffect(() => {
    getRTPError &&
      setTimeout(() => {
        setGetRTPError(null);
      }, 3000);
  });
  useEffect(() => {
    createRTPError &&
      setTimeout(() => {
        setCreateRTPError(null);
      }, 3000);
  });

  //meta title
  document.title = "Custom Bingo RTP";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Bingo" breadcrumbItem="Custom Bingo RTP" />
        {getRTPError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!getRTPError}
            toggle={() => setGetRTPError(null)}
          >
            {getRTPError}
          </Alert>
        )}
        <Card loading={getBingoRTPLoading}>
          <TableContainer
            columns={columns}
            data={customRTPs}
            setShowDetailsModal={() => {}}
            addButtonText="Create Custom RTP"
            isGlobalFilter={true}
            isAddOptions={true}
            customPageSize={10}
            className="custom-header-css"
            addNewGroup={() => setOpenAddGroupModal(true)}
          />
        </Card>
      </div>

      {customModal}

      <Modal
        size="lg"
        isOpen={openAddGroupModal}
        toggle={() => {
          setEditBingoRTP(null);
          setOpenAddGroupModal(false);
        }}
      >
        {createRTPError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!createRTPError}
            toggle={() => setCreateRTPError(null)}
          >
            {createRTPError}
          </Alert>
        )}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add New RTP Setting
          </h5>
          <button
            type="button"
            onClick={() => {
              setEditBingoRTP(null);
              setOpenAddGroupModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="rtp_name">
                    <b>RTP Name</b>
                  </Label>
                  <Input
                    type="select"
                    className="form-control"
                    id="rtp_name"
                    name="rtp_name"
                    placeholder="Enter Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.rtp_name || ""}
                    invalid={
                      validation.touched.rtp_name && validation.errors.rtp_name
                        ? true
                        : false
                    }
                  >
                    <option value="">Select one...</option>
                    {gameRTPs.map(val => (
                      <option
                        key={val.game_rtp_settings_id}
                        value={val.game_rtp_settings_id}
                      >
                        {val.rtp_name}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.rtp_name && validation.errors.rtp_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.rtp_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="number_of_tickets_sold">
                    <b>Number of tickets sold</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="number_of_tickets_sold"
                    name="number_of_tickets_sold"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.number_of_tickets_sold || ""}
                    invalid={
                      validation.touched.number_of_tickets_sold &&
                      validation.errors.number_of_tickets_sold
                        ? true
                        : false
                    }
                  />
                  {validation.touched.number_of_tickets_sold &&
                  validation.errors.number_of_tickets_sold ? (
                    <FormFeedback type="invalid">
                      {validation.errors.number_of_tickets_sold}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="rtp_percentage">
                    <b>RTP Percentage</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="rtp_percentage"
                    name="rtp_percentage"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.rtp_percentage || ""}
                    invalid={
                      validation.touched.rtp_percentage &&
                      validation.errors.rtp_percentage
                        ? true
                        : false
                    }
                  />
                  {validation.touched.rtp_percentage &&
                  validation.errors.rtp_percentage ? (
                    <FormFeedback type="invalid">
                      {validation.errors.rtp_percentage}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="game_profile_id">
                    <b>Bingo Profile</b>
                  </Label>
                  <Input
                    type="select"
                    className="form-control"
                    id="game_profile_id"
                    name="game_profile_id"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.game_profile_id || ""}
                    invalid={
                      validation.touched.game_profile_id &&
                      validation.errors.game_profile_id
                        ? true
                        : false
                    }
                  >
                    <option defaultValue value="">
                      Select one...
                    </option>
                    {gameProfiles.map(profile => (
                      <option
                        key={profile.game_profile_id}
                        value={profile.game_profile_id}
                      >
                        {profile.game_name}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.game_profile_id &&
                  validation.errors.game_profile_id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.game_profile_id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setEditBingoRTP(null);
              setOpenAddGroupModal(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            loading={createBingoRTPLoading ? "true" : "false"}
            onClick={validation.handleSubmit}
          >
            Save changes
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CustomRTP;
