import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import moment from "moment"
import TableContainer from "./../GroupsTable";
import {
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Alert,
  Button,
  Form,
  UncontrolledTooltip,
  Input,
  Progress,
} from "reactstrap";
import { Select, Space, message } from "antd";
const { Option } = Select;
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CustomModal from "../../../reusables/CustomModal";
import api from "../../../api";
import { Card, Checkbox, DatePicker } from "antd";
import { SettingFilled, DeleteFilled, EditFilled } from "@ant-design/icons";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

function WinningFactor() {
  const [getBingosError, setGetBingosError] = useState(null);
  const [winningFactor, setWinningFactor] = useState([]);
  const [startTime, setStartTime] = useState(new Date().getTime());
  const [endTime, setEndTime] = useState(new Date().getTime());
  const [singleFactor, setSingleFactor] = useState(null);
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState("");
  const [rooms, setRooms] = useState([]);
  const [localRooms, setLocalRooms] = useState([]);
  const [globalRooms, setGlobalRooms] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [createCalendarScheduleError, setCreateCalendarScheduleError] =
    useState(null);
  const country = localStorage.getItem("country");
  const isGlobal = country === "global";
  useEffect(() => {
    const fetch = async () => await getWinningFactors();
    fetch();
  }, []);

  useEffect(() => {
    open && getRooms();
  }, [open]);

  useEffect(() => {
    if (singleFactor) {
      setLocalRooms(singleFactor.group_to_effect.locals);
      setGlobalRooms(singleFactor.group_to_effect.globals);
      setEnabled(singleFactor.enabled);

      if (singleFactor.rdc) {
        setCheck("rdc");
      } else if (singleFactor.ftd) {
        setCheck("ftd");
      }
    }
  }, [singleFactor]);
  useEffect(() => {
    singleFactor &&
      setStartTime(moment(singleFactor?.start_time, "HH:mm:ss").valueOf());
    singleFactor &&
      setEndTime(moment(singleFactor?.end_time, "HH:mm:ss").valueOf());
  }, [singleFactor]);
  const getWinningFactors = async () => {
    try {
      const data = await api.tickets.getAllWinningFactors();

      setWinningFactor(data.data.data);
      return data.data;
    } catch (error) {
      setGetBingosError(error.response.data.message);
      alert(error.response.data.message);
      setGetBingosError(error.response.data.message);
    }
  };

  const handleSelectChange = (value, type) => {
    if (type == "global") {
      setGlobalRooms(value);
    } else {
      setLocalRooms(value);
    }
  };

  const resetAll = () => {
    setOpen(false);
    setEnabled(false);
    setLocalRooms([]);
    setGlobalRooms([]);
    setCheck("");
    setStartTime(new Date().getTime());
    setEndTime(new Date().getTime());
    setSingleFactor(null)
    WinningFactorValidation.resetForm()
  };

  const getRooms = () => {
    api.games
      .getAllBingoGroups()
      .then(res => {
        if (res.status === 200) {
          setRooms(res.data.data);
        }
      })
      .catch(err => {
        setGetBingosError(err.response?.data.message || err.message);
      });
  };
  const updateWinningFactor = async body => {
    try {
      const data = await api.tickets.editWinningFactor(body);
      if (data.status === 200) {
        getWinningFactors();
        resetAll();
        message.success("Winning Factor updated successfully");
      }
    } catch (error) {
      setGetBingosError(error.response.data.message);
      alert(error.response.data.message);
      setGetBingosError(error.response.data.message);
    }
  };

  const createWinningFactor = async body => {
    try {
      const data = await api.tickets.createWinningFactor(body);
      if (data.status === 200) {
        await getWinningFactors();
        resetAll();

        message.success("Winning Factor created successfully");
      }
      return data.data;
    } catch (error) {
      setGetBingosError(error.response.data.message);
      alert(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  const deleteWinningFactor = async id => {
    try {
      const data = await api.tickets.deleteWinningFactor(id);
      if (data.status == 200) {
        console.log("deleted");
        getWinningFactors();
      }
    } catch (error) {
      setGetBingosError(error.data.data.message);
    }
  };
  const freeColumns = [
    {
      Header: "Minimum Daily Deposit",
      accessor: "minimum_daily_deposit",
      filterable: true,
    },
    {
      Header: "Maximum Daily Deposit",
      accessor: "maximum_daily_deposit",
      filterable: true,
    },
    {
      Header: "Minimum Daily Win",
      accessor: "minimum_daily_win",
      filterable: true,
    },
    {
      Header: "Maximum Daily Win",
      accessor: "maximum_daily_win",
      filterable: true,
    },
    {
      Header: "Minimum Daily Loss",
      accessor: "minimum_daily_loss",
      filterable: true,
    },
    {
      Header: "Maximum Daily Loss",
      accessor: "maximum_daily_loss",
      filterable: true,
    },
    {
      Header: "Minimum Daily Withdrawal",
      accessor: "minimum_daily_withdrawal",
      filterable: true,
    },
    {
      Header: "Maximum Daily Withdrawal",
      accessor: "maximum_daily_withdrawal",
      filterable: true,
    },
    {
      Header: "Country",
      accessor: "country",
      filterable: true,
    },
    {
      Header: "Start Time",
      accessor: "start_time",
      filterable: true,
    },
    {
      Header: "End Time",
      accessor: "end_time",
      filterable: true,
    },

    {
      Header: "Actions",
      Cell: cellProps => {
        return (
          <div className="d-flex gap-3">
            {
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  //console.log(editBingoProfile);
                  const data = cellProps.row.original;
                  setOpen(true);
                  setSingleFactor(data);
                }}
              >
                <EditFilled className="font-size-12" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            }
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const data = cellProps.row.original;
                deleteWinningFactor(data?.winning_factor_id);
              }}
            >
              <DeleteFilled className="font-size-12" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        );
      },
    },
  ];
  const WinningFactorValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      winning_factor_id: singleFactor?.winning_factor_id,
      minimum_daily_deposit: singleFactor?.minimum_daily_deposit || 0,
      maximum_daily_deposit: singleFactor?.maximum_daily_deposit || 0,
      minimum_daily_win: singleFactor?.minimum_daily_win || 0,
      maximum_daily_win: singleFactor?.maximum_daily_win || 0,
      minimum_daily_loss: singleFactor?.minimum_daily_loss || 0,
      maximum_daily_loss: singleFactor?.maximum_daily_loss || 0,

      minimum_daily_withdrawal: singleFactor?.minimum_daily_withdrawal || 0,
      maximum_daily_withdrawal: singleFactor?.maximum_daily_withdrawal || 0,
      country: singleFactor?.country || singleFactor?.country || country,
    },
    validationSchema: Yup.object({
      country: Yup.string().required("Please pick country"),
    }),

    onSubmit: values => {
      (values.ftd = check === "ftd"),
        (values.rdc = check === "rdc"),
        (values.enabled = enabled),
        (values.start_time = moment(startTime).format("HH:mm:ss"));
      values.end_time = moment(endTime).format("HH:mm:ss");
      (values.group_to_effect = {
        locals: localRooms,
        globals: globalRooms,
      }),
        singleFactor
          ? updateWinningFactor(values)
          : createWinningFactor(values);
    },
  });
  const handleSingleDateChange = (val, type) => {
    console.log(val, type);
    if (!val) {
      setCreateCalendarScheduleError("invalid time");
    }
    if (type == "start") {
      setStartTime(val);
    }
    if (type == "end") {
      setEndTime(val);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Bingos" breadcrumbItem="Winning Factor" />

        <Modal size="md" isOpen={open}>
          <ModalHeader
            toggle={() => {
              resetAll()
              setOpen(false);
            }}
            tag="h4"
          >
            {!singleFactor ? "Add" : "Edit"} Winning Factor
          </ModalHeader>
          <ModalBody>
            <div>
              <div>
                {getBingosError && (
                  <Alert
                    color="danger"
                    fade={true}
                    //isOpen={!!getBingosError}
                    toggle={() => setGetBingosError(null)}
                  >
                    {getBingosError}
                  </Alert>
                )}
                <h6 style={{ marginTop: "20px" }}>Winning Factor</h6>
                <Row>
                  <Col span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Minimum Daily Deposit</b>
                      </Label>
                      <Input
                        type="number"
                        name="minimum_daily_deposit"
                        id="minimum_daily_deposit"
                        className="form-control"
                        placeholder="0"
                        onChange={WinningFactorValidation.handleChange}
                        onBlur={WinningFactorValidation.handleBlur}
                        value={
                          WinningFactorValidation.values
                            .minimum_daily_deposit || ""
                        }
                      ></Input>
                      {WinningFactorValidation.touched.minimum_daily_deposit &&
                      WinningFactorValidation.errors.minimum_daily_deposit ? (
                        <FormFeedback type="invalid">
                          {WinningFactorValidation.errors.minimum_daily_deposit}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Maximum Daily Deposit</b>
                      </Label>
                      <Input
                        type="number"
                        name="maximum_daily_deposit"
                        id="maximum_daily_deposit"
                        className="form-control"
                        min={0}
                        onChange={WinningFactorValidation.handleChange}
                        onBlur={WinningFactorValidation.handleBlur}
                        value={
                          WinningFactorValidation.values
                            .maximum_daily_deposit || ""
                        }
                      ></Input>
                      {WinningFactorValidation.touched.maximum_daily_deposit &&
                      WinningFactorValidation.errors.maximum_daily_deposit ? (
                        <FormFeedback type="invalid">
                          {WinningFactorValidation.errors.maximum_daily_deposit}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Minimum Daily Win</b>
                      </Label>
                      <Input
                        type="number"
                        name="minimum_daily_win"
                        id="minimum_daily_win"
                        placeholder="0"
                        className="form-control"
                        onChange={WinningFactorValidation.handleChange}
                        onBlur={WinningFactorValidation.handleBlur}
                        value={
                          WinningFactorValidation.values.minimum_daily_win || ""
                        }
                      ></Input>
                      {WinningFactorValidation.touched.minimum_daily_win &&
                      WinningFactorValidation.errors.minimum_daily_win ? (
                        <FormFeedback type="invalid">
                          {WinningFactorValidation.errors.minimum_daily_win}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Maximum Daily Win</b>
                      </Label>
                      <Input
                        type="number"
                        name="maximum_daily_win"
                        id="maximum_daily_win"
                        className="form-control"
                        onChange={WinningFactorValidation.handleChange}
                        onBlur={WinningFactorValidation.handleBlur}
                        value={
                          WinningFactorValidation.values.maximum_daily_win || ""
                        }
                      ></Input>
                      {WinningFactorValidation.touched.maximum_daily_win &&
                      WinningFactorValidation.errors.maximum_daily_win ? (
                        <FormFeedback type="invalid">
                          {WinningFactorValidation.errors.maximum_daily_win}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                  <Row>
                  <Col span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Minimum Daily Loss</b>
                      </Label>
                      <Input
                        type="number"
                        name="minimum_daily_loss"
                        id="minimum_daily_loss"
                        placeholder="0"
                        className="form-control"
                        onChange={WinningFactorValidation.handleChange}
                        onBlur={WinningFactorValidation.handleBlur}
                        value={
                          WinningFactorValidation.values.minimum_daily_loss || ""
                        }
                      ></Input>
                      {WinningFactorValidation.touched.minimum_daily_loss &&
                      WinningFactorValidation.errors.minimum_daily_loss ? (
                        <FormFeedback type="invalid">
                          {WinningFactorValidation.errors.minimum_daily_loss}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Maximum Daily Loss</b>
                      </Label>
                      <Input
                        type="number"
                        name="maximum_daily_loss"
                        id="maximum_daily_loss"
                        className="form-control"
                        onChange={WinningFactorValidation.handleChange}
                        onBlur={WinningFactorValidation.handleBlur}
                        value={
                          WinningFactorValidation.values.maximum_daily_loss || ""
                        }
                      ></Input>
                      {WinningFactorValidation.touched.maximum_daily_loss &&
                      WinningFactorValidation.errors.maximum_daily_loss ? (
                        <FormFeedback type="invalid">
                          {WinningFactorValidation.errors.maximum_daily_loss}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
               
                <Row>
                  <Col span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Minimum Daily Withdrawal</b>
                      </Label>
                      <Input
                        type="number"
                        name="minimum_daily_withdrawal"
                        id="minimum_daily_withdrawal"
                        className="form-control"
                        placeholder="0"
                        onChange={WinningFactorValidation.handleChange}
                        onBlur={WinningFactorValidation.handleBlur}
                        value={
                          WinningFactorValidation.values
                            .minimum_daily_withdrawal || ""
                        }
                      ></Input>
                      {WinningFactorValidation.touched
                        .minimum_daily_withdrawal &&
                      WinningFactorValidation.errors
                        .minimum_daily_withdrawal ? (
                        <FormFeedback type="invalid">
                          {
                            WinningFactorValidation.errors
                              .minimum_daily_withdrawal
                          }
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Maximum Daily Withdrawal</b>
                      </Label>
                      <Input
                        type="number"
                        name="maximum_daily_withdrawal"
                        id="maximum_daily_withdrawal"
                        className="form-control"
                        onChange={WinningFactorValidation.handleChange}
                        onBlur={WinningFactorValidation.handleBlur}
                        value={
                          WinningFactorValidation.values
                            .maximum_daily_withdrawal || ""
                        }
                      ></Input>
                      {WinningFactorValidation.touched
                        .maximum_daily_withdrawal &&
                      WinningFactorValidation.errors
                        .maximum_daily_withdrawal ? (
                        <FormFeedback type="invalid">
                          {
                            WinningFactorValidation.errors
                              .maximum_daily_withdrawal
                          }
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "20px" }}>
                  <Col span={3}>
                    <div>
                      <Checkbox
                        name="enabled"
                        id="enabled"
                        checked={enabled}
                        //value={enabled}
                        onChange={e => {
                          setEnabled(e.target.checked);
                        }}
                      >
                        Enabled
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={3}>
                    <Checkbox
                      name="ftd"
                      id="ftd"
                      checked={check === "ftd"}
                      onChange={e => {
                        if (check === "ftd") {
                          setCheck("");
                        } else {
                          setCheck("ftd");
                        }
                      }}
                    >
                      FTD
                    </Checkbox>
                  </Col>
                  <Col span={3}>
                    <div>
                      <Checkbox
                        name="rdc"
                        id="rdc"
                        checked={check === "rdc"}
                        //value={check==="rdc"}
                        onChange={e => {
                          if (check === "rdc") {
                            setCheck("");
                          } else {
                            setCheck("rdc");
                          }
                        }}
                      >
                        RDC
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
                <div span={6}>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="mb-3"
                  >
                    <Label htmlFor="formrow-InputState">
                      <b>Start Time</b>
                    </Label>
                    <DatePicker.TimePicker
                      size="large"
                      onChange={e => handleSingleDateChange(e, "start")}
                      format="hh:mm:a"
                      value={moment(startTime)}
                      showTime={{
                        format: "HH:mm:ss",
                      }}
                    />

                    {WinningFactorValidation.touched.date &&
                    WinningFactorValidation.errors.date ? (
                      <FormFeedback type="invalid">
                        {WinningFactorValidation.errors.date}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <Alert
                  color="danger"
                  fade={true}
                  isOpen={!!createCalendarScheduleError}
                  toggle={() => setCreateCalendarScheduleError(null)}
                >
                  {createCalendarScheduleError}
                </Alert>
                <div span={6}>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="mb-3"
                  >
                    <Label htmlFor="formrow-InputState">
                      <b>End Time</b>
                    </Label>
                    <DatePicker.TimePicker
                      size="large"
                      onChange={e => handleSingleDateChange(e, "end")}
                      format="hh:mm a"
                      value={moment(endTime)}
                      showTime={{
                        format: "HH:mm:ss",
                      }}
                    />

                    {WinningFactorValidation.touched.date &&
                    WinningFactorValidation.errors.date ? (
                      <FormFeedback type="invalid">
                        {WinningFactorValidation.errors.date}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <Alert
                  color="danger"
                  fade={true}
                  isOpen={!!createCalendarScheduleError}
                  toggle={() => setCreateCalendarScheduleError(null)}
                >
                  {createCalendarScheduleError}
                </Alert>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputState">
                    <b>Country</b>
                  </Label>
                  {isGlobal ? (
                    <Input
                      type="select"
                      name="country"
                      id="country"
                      className="form-control"
                      onChange={WinningFactorValidation.handleChange}
                      onBlur={WinningFactorValidation.handleBlur}
                      value={WinningFactorValidation.values.country || ""}
                      invalid={
                        WinningFactorValidation.touched.country &&
                        WinningFactorValidation.errors.country
                          ? true
                          : false
                      }
                    >
                      <option key={0} value="">
                        Select one...
                      </option>
                      <option key={1} value={"ng"}>
                        Ng
                      </option>
                      <option key={2} value={"gh"}>
                        Gh
                      </option>
                      <option key={3} value={"zm"}>
                        Zm
                      </option>
                      <option key={4} value={"cd"}>
                        Cd
                      </option>
                    </Input>
                  ) : (
                    <Input
                      type="text"
                      name="country"
                      id="country"
                      disabled
                      className="form-control"
                      onBlur={WinningFactorValidation.handleBlur}
                      value={country.toLocaleUpperCase()}
                    />
                  )}

                  <div style={{ marginTop: "10px" }}>
                    <Label htmlFor="formrow-InputState">
                      <b>Local Game Room</b>
                    </Label>
                    <Select
                      mode="multiple"
                      style={{
                        width: "100%",
                      }}
                      placeholder="select local game room(s)"
                      value={localRooms}
                      onChange={handleSelectChange}
                      optionLabelProp="Local Game Room"
                    >
                      {rooms.map(r => {
                        return (
                          <Option
                            key={r.group_name}
                            value={rooms.group_name}
                            label={r.group_name}
                          >
                            <Space></Space>
                            {r.group_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  {isGlobal && (
                    <div style={{ marginTop: "10px" }}>
                      <Label htmlFor="formrow-InputState">
                        <b>Global Game Room</b>
                      </Label>
                      <Select
                        mode="multiple"
                        style={{
                          width: "100%",
                        }}
                        placeholder="select global game room(s)"
                        value={globalRooms}
                        onChange={e => handleSelectChange(e, "global")}
                        optionLabelProp="Global Game Room"
                      >
                        {rooms.map(r => {
                          return (
                            <Option
                              key={r.group_name}
                              value={rooms.group_name}
                              label={r.group_name}
                            >
                              <Space></Space>
                              {r.group_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                  {WinningFactorValidation.touched.range &&
                  WinningFactorValidation.errors.range ? (
                    <FormFeedback type="invalid">
                      {WinningFactorValidation.errors.range}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="modal-footer">
                  <Button
                    type="button"
                    onClick={() => {
                      WinningFactorValidation.handleSubmit();
                    }}
                    className="btn btn-success "
                    // disabled={editBingoProfile}
                  >
                    {singleFactor ? "Edit" : "Add"}
                  </Button>
                  <button
                    type="button"
                    onClick={() => {
                      resetAll()
                      setOpen(false);
                    }}
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <TableContainer
          columns={freeColumns}
          data={winningFactor}
          setShowDetailsModal={() => {}}
          isGlobalFilter={true}
          free={false}
          isAddUserList={false}
          isWinningFactor={true}
          handleAddWhiteList={() => {
            setOpen(true);
          }}
          handleUserClick={() => {}}
          handleWhiteList={() => {}}
          customPageSize={10}
          className="custom-header-css"
        />
      </Container>
    </div>
  );
}

export default WinningFactor;
