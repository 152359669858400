import React, { useEffect, useMemo, useState } from "react";
import api from "../../api";
import { Alert } from "reactstrap";
import { Card } from "antd";
import TableContainer from "./GroupsTable";
import CustomModal from "../../reusables/CustomModal";

const CancelledWithdrawals = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [getWithdrawalsLoading, setGetWithdrawalsLoading] = useState(false);
  const [getWithdrawalsError, setGetWithdrawalsError] = useState(null);
  const [customModal, setCustomModal] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");
  const [singleWithdrawal, setSingleWithdrawal] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [fetchWithdrawalLoading, setFetchWithdrawalLoading] = useState(false);

   const columns = useMemo(
     () => [
       {
         Header: "Email",
         accessor: "email",
       },
       {
         Header: "Phone No.",
         accessor: "phone_number",
       },
       {
         Header: "Local Amount",
         accessor: "local_request_amount",
         Cell: data => (data.value / 100).toLocaleString(),
       },
       {
         Header: "Base Amount",
         accessor: "base_request_amount",
         Cell: data => data.value / 100,
       },
       {
         Header: "Status",
         accessor: "status",
       },
       {
         Header: "Date",
         accessor: "request_date",
         Cell: data => new Date(data.value).toLocaleString(),
       },
     ],

     []
   );
   const fetchWithdrawalDetails = id => {
     setFetchWithdrawalLoading(true);
     api.commerce
       .fetchDeposit(id)
       .then(res => {
         if (res.status === 200) {
           console.log(singleWithdrawal);
           setFetchWithdrawalLoading(false);
           setSingleWithdrawal(res.data.data);
         }
       })
       .catch(err => {
         setFetchWithdrawalLoading(false);
         setCustomModal(null);
         setApproveWithdrawalError(err.response?.data.message || err.message);
       });
   };

   const getAllWithdrawals = (limit, page, search) => {
     setGetWithdrawalsLoading(true);
     api.commerce
       .getAllWithdrawalRequests(limit, page, search + "&status=cancelled")
       .then(res => {
         setGetWithdrawalsLoading(false);
         if (res.status === 200) {
           setWithdrawals(res.data.data.withdrawal_requests);
           setPageCount(res.data.data.totalPages);
         }
       })
       .catch(err => {
         setGetWithdrawalsLoading(false);
         setGetWithdrawalsError(err.response?.data.message || err.message);
       });
   };

   useEffect(() => {
     getWithdrawalsError &&
       setTimeout(() => {
         setGetWithdrawalsError(null);
       }, 3000);
   });

  
      
   

   useEffect(() => {
     getAllWithdrawals(pageSize, pageIndex + 1, filters);
   }, [pageIndex, pageSize]);

   useEffect(() => {
     getAllWithdrawals(pageSize, 1, filters);
   }, [filters]);

   //meta title
   document.title = "Withdrawal Requests";

   return (
     <div>
       {showModal && (
         <CustomModal
           title={<b>Withdrawal Request Details</b>}
           content={
             Object.keys(singleWithdrawal).length !== 0 ? (
               <div>
                 <p>
                   <b>Account Name:</b>{" "}
                   {
                     singleWithdrawal.user_withdraw_option?.withdrawal_details
                       .account_name
                   }
                 </p>
                 <p>
                   <b>Account Number:</b>{" "}
                   {
                     singleWithdrawal.user_withdraw_option?.withdrawal_details
                       .account_number
                   }
                 </p>

                 <p>
                   <b>User ID:</b> {singleWithdrawal.user_profile.user_id}
                 </p>
                 <p>
                   <b>Base Amount:</b>{" "}
                   {singleWithdrawal.base_request_amount / 100}
                 </p>
                 <p>
                   <b>Local Amount:</b>{" "}
                   {(
                     singleWithdrawal.local_request_amount / 100
                   ).toLocaleString()}
                 </p>
                 {singleWithdrawal.user_wallets.map(w => {
                   return (
                     <p key={w.user_wallet_id}>
                       <b>
                         {w.wallet_type[0].toUpperCase() +
                           w.wallet_type.substring(1)}{" "}
                         Balance:
                       </b>{" "}
                       {w.local_wallet_value / 100}
                     </p>
                   );
                 })}
                 <p>
                   <b>Request Date:</b>{" "}
                   {new Date(singleWithdrawal.request_date).toString()}
                 </p>
                 <p>
                   <b>Country:</b>{" "}
                   {
                     singleWithdrawal.user_profile.locale_country_profile
                       .country
                   }
                 </p>
                 <p>
                   <b>Withdrawal Status:</b> {singleWithdrawal.request_status}
                 </p>

                 <p>
                   <b>User Name:</b> {singleWithdrawal.user_profile.first_name}{" "}
                   {singleWithdrawal.user_profile.last_name}
                 </p>
                 <p>
                   <b>User Email:</b> {singleWithdrawal.user_profile.email}
                 </p>
                 <p>
                   <b>Phone Number:</b>{" "}
                   {singleWithdrawal.user_profile.phone_num}
                 </p>

                 <p>
                   <b>Account Level:</b>{" "}
                   {singleWithdrawal.user_profile.account_level}
                 </p>
                 <p>
                   <b>Account Status:</b>{" "}
                   {singleWithdrawal.user_profile.account_status}
                 </p>
                 <p>
                   <b>Is Verified:</b>{" "}
                   {singleWithdrawal.user_profile.is_verified.toString()}
                 </p>
                 <p>
                   <b>Bank Name:</b>{" "}
                   {
                     singleWithdrawal.user_withdraw_option?.withdrawal_details
                       .bank_name
                   }
                 </p>
               </div>
             ) : (
               <div>Loading...</div>
             )
           }
           closeModal={() => setShowModal(false)}
           cancelText="Cancel"
           visible={true}
         />
       )}
       {getWithdrawalsError && (
         <Alert
           color="danger"
           fade={true}
           isOpen={!!getWithdrawalsError}
           toggle={() => setGetWithdrawalsError(null)}
         >
           {getWithdrawalsError}
         </Alert>
       )}

       {customModal}

       <Card loading={getWithdrawalsLoading}>
         <TableContainer
           columns={columns}
           data={withdrawals.map(withdrawal => ({
             ...withdrawal,
             email: withdrawal.user_profile.email,
             phone_number: withdrawal.user_profile.phone_num,
             status: withdrawal.request_status,
           }))}
           addButtonText={null}
           setShowDetailsModal={data => {
             fetchWithdrawalDetails(data.original.withdrawal_request_id);
            //  openDepositDetailsModal(singleWithdrawal);
            setShowModal(true)
           }}
           isGlobalFilter={true}
           isAddOptions={false}
           customPageSize={pageSize}
           className="custom-header-css"
           addNewGroup={() => {}}
           pageCount={pageCount}
           setFilters={setFilters}
           setPageIndex={setPageIndex}
           setPageSize={setPageSize}
           controlledPageIndex={pageIndex}
         />
       </Card>
     </div>
   );
};
export default CancelledWithdrawals;
