// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Col,
  Row,
  Label,
  ModalHeader,
  ModalBody,
  Alert,
  UncontrolledTooltip,
  FormFeedback,
  Form as ReactForm,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

import { Card } from "antd";
import CustomModal from "../../../reusables/CustomModal";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "../GroupsTable";
import BingoTicketsPlans from "./BingoTicketPlans";

import api from "../../../api";

const BingoTickets = () => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [error, setError] = useState(null);
  const [customModal, setCustomModal] = useState(null);
  const [getTicketGroupsLoading, setGetTicketGroupsLoading] = useState(false);
  const [allTicketGroups, setAllTicketGroups] = useState([]);
  const [getGroupError, setGetGroupError] = useState(null);
  const [editGroup, setEditGroup] = useState(null);
  const [createGroupError, setCreateGroupError] = useState(null);
  const [createTicketGroupLoading, setCreateTicketGroupLoading] =
    useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(null);
  const [gameTypes, setBingoTypes] = useState([]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ticket_group_name: editGroup?.ticket_group_name || "",
      // game_type_id: editGroup?.game_type_id || "",
      no_of_tickets_per_strip: editGroup?.no_of_tickets_per_strip || "",
      ticket_group_status: editGroup?.ticket_group_status || "",
    },
    validationSchema: Yup.object({
      ticket_group_name: Yup.string().required(
        "Please Enter Ticket Group Name"
      ),
      // game_type_id: Yup.string().required("Please select game type"),
      ticket_group_status: Yup.string().required("Please select group status"),
      no_of_tickets_per_strip: Yup.number().required(
        "Please enter number of tickets per strip"
      ),
    }),
    onSubmit: ({
      ticket_group_name,
      no_of_tickets_per_strip,
      ticket_group_status,
    }) => {
      editGroup
        ? editTicketGroup(editGroup.ticket_group_id, {
            ticket_group_name,
            no_of_tickets_per_strip,
            ticket_group_status,
          })
        : createTicketGroup({ ticket_group_name, no_of_tickets_per_strip });
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Ticket Group Name",
        accessor: "ticket_group_name",
      },
      {
        Header: "Tickets Per Strip",
        accessor: "no_of_tickets_per_strip",
      },
      {
        Header: "Ticket Group Status",
        accessor: "ticket_group_status",
      },
      {
        Header: "Actions",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={e => {
                  e.stopPropagation();
                  const data = cellProps.row.original;
                  console.log("edit", data);
                  setOpenAddGroupModal(true);
                  setEditGroup(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={e => {
                  e.stopPropagation();
                  const data = cellProps.row.original;
                  handleDeleteGroup(data);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
              <Button
                className="btn-success"
                onClick={(e) => {
                   e.stopPropagation()
                  setShowDetailsModal(cellProps.row.original)}}
              >
                Add Plans
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const confirmCancel = () => {
    setCustomModal(
      <CustomModal
        title="Cancel Create Ticket?"
        content={
          <>
            <p>Do you want to stop creating ticket plans?</p>
            <p>Ticket plans might not be saved</p>
          </>
        }
        onOk={() => {
          setCustomModal(null);
          setOpenAddGroupModal(false);
          setEditGroup(null);
        }}
        okText="Okay"
        closeModal={() => setCustomModal(null)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const getAllTickets = () => {
    setGetTicketGroupsLoading(true);
    api.tickets
      .getAllTicketGroups()
      .then(res => {
        setGetTicketGroupsLoading(false);
        if (res.status === 200) {
          setAllTicketGroups(res.data.data);
        }
      })
      .catch(err => {
        setGetTicketGroupsLoading(false);
        setGetGroupError(err.response?.data.message || err.message);
      });
  };

  const getAllBingoTypes = () => {
    api.games
      .getAllBingoTypes()
      .then(res => {
        if (res.status === 200) {
          setBingoTypes(res.data.data);
        }
      })
      .catch(err => {
        setGetBingosError(err.response?.data.message || err.message);
      });
  };

  const createTicketGroup = payload => {
    setCreateTicketGroupLoading(true);
    api.tickets
      .createTicketGroup(payload)
      .then(res => {
        setCreateTicketGroupLoading(false);
        if (res.status === 201) {
          getAllTickets();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Ticket Group Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setEditGroup(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateTicketGroupLoading(false);
        setCreateGroupError(err.response?.data.message || err.message);
      });
  };

  const editTicketGroup = (id, payload) => {
    setCreateTicketGroupLoading(true);
    api.tickets
      .updateTicketGroup(id, payload)
      .then(res => {
        setCreateTicketGroupLoading(false);
        if (res.status === 200) {
          getAllTickets();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Ticket Group Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                setEditGroup(null);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                setEditGroup(null);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateTicketGroupLoading(false);
        setCreateGroupError(err.response?.data.message || err.message);
      });
  };

  const deleteTicketGroup = id => {
    api.tickets
      .deleteTicketGroup(id)
      .then(res => {
        if (res.status === 200) {
          getAllTickets();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Ticket Group Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setGetGroupError(err.response?.data.message || err.message);
      });
  };

  const handleDeleteGroup = data => {
    setCustomModal(
      <CustomModal
        title="Delete Ticket Group?"
        content={<p>Do you want to delete this ticket group?</p>}
        onOk={() => {
          deleteTicketGroup(data.ticket_group_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  useEffect(() => {
    getAllTickets();
    getAllBingoTypes();
  }, []);

  useEffect(() => {
    createGroupError &&
      setTimeout(() => {
        setCreateGroupError(null);
      }, 3000);
    getGroupError &&
      setTimeout(() => {
        setGetGroupError(null);
      }, 3000);
  });

  //meta title
  document.title = "Bingo Tickets";

  return (
    <div className="page-content">
      {getGroupError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getGroupError}
          toggle={() => setGetGroupError(null)}
        >
          {getGroupError}
        </Alert>
      )}
      <div className="container-fluid">
        <Breadcrumbs title="Bingo" breadcrumbItem="Bingo Tickets" />
        <Card loading={getTicketGroupsLoading}>
          <TableContainer
            columns={columns}
            data={allTicketGroups}
            addButtonText="Create Ticket Group"
            isGlobalFilter={true}
            isAddOptions={true}
            setShowDetailsModal={val => setShowDetailsModal(val.original)}
            customPageSize={10}
            className="custom-header-css"
            addNewGroup={() => setOpenAddGroupModal(true)}
          />
        </Card>
      </div>
      {customModal}

      {showDetailsModal && (
        <BingoTicketsPlans
          visible={!!showDetailsModal}
          ticketGroup={showDetailsModal}
          closeModal={() => {
            setEditGroup(null);
            setShowDetailsModal(null);
          }}
        />
      )}

      <Modal isOpen={openAddGroupModal} size="lg" toggle={confirmCancel}>
        <ModalHeader toggle={confirmCancel} tag="h4">
          Create Ticket Plan
        </ModalHeader>
        {createGroupError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!createGroupError}
            toggle={() => setCreateGroupError(null)}
          >
            {createGroupError}
          </Alert>
        )}
        <Card>
          <ModalBody>
            {error && (
              <Alert
                color="danger"
                fade={true}
                isOpen={!!error}
                toggle={() => setError(null)}
              >
                {error}
              </Alert>
            )}
            <ReactForm>
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Row>
                      <Col lg={6}>
                        <Label className="form-label">
                          <b>Group Name</b>
                        </Label>
                        <Input
                          name="ticket_group_name"
                          id="ticket_group_name"
                          type="text"
                          placeholder="Enter ticket group name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.ticket_group_name || ""}
                          invalid={
                            validation.touched.ticket_group_name &&
                            validation.errors.ticket_group_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.ticket_group_name &&
                        validation.errors.ticket_group_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.ticket_group_name}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col lg={6}>
                        <Label className="form-label">
                          <b>Group Status</b>
                        </Label>
                        <Input
                          name="ticket_group_status"
                          id="ticket_group_status"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.ticket_group_status || ""}
                          invalid={
                            validation.touched.ticket_group_status &&
                            validation.errors.ticket_group_status
                              ? true
                              : false
                          }
                        >
                          <option defaultValue value="">
                            Select one...
                          </option>
                          <option value="active">active</option>
                          <option value="disabled">disabled</option>
                        </Input>
                        {validation.touched.ticket_group_status &&
                        validation.errors.ticket_group_status ? (
                          <FormFeedback type="invalid">
                            {validation.errors.ticket_group_status}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      {/* <Col lg={6}>
                        <Label className="form-label">
                          <b>Bingo Type</b>
                        </Label>
                        <Input
                          name="game_type_id"
                          id="game_type_id"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.game_type_id || ""}
                          invalid={
                            validation.touched.game_type_id &&
                            validation.errors.game_type_id
                              ? true
                              : false
                          }
                        >
                          <option value="">Select one...</option>
                          {gameTypes.map(type => (
                            <option
                              key={type.game_type_id}
                              value={type.game_type_id}
                            >
                              {type.game_type}
                            </option>
                          ))}
                        </Input>
                        {validation.touched.game_type_id &&
                        validation.errors.game_type_id ? (
                          <FormFeedback type="invalid">
                            {validation.errors.game_type_id}
                          </FormFeedback>
                        ) : null}
                      </Col> */}
                    </Row>
                  </div>
                  <div className="mb-3">
                    <Row>
                      <Col lg={6}>
                        <Label className="form-label">
                          <b>Tickets per Strip</b>
                        </Label>
                        <Input
                          name="no_of_tickets_per_strip"
                          id="no_of_tickets_per_strip"
                          type="number"
                          placeholder="Enter number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={
                            validation.values.no_of_tickets_per_strip || ""
                          }
                          invalid={
                            validation.touched.no_of_tickets_per_strip &&
                            validation.errors.no_of_tickets_per_strip
                              ? true
                              : false
                          }
                        />
                        {validation.touched.no_of_tickets_per_strip &&
                        validation.errors.no_of_tickets_per_strip ? (
                          <FormFeedback type="invalid">
                            {validation.errors.no_of_tickets_per_strip}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </ReactForm>
          </ModalBody>
        </Card>

        <div className="modal-footer">
          <button
            type="button"
            onClick={confirmCancel}
            className="btn btn-danger"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            onClick={validation.handleSubmit}
            loading={createTicketGroupLoading ? "true" : "false"}
            type="button"
            className="btn btn-success "
          >
            {editGroup ? "Edit" : "Add"} Ticket Group
          </button>
        </div>
      </Modal>
    </div>
  );
};
BingoTickets.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default BingoTickets;
