export default class Admin {
  constructor(client) {
    this.client = client;
  }

  createAdmin(payload) {
    return this.client.post("/admin/admin", payload);
  }

  updateAdmin(payload) {
    const admin_id = payload.admin_id;
    delete payload.admin_id;
    return this.client.patch(`/admin/admin/${admin_id}`, payload);
  }

  deleteAdmin(admin_id) {
    return this.client.delete(`/admin/admin/${admin_id}`,);
  }

  loginAdmin(payload) {
    return this.client.post("/admin/login", payload);
  }

  getAllAdmins() {
    return this.client.get("/admin/all-admin");
  }
  getAllRoles() {
    return this.client.get("/admin/all-role");
  }

  createRole(payload) {
    return this.client.post("/admin/role", payload);
  }

  createPermission(payload) {
    return this.client.post("/admin/permission", payload);
  }

  prepareEnv(payload) {
    return this.client.post("/admin/prepare-env", payload);
  }
}
