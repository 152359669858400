import React, { useEffect, useMemo, useState } from "react";
import api from "../../api";
import { Alert } from "reactstrap";
import { Card } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../Withdrawals/GroupsTable";
import { CSVLink } from "react-csv";
import moment from "moment";

const SumOfWins = () => {
  const [bingoHistory, setBingoHistory] = useState([]);
  const [slots, setSlots] = useState([]);
  const [getHistoryLoading, setGetHistoryLoading] = useState(false);
  const [getHistoryError, setGetHistoryError] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");

  const columns = [
    {
      Header: "Date",
      accessor: "created_at",
    },
    {
      Header: "Sum of Bets (Bingo)",
      accessor: "bet_sum_bingo",
    },
    {
      Header: "Sum of Wins (Bingo)",
      accessor: "win_sum_bingo",
    },
    {
      Header: "Sum of GGR (Bingo)",
      accessor: "ggr_sum_bingo",
    },
    {
      Header: "Sum of Bets (Slots)",
      accessor: "bet_sum_slots",
    },

    {
      Header: "Sum of Wins (Slots)",
      accessor: "win_sum_slots",
    },

    {
      Header: "Sum of GGR (Slots)",
      accessor: "ggr_sum_slots",
    },
  ];

  const getAllReports = () => {
    setGetHistoryLoading(true);
    api.commsAxiosInstance
      .get("/report/all-games?start_date=2022-09-01&end_date=2022-09-20&page=1&limit=40")
      .then(res => {
        setGetHistoryLoading(false);
        if (res.status === 200) {
          const data = [];
          for (let dayEvents of res.data.data) {
            data.push(...dayEvents.response);
          }

          const dateObj = {};
          for (let event of data) {
            const datePlayed = moment(event.game_event?.game_start).format(
              "DD MMMM"
            );
            const totalWin = event.jackpot
              .map(jp => Number(jp.base_total_win) / 100)
              .reduce((a, b) => a + b, 0);
            const totalSales = event.sales
              .map(sale => Number(sale.base_ticket_total) / 100)
              .reduce((a, b) => a + b, 0);
            const ggr = totalSales - totalWin;

            dateObj[datePlayed] = dateObj[datePlayed]
              ? [...dateObj[datePlayed], { totalWin, totalSales, ggr }]
              : [{ totalWin, totalSales, ggr }];
          }
          for (let date in dateObj) {
            const sumOfWages = dateObj[date]
              .map(game => game.totalSales)
              .reduce((a, b) => a + b, 0);
            const sumOfWins = dateObj[date]
              .map(game => game.totalWin)
              .reduce((a, b) => a + b, 0);
            const totalGGr = dateObj[date]
              .map(game => game.ggr)
              .reduce((a, b) => a + b, 0);
            dateObj[date] = {
              created_at: date,
              bet_sum_bingo: sumOfWages.toFixed(1),
              win_sum_bingo: sumOfWins.toFixed(1),
              ggr_sum_bingo: totalGGr.toFixed(1),
            };
          }
          setBingoHistory(dateObj);
        }
      })
      .catch(err => {
        setGetHistoryLoading(false);
        setGetHistoryError(err.response?.data.message || err.message);
      });
  };

  const getAllSlots = (limit, page, search) => {
    setGetHistoryLoading(true);
    api.commerce
      .getAllSlots(limit, page, search)
      .then(res => {
        setGetHistoryLoading(false);
        if (res.status === 200) {
          const data = res.data.data?.transactions;
          let slotsObj = {};
          for (let event of data) {
            const datePlayed = moment(Number(event.created)).format("DD MMMM");

            const rate =
              (Number(event.local_amount) * 100) / Number(event.base_amount);

            slotsObj[datePlayed] = slotsObj[datePlayed]
              ? {
                  created_at: datePlayed,
                  bet_sum_slots:
                    slotsObj[datePlayed].bet_sum_slots +
                    Number(event.base_amount) / 100,
                  win_sum_slots:
                    slotsObj[datePlayed].win_sum_slots +
                    Number(event.wins) / rate,
                }
              : {
                  created_at: datePlayed,
                  bet_sum_slots: Number(event.base_amount) / 100,
                  win_sum_slots: Number(event.wins) / rate,
                };
          }
          setSlots(slotsObj);
          // setPageCount(res.data.data.totalPages);
        }
      })
      .catch(err => {
        setGetHistoryLoading(false);
        setGetHistoryError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    getHistoryError &&
      setTimeout(() => {
        setGetHistoryError(null);
      }, 3000);
  });

  useEffect(() => {
    getAllReports();
    getAllSlots(pageSize, pageIndex + 1, "");
  }, [pageIndex, pageSize]);

  useEffect(() => {
    getAllSlots(pageSize, 1, filters);
  }, [filters]);

  const acceptedHeaders = [
    {
      label: "Date",
      key: "created_at",
    },
    {
      label: "Sum of Bets (Bingo)",
      key: "bet_sum_bingo",
    },
    {
      label: "Sum of Wins (Bingo)",
      key: "win_sum_bingo",
    },
    {
      label: "Sum of GGR (Bingo)",
      key: "ggr_sum_bingo",
    },
    {
      label: "Sum of Bets (Slots)",
      key: "bet_sum_slots",
    },

    {
      label: "Sum of Wins (Slots)",
      key: "win_sum_slots",
    },
    {
      label: "Sum of GGR (Slots)",
      key: "ggr_sum_slots",
    },
  ];

  const getAllData = (bingo, slots) => {
    const displayData = [];

    Object.keys(bingo).forEach(day => {
      const game = {
        ...bingo[day],
        win_sum_slots: slots[day]?.win_sum_slots?.toLocaleString() || 0,
        bet_sum_slots: slots[day]?.bet_sum_slots?.toLocaleString() || 0,
        ggr_sum_slots: slots[day]
          ? (
              slots[day]?.bet_sum_slots - slots[day]?.win_sum_slots
            ).toLocaleString()
          : 0,
      };
      displayData.push(game);
    });
    Object.keys(slots).forEach(day => {
      if (!bingo[day]) {
        const game = {
          created_at: day,
          bet_sum_bingo: 0,
          win_sum_bingo: 0,
          ggr_sum_bingo: 0,
          win_sum_slots: slots[day]?.win_sum_slots?.toLocaleString() || 0,
          bet_sum_slots: slots[day]?.bet_sum_slots?.toLocaleString() || 0,
          ggr_sum_slots: slots[day]
            ? (
                slots[day]?.bet_sum_slots - slots[day]?.win_sum_slots
              ).toLocaleString()
            : 0,
        };
        displayData.push(game);
      }
    });
    return displayData.sort(
      (a, b) => moment(a.created_at) - moment(b.created_at)
    );
  };

  const exportButton = (
    <CSVLink
      id="csvDownloadBtn"
      key="all-users-download"
      data={getAllData(bingoHistory, slots)}
      headers={acceptedHeaders}
      filename={`all-games-reports.csv`}
      target="_blank"
    >
      <button className="btn btn-primary">Export Report</button>
    </CSVLink>
  );

  //meta title
  document.title = "Bingo Reports";

  return (
    <div className="page-content">
      {getHistoryError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getHistoryError}
          toggle={() => setGetHistoryError(null)}
        >
          {getHistoryError}
        </Alert>
      )}
      <div className="container-fluid">
        <Breadcrumbs title="Reports" breadcrumbItem="All Games Summary" />
        <Card loading={getHistoryLoading}>
          <TableContainer
            columns={columns}
            data={getAllData(bingoHistory, slots)}
            addButtonText={null}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={pageSize}
            setShowDetailsModal={() => {}}
            className="custom-header-css"
            addNewGroup={() => {}}
            exportButton={exportButton}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            controlledPageIndex={pageIndex}
            pageCount={pageCount}
          />
        </Card>
      </div>
    </div>
  );
};
export default SumOfWins;
