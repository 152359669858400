import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Col,
  Row,
  Label,
  Form,
  Input,
  FormFeedback,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import api from "../../api";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../Withdrawals/GroupsTable";
import { Card } from "antd";
import CustomModal from "../../reusables/CustomModal";
import moment from "moment";

const LocaleProfiles = () => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [localeProfiles, setLocaleProfiles] = useState([]);
  const [getLocaleProfileLoading, setGetLocaleProfileLoading] = useState(false);
  const [getLocaleProfileError, setGetLocaleProfileError] = useState(null);
  const [createLocaleProfileLoading, setCreateLocaleProfileLoading] =
    useState(false);
  const [createLocaleProfileError, setCreateLocaleProfileError] =
    useState(null);
  const [editLocaleProfile, setEditLocaleProfile] = useState(null);
  const [deleteLocaleProfileLoading, setDeleteLocaleProfileLoading] =
    useState(false);
  const [customModal, setCustomModal] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "Currency Name",
        accessor: "currency_name",
      },
      {
        Header: "Business Name",
        accessor: "business_name",
      },
      {
        Header: "Business Email",
        accessor: "business_email",
      },
      {
        Header: "Base Currency",
        accessor: "base_currency",
      },
      {
        Header: "Conversion Rate",
        accessor: "base_currency_conv",
      },
      {
        Header: "Actions",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  setOpenAddGroupModal(true);
                  setEditLocaleProfile(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleDeleteLocaleProfile(data);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      business_name: editLocaleProfile?.business_name || "",
      business_email: editLocaleProfile?.business_email || "",
      business_phone: editLocaleProfile?.business_phone || "",
      business_sms_handle: editLocaleProfile?.business_sms_handle || "",
      base_currency_conv: editLocaleProfile?.base_currency_conv || "",
      base_currency: editLocaleProfile?.base_currency || "",
      country: editLocaleProfile?.country || "",
      tld_code: editLocaleProfile?.tld_code || "",
      ftd_bonus: editLocaleProfile ? editLocaleProfile.ftd_bonus : 0,
      ftd_expiration_in_day: editLocaleProfile?.ftd_expiration_in_day
        ? editLocaleProfile?.ftd_expiration_in_day
        : 0,
      ftd_game_type: editLocaleProfile?.ftd_game_type || "",
      ftd_toggle: editLocaleProfile?.ftd_toggle || false,
      currency_suffix: editLocaleProfile?.currency_suffix || "",
      currency_prefix: editLocaleProfile?.currency_prefix || "",
      currency_code: editLocaleProfile?.currency_code || "",
      currency_name: editLocaleProfile?.currency_name || "",
      language_code: editLocaleProfile?.language_code || "",
      utc_off_set: editLocaleProfile?.utc_off_set || "",
      valid_from_date: editLocaleProfile?.valid_from_date || "",
      valid_to_date: editLocaleProfile?.valid_to_date || "",
      min_deposit: editLocaleProfile?.min_deposit / 100 || "",
      min_withdraw: editLocaleProfile?.min_withdraw / 100 || "",
      max_deposit: editLocaleProfile?.max_deposit / 100 || "",
      max_withdraw: editLocaleProfile?.max_withdraw / 100 || "",
      tax: editLocaleProfile?.tax || "",
    },
    validationSchema: Yup.object({
      business_name: Yup.string().required("Please enter business name"),
      business_email: Yup.string().required("Please enter business email"),
      business_phone: Yup.string().required("Please enter business phone"),
      business_sms_handle: Yup.string(),
      country: Yup.string().required("Please enter country"),
      currency_code: Yup.string().required("Please enter currency code"),
      tld_code: Yup.string(),
      language_code: Yup.string(),
      currency_name: Yup.string().required("Please enter currency name"),
      currency_prefix: Yup.string(),
      utc_off_set: Yup.string(),
      currency_suffix: Yup.string(),
      base_currency: Yup.string().required("Please enter base currency"),
      valid_from_date: Yup.string().required(
        "Please enter date currency conversion is valid from"
      ),
      valid_to_date: Yup.string().required(
        "Please enter date currency conversion is valid to"
      ),
      base_currency_conv: Yup.number().required(
        "Please enter currency conversion rate"
      ),
      min_deposit: Yup.number().required(
        "Please enter minimum deposit  amount"
      ),
      min_withdraw: Yup.number().required(
        "Please enter minimum withdrawal amount"
      ),
      max_deposit: Yup.number()
        .required("Please enter maximum deposit amount")
        .moreThan(Yup.ref("min_deposit")),
      max_withdraw: Yup.number()
        .required("Please enter maximum withdrawal amount")
        .moreThan(Yup.ref("min_withdraw")),
      tax: Yup.number().required("Please enter country tax"),
    }),
    onSubmit: values => {
      if (!values.ftd_bonus) {
        values.ftd_bonus = 0;
      }

      if (!values.ftd_expiration_in_day) {
        values.ftd_expiration_in_day = 0;
      }
      values.valid_from_date = moment(values.valid_from_date).format(
        "DD-MM-YYYY"
      );
      values.valid_to_date = moment(values.valid_to_date).format("DD-MM-YYYY");
      editLocaleProfile
        ? handleEditLocaleProfile(editLocaleProfile.country_profile_id, values)
        : createLocaleProfile(values);
    },
  });

  const getAllLocaleProfiles = () => {
    setGetLocaleProfileLoading(true);
    api.commerce
      .getAllLocaleProfiles()
      .then(res => {
        setGetLocaleProfileLoading(false);
        if (res.status === 200) {
          setLocaleProfiles(res.data.data);
        }
      })
      .catch(err => {
        setGetLocaleProfileLoading(false);
        setGetLocaleProfileError(err.response?.data.message || err.message);
      });
  };

  const createLocaleProfile = payload => {
    setCreateLocaleProfileLoading(true);
    api.commerce
      .createLocaleProfile(payload)
      .then(res => {
        setCreateLocaleProfileLoading(false);
        if (res.status === 201) {
          getAllLocaleProfiles();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo Locale Profile Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateLocaleProfileLoading(false);
        setCreateLocaleProfileError(err.response?.data.message || err.message);
      });
  };

  const handleEditLocaleProfile = (id, payload) => {
    setCreateLocaleProfileLoading(true);
    api.commerce
      .updateLocaleProfile(id, payload)
      .then(res => {
        setCreateLocaleProfileLoading(false);
        if (res.status === 200) {
          getAllLocaleProfiles();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Locale Profile Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                setEditLocaleProfile(null);
                validation.handleReset();
              }}
              closeModal={() => {
                setEditLocaleProfile(null);
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateLocaleProfileLoading(false);
        setCreateLocaleProfileError(err.response?.data.message || err.message);
      });
  };

  const deleteLocaleProfile = id => {
    setDeleteLocaleProfileLoading(true);
    api.games
      .deleteCustomBingoRTP(id)
      .then(res => {
        setDeleteLocaleProfileLoading(false);
        if (res.status === 200) {
          getAllLocaleProfiles();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Locale Profile Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setDeleteLocaleProfileLoading(false);
        setGetLocaleProfileError(err.response?.data.message || err.message);
      });
  };

  const handleDeleteLocaleProfile = data => {
    setCustomModal(
      <CustomModal
        title="Delete Bingo LocaleProfile?"
        loading={deleteLocaleProfileLoading}
        content={<p>Do you want to delete this Locale Profile?</p>}
        onOk={() => {
          deleteLocaleProfile(data.country_profile_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  useEffect(() => {
    getLocaleProfileError &&
      setTimeout(() => {
        setGetLocaleProfileError(null);
      }, 3000);
    createLocaleProfileError &&
      setTimeout(() => {
        setCreateLocaleProfileError(null);
      }, 3000);
  });

  useEffect(() => {
    getAllLocaleProfiles(pageSize, pageIndex + 1, "");
  }, [pageIndex, pageSize]);

  useEffect(() => {
    getAllLocaleProfiles(pageSize, 1, filters);
  }, [filters]);

  //meta title
  document.title = "Locale Profiles";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Admin" breadcrumbItem="Locale Profiles" />
        {getLocaleProfileError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!getLocaleProfileError}
            toggle={() => setGetLocaleProfileError(null)}
          >
            {getLocaleProfileError}
          </Alert>
        )}
        <Card loading={getLocaleProfileLoading}>
          <TableContainer
            columns={columns}
            data={localeProfiles}
            setShowDetailsModal={() => {}}
            addButtonText="Create Custom Locale Profile"
            isGlobalFilter={true}
            isAddOptions={true}
            customPageSize={10}
            className="custom-header-css"
            addNewGroup={() => setOpenAddGroupModal(true)}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            controlledPageIndex={pageIndex}
            pageCount={pageCount}
          />
        </Card>
      </div>

      {customModal}

      <Modal
        size="lg"
        isOpen={openAddGroupModal}
        toggle={() => {
          setEditLocaleProfile(null);
          setOpenAddGroupModal(false);
        }}
      >
        {createLocaleProfileError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!createLocaleProfileError}
            toggle={() => setCreateLocaleProfileError(null)}
          >
            {createLocaleProfileError}
          </Alert>
        )}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Locale Profile Settings
          </h5>
          <button
            type="button"
            onClick={() => {
              setEditLocaleProfile(null);
              setOpenAddGroupModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="business_name">
                    <b>Business Name</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="business_name"
                    name="business_name"
                    placeholder="Enter Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.business_name || ""}
                    invalid={
                      validation.touched.business_name &&
                      validation.errors.business_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.business_name &&
                  validation.errors.business_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.business_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="business_email">
                    <b>Business Email</b>
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="business_email"
                    name="business_email"
                    placeholder="Enter Email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.business_email || ""}
                    invalid={
                      validation.touched.business_email &&
                      validation.errors.business_email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.business_email &&
                  validation.errors.business_email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.business_email}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="business_phone">
                    <b>Business Phone</b>
                  </Label>
                  <Input
                    type="tel"
                    className="form-control"
                    id="business_phone"
                    name="business_phone"
                    placeholder="Enter Phone Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.business_phone || ""}
                    invalid={
                      validation.touched.business_phone &&
                      validation.errors.business_phone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.business_phone &&
                  validation.errors.business_phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.business_phone}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="business_sms_handle">
                    <b>Business SMS Handle</b>
                  </Label>
                  <Input
                    type="tel"
                    className="form-control"
                    id="business_sms_handle"
                    name="business_sms_handle"
                    placeholder="Enter sms handle"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.business_sms_handle || ""}
                    invalid={
                      validation.touched.business_sms_handle &&
                      validation.errors.business_sms_handle
                        ? true
                        : false
                    }
                  />
                  {validation.touched.business_sms_handle &&
                  validation.errors.business_sms_handle ? (
                    <FormFeedback type="invalid">
                      {validation.errors.business_sms_handle}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="tax">
                    <b>Country Name</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="country"
                    name="country"
                    placeholder="Enter country name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.country || ""}
                    invalid={
                      validation.touched.tax && validation.errors.country
                        ? true
                        : false
                    }
                  />
                  {validation.touched.country && validation.errors.country ? (
                    <FormFeedback type="invalid">
                      {validation.errors.country}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="tax">
                    <b>Country Tax</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="tax"
                    name="tax"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tax || ""}
                    invalid={
                      validation.touched.tax && validation.errors.tax
                        ? true
                        : false
                    }
                  />
                  {validation.touched.tax && validation.errors.tax ? (
                    <FormFeedback type="invalid">
                      {validation.errors.tax}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="base_currency">
                    <b>Base Currency</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="base_currency"
                    name="base_currency"
                    placeholder="Enter currency"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.base_currency || ""}
                    invalid={
                      validation.touched.base_currency &&
                      validation.errors.base_currency
                        ? true
                        : false
                    }
                  />
                  {validation.touched.base_currency &&
                  validation.errors.base_currency ? (
                    <FormFeedback type="invalid">
                      {validation.errors.base_currency}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="base_currency_conv">
                    <b>Conversion Rate</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="base_currency_conv"
                    name="base_currency_conv"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.base_currency_conv || ""}
                    invalid={
                      validation.touched.base_currency_conv &&
                      validation.errors.base_currency_conv
                        ? true
                        : false
                    }
                  />
                  {validation.touched.base_currency_conv &&
                  validation.errors.base_currency_conv ? (
                    <FormFeedback type="invalid">
                      {validation.errors.base_currency_conv}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="currency_name">
                    <b>Currency Name</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="currency_name"
                    name="currency_name"
                    placeholder="Enter currency name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.currency_name || ""}
                    invalid={
                      validation.touched.currency_name &&
                      validation.errors.currency_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.currency_name &&
                  validation.errors.currency_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.currency_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="currency_code">
                    <b>Currency Code</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="currency_code"
                    name="currency_code"
                    placeholder="Enter code"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.currency_code || ""}
                    invalid={
                      validation.touched.currency_code &&
                      validation.errors.currency_code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.currency_code &&
                  validation.errors.currency_code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.currency_code}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="currency_prefix">
                    <b>Currency Prefix</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="currency_prefix"
                    name="currency_prefix"
                    placeholder="Enter currency prefix"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.currency_prefix || ""}
                    invalid={
                      validation.touched.currency_prefix &&
                      validation.errors.currency_prefix
                        ? true
                        : false
                    }
                  />
                  {validation.touched.currency_prefix &&
                  validation.errors.currency_prefix ? (
                    <FormFeedback type="invalid">
                      {validation.errors.currency_prefix}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="currency_suffix">
                    <b>Currency Suffix</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="currency_suffix"
                    name="currency_suffix"
                    placeholder="Enter suffix"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.currency_suffix || ""}
                    invalid={
                      validation.touched.currency_suffix &&
                      validation.errors.currency_suffix
                        ? true
                        : false
                    }
                  />
                  {validation.touched.currency_suffix &&
                  validation.errors.currency_suffix ? (
                    <FormFeedback type="invalid">
                      {validation.errors.currency_suffix}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="language_code">
                    <b>Language Code</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="language_code"
                    name="language_code"
                    placeholder="Enter language code"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.language_code || ""}
                    invalid={
                      validation.touched.language_code &&
                      validation.errors.language_code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.language_code &&
                  validation.errors.language_code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.language_code}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="tld_code">
                    <b>TLD Code</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="tld_code"
                    name="tld_code"
                    placeholder="Enter code"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tld_code || ""}
                    invalid={
                      validation.touched.tld_code && validation.errors.tld_code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.tld_code && validation.errors.tld_code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.tld_code}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="language_code">
                    <b>FTD Bonus</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="ftd_bonus"
                    name="ftd_bonus"
                    min={0}
                    placeholder="Enter Bonus"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.ftd_bonus || ""}
                    invalid={
                      validation.touched.ftd_bonus &&
                      validation.errors.ftd_bonus
                        ? true
                        : false
                    }
                  />
                  {validation.touched.ftd_bonus &&
                  validation.errors.ftd_bonus ? (
                    <FormFeedback type="invalid">
                      {validation.errors.ftd_bonus}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="tld_code">
                    <b>FTD Expiration (Days)</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="ftd_expiration_in_day"
                    name="ftd_expiration_in_day"
                    placeholder="Enter Expiration"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.ftd_expiration_in_day || ""}
                    invalid={
                      validation.touched.ftd_expiration_in_day &&
                      validation.errors.ftd_expiration_in_day
                        ? true
                        : false
                    }
                  />
                  {validation.touched.ftd_expiration_in_day &&
                  validation.errors.ftd_expiration_in_day ? (
                    <FormFeedback type="invalid">
                      {validation.errors.ftd_expiration_in_day}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="tld_code">
                    <b>FTD Game Type</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="ftd_game_type"
                    name="ftd_game_type"
                    placeholder="Enter Expiration"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.ftd_game_type || ""}
                    invalid={
                      validation.touched.ftd_game_type &&
                      validation.errors.ftd_game_type
                        ? true
                        : false
                    }
                  />
                  {validation.touched.ftd_game_type &&
                  validation.errors.ftd_game_type ? (
                    <FormFeedback type="invalid">
                      {validation.errors.ftd_game_type}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="tld_code">
                    <b>FTD Toggle</b>
                  </Label>
                  <Input
                    type="checkbox"
                    className="form-control"
                    id="ftd_toggle"
                    name="ftd_toggle"
                    placeholder="Enter Expiration"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    checked={validation.values.ftd_toggle}
                    invalid={
                      validation.touched.ftd_toggle &&
                      validation.errors.ftd_toggle
                        ? true
                        : false
                    }
                  />
                  {validation.touched.ftd_toggle &&
                  validation.errors.ftd_ftd_toggle ? (
                    <FormFeedback type="invalid">
                      {validation.errors.ftd_toggle}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="valid_from_date">
                    <b>Valid From</b>
                  </Label>
                  <Input
                    type="date"
                    className="form-control"
                    id="valid_from_date"
                    name="valid_from_date"
                    placeholder="Enter date"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.valid_from_date || ""}
                    invalid={
                      validation.touched.valid_from_date &&
                      validation.errors.valid_from_date
                        ? true
                        : false
                    }
                  />
                  {validation.touched.valid_from_date &&
                  validation.errors.valid_from_date ? (
                    <FormFeedback type="invalid">
                      {validation.errors.valid_from_date}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="valid_to_date">
                    <b>Valid Till</b>
                  </Label>
                  <Input
                    type="date"
                    className="form-control"
                    id="valid_to_date"
                    name="valid_to_date"
                    placeholder="Enter date"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.valid_to_date || ""}
                    invalid={
                      validation.touched.valid_to_date &&
                      validation.errors.valid_to_date
                        ? true
                        : false
                    }
                  />
                  {validation.touched.valid_to_date &&
                  validation.errors.valid_to_date ? (
                    <FormFeedback type="invalid">
                      {validation.errors.valid_to_date}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="min_deposit">
                    <b>Min Deposit</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="min_deposit"
                    name="min_deposit"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.min_deposit || ""}
                    invalid={
                      validation.touched.min_deposit &&
                      validation.errors.min_deposit
                        ? true
                        : false
                    }
                  />
                  {validation.touched.min_deposit &&
                  validation.errors.min_deposit ? (
                    <FormFeedback type="invalid">
                      {validation.errors.min_deposit}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="max_deposit">
                    <b>Max Deposit</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="max_deposit"
                    name="max_deposit"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.max_deposit || ""}
                    invalid={
                      validation.touched.max_deposit &&
                      validation.errors.max_deposit
                        ? true
                        : false
                    }
                  />
                  {validation.touched.max_deposit &&
                  validation.errors.max_deposit ? (
                    <FormFeedback type="invalid">
                      {validation.errors.max_deposit}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="min_withdraw">
                    <b>Min Withdraw</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="min_withdraw"
                    name="min_withdraw"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.min_withdraw || ""}
                    invalid={
                      validation.touched.min_withdraw &&
                      validation.errors.min_withdraw
                        ? true
                        : false
                    }
                  />
                  {validation.touched.min_withdraw &&
                  validation.errors.min_withdraw ? (
                    <FormFeedback type="invalid">
                      {validation.errors.min_withdraw}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="max_withdraw">
                    <b>Max Withdrawal</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="max_withdraw"
                    name="max_withdraw"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.max_withdraw || ""}
                    invalid={
                      validation.touched.max_withdraw &&
                      validation.errors.max_withdraw
                        ? true
                        : false
                    }
                  />
                  {validation.touched.max_withdraw &&
                  validation.errors.max_withdraw ? (
                    <FormFeedback type="invalid">
                      {validation.errors.max_withdraw}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="tax">
                    <b>Country Tax</b>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="tax"
                    name="tax"
                    placeholder="Enter number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tax || ""}
                    invalid={
                      validation.touched.tax && validation.errors.tax
                        ? true
                        : false
                    }
                  />
                  {validation.touched.tax && validation.errors.tax ? (
                    <FormFeedback type="invalid">
                      {validation.errors.tax}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="utc_off_set">
                    <b>UTC Offset</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="utc_off_set"
                    name="utc_off_set"
                    placeholder="Enter utc offset"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.utc_off_set || ""}
                    invalid={
                      validation.touched.utc_off_set &&
                      validation.errors.utc_off_set
                        ? true
                        : false
                    }
                  />
                  {validation.touched.utc_off_set &&
                  validation.errors.utc_off_set ? (
                    <FormFeedback type="invalid">
                      {validation.errors.utc_off_set}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setEditLocaleProfile(null);
              setOpenAddGroupModal(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            loading={createLocaleProfileLoading ? "true" : "false"}
            onClick={validation.handleSubmit}
          >
            Save changes
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default LocaleProfiles;
