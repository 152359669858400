import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
//Admins
import AdminList from "../pages/Admins/AdminList/admin-list";
import Roles from "../pages/Admins/Roles";

//Bingos
import BingoRooms from "../pages/Bingos/BingoRooms";
import CreateBingos from "../pages/Bingos/CreateBingos";
import BingoTypes from "../pages/Bingos/partials/BingoTypes";
import BingoTickets from "../pages/Bingos/partials/BingoTickets";
import LiveBingos from "../pages/Bingos/partials/LiveBingos";
import BingoCalendar from "../pages/Bingos/partials/BingoCalendar";
import JackpotPatterns from "../pages/Bingos/partials/JackpotPatterns";
import JackpotConfigs from "../pages/Bingos/partials/JackpotConfigs";
import RTP from "../pages/Bingos/partials/RTP";
import CustomRTP from "../pages/Bingos/partials/CustomBingoRTP";
import LiveCalendar from "../pages/Calendar/Calendar";
import WinningFactor from "../pages/Bingos/partials/WinningFactor";

//Withdrawals
import WalletWithdrawals from "../pages/Withdrawals/WalletWithdrawals";
import WithdrawalRequests from "../pages/Withdrawals/WithdrawalRequests";
import Deposits from "../pages/Deposits/AllDeposits";
import AllUsers from "../pages/Users/AllUsers";
import AllWallets from "../pages/Users/Wallets";
import LocaleProfiles from "../pages/Locale/LocaleProfiles";
import SlotsHistory from "../pages/Slots/SlotHistory";
import SlotsManagement from "../pages/Slots/SlotManagement";
import SumOfWins from "../pages/Reports/SumOfWins";
import DailyJackpots from "../pages/Reports/DailyJackpots";
import AllGamesSummary from "../pages/Reports/AllGamesSummary";
import UserReports from "../pages/Reports/UserReports";

import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  // //admin
  { path: "/admins", component: AdminList },
  { path: "/admin/roles", component: Roles },

  // //games
  { path: "/game-rooms", component: BingoRooms },
  { path: "/create-game", component: CreateBingos },
  { path: "/game-types", component: BingoTypes },
  { path: "/game-tickets", component: BingoTickets },
  { path: "/live-games", component: LiveBingos },
  { path: "/game-calendar", component: BingoCalendar },
  { path: "/jackpot-configs", component: JackpotConfigs },
  { path: "/jackpot-patterns", component: JackpotPatterns },
  { path: "/game-rtp", component: RTP },
  { path: "/custom-game-rtp", component: CustomRTP },
  { path: "/live-calendar", component: LiveCalendar },
  { path: "/bingo-reports", component: DailyJackpots },
  { path: "/sum-of-wins", component: SumOfWins },
  { path: "/all-games-summary", component: AllGamesSummary },
  { path: "/user-reports", component: UserReports },
  { path: "/winning-factor", component: WinningFactor },

  // Withdrawals
  { path: "/wallet-withdrawals", component: WalletWithdrawals },
  { path: "/withdrawal-requests", component: WithdrawalRequests },

  { path: "/deposits", component: Deposits },
  { path: "/users", component: AllUsers },
  { path: "/wallets", component: AllWallets },
  { path: "/locale-profiles", component: LocaleProfiles },
  { path: "/slots-history", component: SlotsHistory },
  { path: "/slots-management", component: SlotsManagement },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
];

export { authProtectedRoutes, publicRoutes };
