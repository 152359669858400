import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  CardBody,
  Col,
  Container,
  Row,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import { Card } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteAdmin,
  getAllAdmins,
  getAllRoles,
  updateAdminDetails,
} from "../../../redux/actions/admin";
import {
  getAdminSelector,
  clearState as clearGetAdminState,
} from "../../../redux/reducers/admin/getAllAdmins";
import {
  getRolesSelector,
  clearState as clearGetRoleState,
} from "../../../redux/reducers/admin/getAllRoles";
import { registerNewAdmin } from "../../../redux/actions/admin";
import {
  registerAdminSelector,
  clearState as clearAdminState,
} from "../../../redux/reducers/admin/registerAdmin";
import {
  updateAdminSelector,
  clearState as clearUpdateAdminState,
} from "../../../redux/reducers/admin/updateAdmin";

import CustomModal from "../../../reusables/CustomModal";

import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import EditAdminModal from "./editAdminModal";
import AddAdminModal from "./addAmin";
import { deleteAdminSlice } from "../../../redux/reducers/admin/deleteAdmin";

const AdminList = () => {
  //meta title
  document.title = "Admins";
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [editAdmin, setEditAdmin] = useState(null);
  const [customModal, setCustomModal] = useState(null);

  const {
    allAdmins,
    loading: getAdminsLoading,
    errors: getAdminError,
  } = useSelector(getAdminSelector);
  const { allRoles, errors: getRolesError } = useSelector(getRolesSelector);

  const {
    loading: addAdminLoading,
    errors: addAdminError,
    success: addAdminSuccess,
  } = useSelector(registerAdminSelector);

  const {
    loading: updateAdminLoading,
    errors: updateAdminError,
    success: updateAdminSuccess,
  } = useSelector(updateAdminSelector);

  //console.log(useSelector(updateAdminSelector))
  console.log(useSelector(registerAdminSelector));
  useEffect(() => {
    dispatch(getAllAdmins());
    dispatch(getAllRoles());
  }, []);

  useEffect(() => {
    getAdminError &&
      setTimeout(() => {
        dispatch(clearGetAdminState());
      }, 3000);
    getRolesError &&
      setTimeout(() => {
        dispatch(clearGetRoleState());
      }, 3000);
    addAdminError &&
      setTimeout(() => {
        dispatch(clearAdminState());
      }, 3000);
  }, [getAdminError, getRolesError, addAdminError]);
  const roleObj = {};
  for (let role of allRoles) {
    roleObj[role.role_id] = role;
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      fname: editAdmin?.first_name || "",
      lname: editAdmin?.last_name || "",
      email: editAdmin?.email || "",
      phone: editAdmin?.phone_num || "",
      role_id: editAdmin?.role_id || "",
      dob: editAdmin?.date_of_birth || "",
      password: "",
      confirm_password: "",
    },
    validationSchema: editAdmin
      ? Yup.object({
          email: Yup.string().required("Please enter admin email"),
          role_id: Yup.string().required("Please select role"),
          phone: Yup.number().required("Please enter phone number"),
        })
      : Yup.object({
          email: Yup.string().required("Please enter admin email"),
          role_id: Yup.string().required("Please select role"),
          password: Yup.string().required("Please enter admin password"),
          phone: Yup.number().required("Please enter phone number"),
          confirm_password: Yup.string()
            .required("Please confirm password")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
        }),
    onSubmit: values => {
      values.dob = moment(values.dob, "YYYY-MM-DD").format("DD-MM-YYYY");
      values.ip = "97.80.143.232";
      console.log(values);
      if (editAdmin) {
        values.admin_id = editAdmin?.admin_id;
        delete values.password;
        delete values.email;
        delete values.confirm_password;
        delete values.ip;
        dispatch(updateAdminDetails(values));
      } else {
        dispatch(registerNewAdmin(values));
      }
    },
  });
  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "first_name",
        filterable: true,
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        filterable: true,
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "account_status",
        filterable: true,
      },
      {
        Header: "Role",
        accessor: "role_id",
        filterable: true,
        Cell: data => roleObj[data.value]?.role,
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  setOpenModal(true);
                  setEditAdmin(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleDeleteAdmin(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const closeModal = () => {
    setEditAdmin(null);
    setOpenModal(false);
  };

  useEffect(() => {
    setCustomModal(
      addAdminSuccess && (
        <CustomModal
          title="Admin Added Successfully!"
          onOk={() => {
            closeModal();
            setCustomModal(false);
              dispatch(clearAdminState());
            dispatch(getAllAdmins());
            dispatch(getAllRoles());
             validation.handleReset();
          }}
          okText="Okay"
          closeModal={() => {
            validation.handleReset();
            closeModal();
            setCustomModal(false);
          }}
          visible={true}
        />
      )
    );
  }, [addAdminSuccess]);

  useEffect(() => {
    updateAdminSuccess === true &&
      setCustomModal(
        <CustomModal
          title="Admin Updated Successfully!"
          onOk={() => {
            closeModal();
            setCustomModal(false);
            setEditAdmin(null);
            dispatch(clearUpdateAdminState());
            dispatch(getAllAdmins());
            dispatch(getAllRoles());
          }}
          okText="Okay"
          closeModal={() => {
            setEditAdmin(null);
            setTimeout(() => {
              dispatch(clearUpdateAdminState());
            }, 1000);
          }}
          visible={true}
        />
      );
  }, [updateAdminSuccess]);

  const handleDeleteAdmin = admin => {
    setCustomModal(
      <CustomModal
        title="Delete Admin?"
        content={<p>Do you want to delete this admin account?</p>}
        onOk={() => {
          setCustomModal(false);
          console.log("about to delete");
          dispatch(deleteAdmin(admin.admin_id));
          dispatch(getAllAdmins());
          dispatch(getAllRoles());
        }}
        okText="Delete"
        // closeModal={() =>{
        //   dispatch(getAllAdmins())
        //   dispatch(getAllRoles())}}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {getAdminError && (
            <Alert
              color="danger"
              fade={true}
              isOpen={!!getAdminError}
              toggle={() => dispatch(clearGetAdminState())}
            >
              {getAdminError.message || getAdminError}
            </Alert>
          )}
          {getRolesError && (
            <Alert
              color="danger"
              fade={true}
              isOpen={!!getRolesError}
              toggle={() => dispatch(clearGetRoleState())}
            >
              {getRolesError.message || getRolesError}
            </Alert>
          )}
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Admins" breadcrumbItem="Admin List" />
          <Row>
            <Col lg="12">
              <Card loading={getAdminsLoading}>
                {customModal}
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={allAdmins}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleRefreshClicked={() => {
                      dispatch(getAllRoles());
                      dispatch(getAllAdmins());
                    }}
                    handleUserClick={() => setOpenModal(true)}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  {editAdmin ? (
                    <EditAdminModal
                      toggle={closeModal}
                      validation={validation}
                      addAdminError={updateAdminError}
                      allRoles={allRoles}
                      //success={updateAdminSuccess}
                      addAdminLoading={updateAdminLoading}
                      isOpen={openModal}
                    />
                  ) : (
                    <AddAdminModal
                      toggle={closeModal}
                      addAdminLoading={addAdminLoading}
                      allRoles={allRoles}
                      success={addAdminSuccess}
                      validation={validation}
                      addAdminError={addAdminError}
                      isOpen={openModal}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AdminList);
