import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import TableContainer from "./GroupsTable";
import AWS from "aws-sdk";
import {
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Alert,
  Button,
  Form,
  UncontrolledTooltip,
  Input,
  Progress,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomModal from "../../reusables/CustomModal";
import api from "../../api";
import { Card,} from "antd";
import { SettingFilled, DeleteFilled, EditFilled } from "@ant-design/icons";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import WhiteList from "./Whitlist";

const CreateBingos = () => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [openVipModal, setOpenVipModal] = useState(false);
  const [openFreeModal, setOpenFreeModal] = useState(false);
  const [allBingos, setAllBingos] = useState([]);
  const [customModal, setCustomModal] = useState(null);
  const [openWhiteList, setOpenWhiteList] = useState(false);
  const [getBingosError, setGetBingosError] = useState(null);
  const [editBingoProfile, setEditBingoProfile] = useState(null);
  const [createBingoProfileError, setCreateBingoProfileError] = useState(null);
  const [createBingoProfileLoading, setCreateBingoProfileLoading] =
    useState(false);
  const [getBingoProfilesLoading, setGetBingoProfilesLoading] = useState(false);

  const [gameTypes, setBingoTypes] = useState([]);
  const [gameRooms, setBingoRooms] = useState([]);
  const [jackpotConfigs, setJackpotConfigs] = useState([]);
  const [ticketPlans, setTicketPlans] = useState([]);
  const [localeProfiles, setLocaleProfiles] = useState([]);
  const [singleVip, setSingleVip] = useState(null);
  const [singleFree, setSingleFree] = useState(null);
  const [AllFreeGames, setAllFreeGames] = useState([]);
  const [openAllFree, setOpenAllFree] = useState(false);
  const [allVipProfiles, setAllVipProfiles] = useState([]);

  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(editBingoProfile?.banner);
  const [selectedNgFile, setSelectedNgFile] = useState(null);
  const [selectedGhFile, setSelectedGhFile] = useState(null);
  const [selectedZmFile, setSelectedZmFile] = useState(null);
  const [selectedCdFile, setSelectedCdFile] = useState(null);

  const [ngCriteria, setNgCriteria] = useState(null);
  const [ghCriteria, setGhCriteria] = useState(null);
  const [zmCriteria, setZmCriteria] = useState(null);
  const [cdCriteria, setCdCriteria] = useState(null);

  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const [ngTicketPlan, setNgTicketPlan] = useState(null);
  const [ghTicketPlan, setGhTicketPlan] = useState(null);
  const [zmTicketPlan, setZmTicketPlan] = useState(null);
  const [cdTicketPlan, setCdTicketPlan] = useState(null);
  const [ngJpConfig, setNgJpConfig] = useState(null);
  const [ghJpConfig, setGhJpConfig] = useState(null);
  const [zmJpConfig, setZmJpConfig] = useState(null);
  const [cdJpConfig, setCdJpConfig] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [autoRotate, setAutoRotate] = useState(
    editBingoProfile?.auto_rotate_min_players || false
  );
  const country = localStorage.getItem("country");
  const defaultCountry = localeProfiles.find(one => one.tld_code === country);
  const isGlobal = country === "global";
  const vipValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      game_profile_id: editBingoProfile?.game_profile_id,
      vip_groups_id: singleVip?.vip_groups_id || "",
      assign_status: singleVip?.assign_status || "",
    },
    validationSchema: Yup.object({
      vip_groups_id: Yup.string().required("Please select a group"),
      assign_status: Yup.string().required("Please choose game status"),
    }),

    onSubmit: values => {
      if (!isGlobal) {
        values["criteria"] = selectedCriteria || singleVip?.criteria;
      }
      if (isGlobal) {
        values["criteria"] = JSON.stringify({
          ng: ngCriteria,
          gh: ghCriteria,
          zm: zmCriteria,
          cd: cdCriteria,
        });
      }
      singleVip
        ? updateSingleVipProfile(values)
        : createSingleVipProfile(values);
    },
  });

  const FreeGameValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      game_profile_id: editBingoProfile?.game_profile_id,
      user_play_count: singleFree?.user_play_count || 0,
      user_win_count: singleFree?.user_win_count || 0,
      free_game_id: singleFree?.free_game_id,
      country: singleFree?.country || country,
      deposit_amount_criteria: singleFree?.deposit_amount_criteria || "",
      range: singleFree?.range || "",
    },
    validationSchema: Yup.object({
      country: Yup.string().required("Please pick country"),
    }),

    onSubmit: values => {
      singleFree
        ? updateSingleFreeGameProfile(values)
        : createSingleFreeGameProfile(values);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      game_group_id: editBingoProfile?.game_group_id || "",
      game_type_id: editBingoProfile?.game_type_id || "",
      game_name: editBingoProfile?.game_name || "",
      game_status: editBingoProfile?.game_status || "",
      game_jackpot_config_id: editBingoProfile?.game_jackpot_config_id || "",
      tag_label: editBingoProfile?.tag_label || "",
      ticket_group_id: editBingoProfile?.ticket_group_id || "",
      min_players: editBingoProfile?.min_players || "",
      min_tickets: editBingoProfile?.min_tickets || "",
      auto_rotate_min_players:
        editBingoProfile?.auto_rotate_min_players || false,
      auto_rotate_min_players_min:
        editBingoProfile?.auto_rotate_min_players_min || 0,
      auto_rotate_min_players_max:
        editBingoProfile?.auto_rotate_min_players_max || 0,
      max_tickets: editBingoProfile?.max_tickets || 0,
      country_profile_id:
        editBingoProfile?.country_profile_id ||
        defaultCountry?.country_profile_id ||
        "",
    },
    validationSchema: Yup.object({
      game_name: Yup.string().required("Please Enter Bingo Name"),
      tag_label: Yup.string().required("Please enter game tag"),
      game_group_id: Yup.string().required("Please select game group"),
      game_type_id: Yup.string().required("Please select game type"),
      ticket_group_id: Yup.string(),
      game_jackpot_config_id: Yup.string(),
      game_status: Yup.string().required("Please select game status"),
      min_players: Yup.number().required("Please enter min number of players"),
      min_tickets: Yup.number().required("Please enter min number of tickets"),
      max_tickets: Yup.number().required("Please enter max number of tickets"),
      country_profile_id: Yup.string().required("Please select locale country"),
    }),
    onSubmit: values => {
      // if (!isGlobal && !selectedFile) {
      //   return setCreateBingoProfileError("Please upload banner image");
      // }
      if (
        values.auto_rotate_min_players &&
        values.auto_rotate_min_players_max < values.auto_rotate_min_players_min
      ) {
        console.log("heyy");
        setCreateBingoProfileError(
          "Auto rotate max must be greater than auto rotate min"
        );

        return;
      }
      if (!isGlobal) {
        values["banner"] = selectedFile || editBingoProfile?.banner;
      }
      if (isGlobal) {
        values["banner"] = JSON.stringify({
          ng: selectedNgFile,
          gh: selectedGhFile,
          zm: selectedZmFile,
          cd: selectedCdFile,
        });
        values["ticket_group_id"] = JSON.stringify({
          ng: ngTicketPlan,
          gh: ghTicketPlan,
          zm: zmTicketPlan,
          cd: cdTicketPlan,
        });
        values["game_jackpot_config_id"] = JSON.stringify({
          ng: ngJpConfig,
          gh: ghJpConfig,
          zm: zmJpConfig,
          cd: cdJpConfig,
        });
      }
      editBingoProfile
        ? updateBingoProfile(editBingoProfile.game_profile_id, values)
        : createBingoProfile(values);
    },
  });
  const freeColumns = [
    {
      Header: "Country",
      accessor: "country",
      filterable: true,
    },
    {
      Header: "Deposit Criteria",
      accessor: "deposit_amount_criteria",
      filterable: true,
    },
    {
      Header: "User Play Count",
      accessor: "user_play_count",
      filterable: true,
    },
    {
      Header: "User Win Count",
      accessor: "user_win_count",
      filterable: true,
    },
    {
      Header: "Range",
      accessor: "range",
      filterable: true,
    },

    {
      Header: "Actions",
      Cell: cellProps => {
        return (
          <div className="d-flex gap-3">
            {
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  console.log(editBingoProfile);
                  const data = cellProps.row.original;
                  setOpenFreeModal(true);
                  setSingleFree(data);
                }}
              >
                <EditFilled className="font-size-12" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            }

            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const data = cellProps.row.original;
                handleDeleteFreeProfile(data);
              }}
            >
              <DeleteFilled className="font-size-12" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        );
      },
    },
  ];
  const columns = [
    {
      Header: "Name",
      accessor: "game_name",
      filterable: true,
    },
    {
      Header: "Status",
      accessor: "game_status",
      filterable: true,
    },
    {
      Header: "Bingo Group",
      accessor: "game_group_id",
      filterable: true,
      Cell: data =>
        gameRooms.find(room => room.game_group_id === data.value)?.group_name ||
        "N/A",
    },
    {
      Header: "Bingo Type",
      accessor: "game_type_id",
      filterable: true,
      Cell: data =>
        gameTypes.find(type => type.game_type_id === data.value)?.game_type ||
        "N/A",
    },
    {
      Header: "Jackpot Config",
      accessor: "game_jackpot_config_id",
      filterable: true,
      Cell: data => {
        const getJpName = id =>
          jackpotConfigs.find(config => config.game_jackpot_config_id === id)
            ?.jackpot_config_name || "N/A";

        return isGlobal
          ? Object.keys(JSON.parse(data.value)).map(country => (
              <p key={Math.random()}>
                {country}: {getJpName(JSON.parse(data.value)[country])}
              </p>
            ))
          : getJpName(data.value);
      },
    },
    {
      Header: "Ticket Group",
      accessor: "ticket_group_id",
      filterable: true,
      Cell: data => {
        const getTicketGroup = id =>
          ticketPlans.find(group => group.ticket_group_id === id)
            ?.ticket_group_name || "N/A";

        return isGlobal
          ? Object.keys(JSON.parse(data.value)).map(country => (
              <p key={Math.random()}>
                {country}: {getTicketGroup(JSON.parse(data.value)[country])}
              </p>
            ))
          : getTicketGroup(data.value);
      },
    },
    {
      Header: "Min Players",
      accessor: "min_players",
      filterable: true,
    },
    {
      Header: "Actions",
      Cell: cellProps => {
        const game = gameRooms.find(
          room => room.game_group_id === cellProps.row.original.game_group_id
        )?.group_name;

        return (
          <div className="d-flex gap-3">
            {(game == "vip" || game == "free games") && (
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  game == "vip" ? setOpenVipModal(true) : setOpenAllFree(true);
                  game == "vip"
                    ? getSingleVipProfile(data.game_profile_id)
                    : getSingleFreeProfile(data.game_profile_id);
                  setEditBingoProfile(data);
                }}
              >
                <SettingFilled className="font-size-12" id="settingstooltip" />
                <UncontrolledTooltip placement="top" target="settingstooltip">
                  Settings
                </UncontrolledTooltip>
              </Link>
            )}
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const data = cellProps.row.original;
                setOpenAddGroupModal(true);
                setEditBingoProfile(data);
                console.log(data);
              }}
            >
              <EditFilled className="font-size-12" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>

            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const data = cellProps.row.original;
                handleDeleteProfile(data);
              }}
            >
              <DeleteFilled className="font-size-12" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        );
      },
    },
  ];

  const confirmCancel = () => {
    setCustomModal(
      <CustomModal
        title="Cancel Create Bingo?"
        content={
          <>
            <p>Do you want to stop creating new game?</p>
            <p>Bingo might not be saved</p>
          </>
        }
        onOk={() => {
          setCustomModal(null);
          setOpenAddGroupModal(false);
          setEditBingoProfile(null);
          setSelectedFile(null);
        }}
        okText="Okay"
        closeModal={() => {
          setCustomModal(null);
          setOpenAddGroupModal(false);
          setEditBingoProfile(null);
          setSelectedFile(null);
        }}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const confirmVipCancel = () => {
    setCustomModal(
      <CustomModal
        title="Cancel Create Bingo?"
        content={
          <>
            <p>Do you want to stop creating new vip profile?</p>
            <p>Changes might not be saved</p>
          </>
        }
        onOk={() => {
          setCustomModal(null);
          setOpenVipModal(false);
          setEditBingoProfile(null);
          setSingleVip(null);
        }}
        okText="Okay"
        closeModal={() => {
          setCustomModal(null);
          setOpenVipModal(false);
          setEditBingoProfile(null);
          setSingleVip(null);
        }}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const confirmFreeGameCancel = () => {
    setCustomModal(
      <CustomModal
        title="Cancel Create Bingo?"
        content={
          <>
            <p>Do you want to stop creating a new free game profile?</p>
            <p>Changes might not be saved</p>
          </>
        }
        onOk={() => {
          setCustomModal(null);
          setOpenFreeModal(false);

          setSingleFree(null);
        }}
        okText="Okay"
        closeModal={() => {
          setCustomModal(null);

          setSingleVip(null);
        }}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const getAllVipProfiles = () => {
    api.games
      .getAllVipProfiles()
      .then(res => {
        if (res.status === 200) {
          setAllVipProfiles(res.data.data);
        }
      })
      .catch(err => {
        setCreateBingoProfileError(err.response?.data.message || err.message);
      });
  };

  const getSingleVipProfile = game_profile_id => {
    api.games
      .getSingleVipProfile(game_profile_id)
      .then(res => {
        if (res.status === 200) {
          setSingleVip(res.data.data);
        }
      })
      .catch(err => {
        setCreateBingoProfileError(err.response?.data.message || err.message);
      });
  };
  const getSingleFreeProfile = game_profile_id => {
    api.games
      .getFreeGame(game_profile_id)
      .then(res => {
        if (res.status === 200) {
          setAllFreeGames(res.data.data);
        }
      })
      .catch(err => {
        setCreateBingoProfileError(err.response?.data.message || err.message);
      });
  };

  const updateSingleVipProfile = payload => {
    api.games
      .updateSingleVipProfile(payload)
      .then(res => {
        if (res.status === 200) {
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Vip Profile Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenVipModal(false);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenVipModal(false);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoProfileError(err.response?.data.message || err.message);
      });
  };

  const updateSingleFreeGameProfile = payload => {
    api.games
      .updateFreegame(payload)
      .then(res => {
        if (res.status === 200) {
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Free Game Profile Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenFreeModal(false);
                getSingleFreeProfile(editBingoProfile?.game_profile_id);
                FreeGameValidation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenFreeModal(false);
                getSingleFreeProfile(editBingoProfile?.game_profile_id);
                FreeGameValidation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoProfileError(err.response?.data.message || err.message);
      });
  };

  const createSingleVipProfile = payload => {
    api.games
      .createSingleVipProfile(payload)
      .then(res => {
        if (res.status === 200) {
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Vip Profile Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenVipModal(false);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenVipModal(false);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoProfileError(err.response?.data.message || err.message);
      });
  };

  const createSingleFreeGameProfile = payload => {
    api.games
      .createFreegame(payload)
      .then(res => {
        if (res.status === 200) {
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Free Game Profile Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenFreeModal(false);
                getSingleFreeProfile(editBingoProfile?.game_profile_id);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenFreeModal(false);
                getSingleFreeProfile(editBingoProfile?.game_profile_id);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoProfileError(err.response?.data.message || err.message);
      });
  };
  const createBingoProfile = payload => {
    setCreateBingoProfileLoading(true);
    api.games
      .createBingoProfile(payload)
      .then(res => {
        setCreateBingoProfileLoading(false);
        if (res.status === 200) {
          setCreateBingoProfileError(null);
          getAllBingoProfiles();
          setOpenAddGroupModal(false);
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo Profile Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoProfileLoading(false);
        setCreateBingoProfileError(err.response?.data.message || err.message);
      });
  };

  const updateBingoProfile = (id, payload) => {
    setCreateBingoProfileLoading(true);
    api.games
      .updateBingoProfile(id, payload)
      .then(res => {
        setCreateBingoProfileLoading(false);
        if (res.status === 200) {
          setCreateBingoProfileError(null);
          getAllBingoProfiles();
          setOpenAddGroupModal(false);
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo Profile Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoProfileLoading(false);
        setCreateBingoProfileError(err.response?.data.message || err.message);
      });
  };

  const getAllBingoProfiles = () => {
    setGetBingoProfilesLoading(true);
    api.games
      .getAllBingoProfiles()
      .then(res => {
        setGetBingoProfilesLoading(false);
        if (res.status === 200) {
          setAllBingos(res.data.data);
        }
      })
      .catch(err => {
        setGetBingoProfilesLoading(false);
        setGetBingosError(err.response?.data.message || err.message);
      });
  };

  const getAllBingoTypes = () => {
    api.games
      .getAllBingoTypes()
      .then(res => {
        if (res.status === 200) {
          setBingoTypes(res.data.data);
        }
      })
      .catch(err => {
        setGetBingosError(err.response?.data.message || err.message);
      });
  };

  const getAllBingoRooms = () => {
    api.games
      .getAllBingoGroups()
      .then(res => {
        if (res.status === 200) {
          setBingoRooms(res.data.data);
        }
      })
      .catch(err => {
        setGetBingosError(err.response?.data.message || err.message);
      });
  };

  const getAllLocaleProfiles = () => {
    api.commerce
      .getAllLocaleProfiles()
      .then(res => {
        if (res.status === 200) {
          setLocaleProfiles(res.data.data);
        }
      })
      .catch(err => {
        setGetBingosError(err.response?.data.message || err.message);
      });
  };

  const getAllTicketGroups = () => {
    api.tickets
      .getAllTicketGroups()
      .then(res => {
        if (res.status === 200) {
          setTicketPlans(res.data.data);
        }
      })
      .catch(err => {
        setGetBingosError(err.response?.data.message || err.message);
      });
  };

  const getAllJackpotConfigs = () => {
    api.games
      .getAllJackpotConfigs()
      .then(res => {
        if (res.status === 200) {
          setJackpotConfigs(res.data.data.jp_configs);
        }
      })
      .catch(err => {
        setGetBingosError(err.response?.data.message || err.message);
      });
  };

  const deleteBingoProfile = id => {
    api.games
      .deleteBingoProfile(id)
      .then(res => {
        if (res.status === 200) {
          getAllBingoProfiles();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo Profile Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setGetBingosError(err.response?.data.message || err.message);
      });
  };
  const deleteFreeBingoProfile = id => {
    api.games
      .deleteFreeGame(id)
      .then(res => {
        if (res.status === 200) {
          getAllBingoProfiles();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo Profile Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
                getSingleFreeProfile(editBingoProfile?.game_profile_id);
              }}
              closeModal={() => {
                setCustomModal(null);
                getSingleFreeProfile(editBingoProfile?.game_profile_id);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setGetBingosError(err.response?.data.message || err.message);
      });
  };

  const handleDeleteProfile = data => {
    setCustomModal(
      <CustomModal
        title="Delete Bingo Profile?"
        content={<p>Do you want to delete this game profile?</p>}
        onOk={() => {
          deleteBingoProfile(data.game_profile_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const handleDeleteFreeProfile = data => {
    setCustomModal(
      <CustomModal
        title="Delete Bingo Profile?"
        content={<p>Do you want to delete this game profile?</p>}
        onOk={() => {
          deleteFreeBingoProfile(data.free_game_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };
  useEffect(() => {
    getAllBingoProfiles();
    getAllBingoRooms();
    getAllBingoTypes();
    getAllTicketGroups();
    getAllJackpotConfigs();
    getAllLocaleProfiles();
    getAllVipProfiles();
  }, []);

  useEffect(() => {
    editBingoProfile?.banner && setSelectedFile(editBingoProfile.banner);
    setAutoRotate(editBingoProfile?.auto_rotate_min_players);
  }, [editBingoProfile]);

  useEffect(() => {
    if (isGlobal && singleVip) {
      setNgCriteria(JSON.parse(singleVip.criteria)["ng"]);
      setGhCriteria(JSON.parse(singleVip.criteria)["gh"]);
      setZmCriteria(JSON.parse(singleVip.criteria)["zm"]);
      setCdCriteria(JSON.parse(singleVip.criteria)["cd"]);
    }
    if (!isGlobal && singleVip) {
      setSelectedCriteria(singleVip?.criteria);
    }
  }, [singleVip]);
  //meta title
  document.title = "Create Bingo";

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKETNAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const handleFileInput = (e, setSelectedFile) => {
    uploadFile(e.target.files[0], setSelectedFile);
  };

  const uploadFile = (file, setSelectedFile) => {
    setUploadLoading(true);
    setSelectedFile("");

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKETNAME,
      Key: file.name,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", evt => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send(err => {
        setUploadLoading(false);
        if (err) {
          console.log(err);
        } else {
          setSelectedFile(
            `https://${process.env.REACT_APP_AWS_BUCKETNAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${file.name}`
          );
        }
      });
  };

  const handleCriteria = (code, e) => {
    switch (code) {
      case "ng":
        setNgCriteria(e.target.value);
        break;
      case "gh":
        setGhCriteria(e.target.value);
        break;
      case "zm":
        setZmCriteria(e.target.value);
        break;
      case "cd":
        setCdCriteria(e.target.value);
        break;
      case "global":
        console.log("global");
        setSelectedCriteria(e.target.value);
        break;
      default:
        setSelectedCriteria(e.target.value);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {getBingosError && (
            <Alert
              color="danger"
              fade={true}
              isOpen={!!getBingosError}
              toggle={() => setGetBingosError(null)}
            >
              {getBingosError}
            </Alert>
          )}
          <Breadcrumbs title="Bingos" breadcrumbItem="All Bingos" />
          <Modal isOpen={openVipModal}>
            <ModalHeader toggle={confirmVipCancel} tag="h4">
              {singleVip ? "Edit" : "Add"} Vip Configuration
            </ModalHeader>
            <ModalBody>
              <Col span={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputState">
                    <b>Group Name</b>
                  </Label>
                  <Input
                    type="select"
                    name="vip_groups_id"
                    id="vip_groups_id"
                    className="form-control"
                    onChange={vipValidation.handleChange}
                    onBlur={vipValidation.handleBlur}
                    value={vipValidation.values.vip_groups_id || ""}
                    invalid={
                      vipValidation.touched.vip_groups_id &&
                      vipValidation.errors.vip_groups_id
                        ? true
                        : false
                    }
                  >
                    <option value="">Select one...</option>
                    {allVipProfiles.map(type => (
                      <option
                        key={type.vip_groups_id}
                        value={type.vip_groups_id}
                      >
                        {type.group_name}
                      </option>
                    ))}
                  </Input>
                  {vipValidation.touched.vip_groups_id &&
                  vipValidation.errors.vip_groups_id ? (
                    <FormFeedback type="invalid">
                      {vipValidation.errors.vip_groups_id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputState">
                    <b>Status</b>
                  </Label>
                  <Input
                    type="select"
                    name="assign_status"
                    id="assign_status"
                    className="form-control"
                    onChange={vipValidation.handleChange}
                    onBlur={vipValidation.handleBlur}
                    value={vipValidation.values.assign_status || ""}
                    invalid={
                      vipValidation.touched.assign_status &&
                      vipValidation.errors.assign_status
                        ? true
                        : false
                    }
                  >
                    <option key={0} value="">
                      Select one...
                    </option>
                    <option key={1} value={"active"}>
                      active
                    </option>
                    <option key={2} value={"disabled"}>
                      disabled
                    </option>
                  </Input>
                  {vipValidation.touched.assign_status &&
                  vipValidation.errors.assign_status ? (
                    <FormFeedback type="invalid">
                      {vipValidation.errors.assign_status}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              {isGlobal ? (
                <div>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="ng_criteria">
                        <b>Ng Criteria</b>
                      </Label>
                      <Input
                        type="textarea"
                        name="ng_criteria"
                        id="ng_criteria"
                        className="form-control"
                        onChange={e => handleCriteria("ng", e)}
                        value={ngCriteria || ""}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Gh Criteria</b>
                      </Label>
                      <Input
                        type="textarea"
                        name="assign_status"
                        id="assign_status"
                        className="form-control"
                        onChange={e => handleCriteria("gh", e)}
                        value={ghCriteria || ""}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Cd Criteria</b>
                      </Label>
                      <Input
                        type="textarea"
                        name="assign_status"
                        id="assign_status"
                        className="form-control"
                        onChange={e => handleCriteria("cd", e)}
                        value={cdCriteria || ""}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Zm Criteria</b>
                      </Label>
                      <Input
                        type="textarea"
                        name="assign_status"
                        id="assign_status"
                        className="form-control"
                        onChange={e => handleCriteria("zm", e)}
                        onBlur={vipValidation.handleBlur}
                        value={zmCriteria || ""}
                      />
                    </div>
                  </Col>
                </div>
              ) : (
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="formrow-InputState">
                      <b>{country.toLocaleUpperCase()} Criteria</b>
                    </Label>
                    <Input
                      type="textarea"
                      name="assign_status"
                      id="assign_status"
                      className="form-control"
                      onChange={e => handleCriteria("global", e)}
                      value={selectedCriteria || ""}
                    />
                  </div>
                </Col>
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={confirmVipCancel}
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <Button
                  type="button"
                  onClick={vipValidation.handleSubmit}
                  disabled={createBingoProfileLoading}
                  className="btn btn-success "
                  // disabled={editBingoProfile}
                >
                  {singleVip ? "Edit" : "Create"} Profile
                </Button>
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={openFreeModal}>
            <ModalHeader toggle={confirmFreeGameCancel} tag="h4">
              {singleFree ? "Edit" : "Add"} Free Game Configuration
            </ModalHeader>
            <ModalBody>
              <Col span={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputState">
                    <b>Deposit Amount Criteria</b>
                  </Label>
                  <Input
                    type="number"
                    name="deposit_amount_criteria"
                    id="deposit_amount_criteria"
                    className="form-control"
                    onChange={FreeGameValidation.handleChange}
                    onBlur={FreeGameValidation.handleBlur}
                    value={
                      FreeGameValidation.values.deposit_amount_criteria || ""
                    }
                    invalid={
                      FreeGameValidation.touched.deposit_amount_criteria &&
                      FreeGameValidation.errors.deposit_amount_criteria
                        ? true
                        : false
                    }
                  ></Input>
                  {FreeGameValidation.touched.deposit_amount_criteria &&
                  FreeGameValidation.errors.deposit_amount_criteria ? (
                    <FormFeedback type="invalid">
                      {FreeGameValidation.errors.deposit_amount_criteria}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputState">
                    <b>User Play Count</b>
                  </Label>
                  <Input
                    type="number"
                    name="user_play_count"
                    id="user_play_count"
                    className="form-control"
                    onChange={FreeGameValidation.handleChange}
                    onBlur={FreeGameValidation.handleBlur}
                    value={FreeGameValidation.values.user_play_count || ""}
                    invalid={
                      FreeGameValidation.touched.user_play_count &&
                      FreeGameValidation.errors.user_play_count
                        ? true
                        : false
                    }
                  ></Input>
                  {FreeGameValidation.touched.user_play_count &&
                  FreeGameValidation.errors.user_play_count ? (
                    <FormFeedback type="invalid">
                      {FreeGameValidation.errors.user_play_count}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputState">
                    <b>User Win Count</b>
                  </Label>
                  <Input
                    type="number"
                    name="user_win_count"
                    id="user_win_count"
                    className="form-control"
                    onChange={FreeGameValidation.handleChange}
                    onBlur={FreeGameValidation.handleBlur}
                    value={FreeGameValidation.values.user_win_count || ""}
                    invalid={
                      FreeGameValidation.touched.user_win_count &&
                      FreeGameValidation.errors.user_win_count
                        ? true
                        : false
                    }
                  ></Input>
                  {FreeGameValidation.touched.user_win_count &&
                  FreeGameValidation.errors.user_win_count ? (
                    <FormFeedback type="invalid">
                      {FreeGameValidation.errors.user_win_count}{" "}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputState">
                    <b>Range</b>
                  </Label>
                  <Input
                    type="select"
                    name="range"
                    id="range"
                    className="form-control"
                    onChange={FreeGameValidation.handleChange}
                    onBlur={FreeGameValidation.handleBlur}
                    value={FreeGameValidation.values.range || ""}
                    invalid={
                      FreeGameValidation.touched.range &&
                      FreeGameValidation.errors.range
                        ? true
                        : false
                    }
                  >
                    <option key={0} value="">
                      Select one...
                    </option>
                    <option key={1} value={"daily"}>
                      daily
                    </option>
                    <option key={2} value={"weekly"}>
                      weekly
                    </option>
                    <option key={3} value={"monthly"}>
                      monthly
                    </option>
                  </Input>
                  {FreeGameValidation.touched.range &&
                  FreeGameValidation.errors.range ? (
                    <FormFeedback type="invalid">
                      {FreeGameValidation.errors.range}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputState">
                    <b>Country</b>
                  </Label>
                  {isGlobal ? (
                    <Input
                      type="select"
                      name="country"
                      id="country"
                      className="form-control"
                      onChange={FreeGameValidation.handleChange}
                      onBlur={FreeGameValidation.handleBlur}
                      value={FreeGameValidation.values.country || ""}
                      invalid={
                        FreeGameValidation.touched.country &&
                        FreeGameValidation.errors.country
                          ? true
                          : false
                      }
                    >
                      <option key={0} value="">
                        Select one...
                      </option>
                      <option key={1} value={"ng"}>
                        Nigeria
                      </option>
                      <option key={2} value={"gh"}>
                        Ghana
                      </option>
                      <option key={3} value={"zm"}>
                        Zimbabwe
                      </option>
                      <option key={4} value={"cd"}>
                        Cd
                      </option>
                    </Input>
                  ) : (
                    <Input
                      type="text"
                      name="country"
                      id="country"
                      disabled
                      className="form-control"
                      onBlur={FreeGameValidation.handleBlur}
                      value={country.toLocaleUpperCase()}
                    />
                  )}
                  {FreeGameValidation.touched.range &&
                  FreeGameValidation.errors.range ? (
                    <FormFeedback type="invalid">
                      {FreeGameValidation.errors.range}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={confirmFreeGameCancel}
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <Button
                  type="button"
                  onClick={FreeGameValidation.handleSubmit}
                  disabled={createBingoProfileLoading}
                  className="btn btn-success "
                  // disabled={editBingoProfile}
                >
                  {singleFree ? "Edit" : "Create"} Profile
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={openAllFree}
            size="lg"
            toggle={() => {
              setOpenAllFree(false);
              setSingleFree(null);
            }}
          >
            <ModalHeader
              toggle={() => {
                setOpenAllFree(false);
                setSingleFree(null);
              }}
              tag="h4"
            >
              Country Criteria
            </ModalHeader>
            <ModalBody>
              <TableContainer
                columns={freeColumns}
                data={AllFreeGames}
                setShowDetailsModal={() => {}}
                isGlobalFilter={true}
                free={true}
                isAddUserList={true}
                handleUserClick={() => {
                  setOpenFreeModal(true);
                }}
                handleWhiteList={() => {
                  setOpenWhiteList(true);
                }}
                customPageSize={10}
                className="custom-header-css"
              />
            </ModalBody>
          </Modal>
          <Row>
            <Col lg="12">
              <Card loading={getBingoProfilesLoading}>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={allBingos}
                    setShowDetailsModal={() => {}}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={() => {
                      setOpenAddGroupModal(true);
                    }}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  {customModal}

                  <Modal
                    isOpen={openAddGroupModal}
                    size="lg"
                    toggle={confirmCancel}
                  >
                    {createBingoProfileError && (
                      <Alert
                        color="danger"
                        fade={true}
                        isOpen={!!createBingoProfileError}
                        toggle={() => setCreateBingoProfileError(null)}
                      >
                        {createBingoProfileError}
                      </Alert>
                    )}
                    <ModalHeader toggle={confirmCancel} tag="h4">
                      Create New Bingo
                    </ModalHeader>
                    <ModalBody>
                      <Form>
                        <Row>
                          <Col xs={12} style={{ padding: "0 20px" }}>
                            <div className="mb-3">
                              <Label className="form-label">
                                <b>Bingo Name</b>
                              </Label>
                              <Input
                                name="game_name"
                                id="game_name"
                                type="text"
                                placeholder="Enter game name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.game_name || ""}
                                invalid={
                                  validation.touched.game_name &&
                                  validation.errors.game_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.game_name &&
                              validation.errors.game_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.game_name}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <Row>
                              <Col span={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    <b>Bingo Group</b>
                                  </Label>
                                  <Input
                                    className="form-control"
                                    name="game_group_id"
                                    id="game_group_id"
                                    type="select"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.game_group_id || ""
                                    }
                                    invalid={
                                      validation.touched.game_group_id &&
                                      validation.errors.game_group_id
                                        ? true
                                        : false
                                    }
                                  >
                                    <option defaultValue value="">
                                      Select One
                                    </option>
                                    {gameRooms.map(room => (
                                      <option
                                        key={room.game_group_id}
                                        value={room.game_group_id}
                                      >
                                        {room.group_name}
                                      </option>
                                    ))}
                                  </Input>
                                  {validation.touched.game_group_id &&
                                  validation.errors.game_group_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.game_group_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col span={6}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-InputState">
                                    <b>Bingo Type</b>
                                  </Label>
                                  <Input
                                    type="select"
                                    name="game_type_id"
                                    id="game_type_id"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.game_type_id || ""}
                                    invalid={
                                      validation.touched.game_type_id &&
                                      validation.errors.game_type_id
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="">Select one...</option>
                                    {gameTypes.map(type => (
                                      <option
                                        key={type.game_type_id}
                                        value={type.game_type_id}
                                      >
                                        {type.game_type}
                                      </option>
                                    ))}
                                  </Input>
                                  {validation.touched.game_type_id &&
                                  validation.errors.game_type_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.game_type_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>

                            {!isGlobal && (
                              <div className="mb-3">
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputState">
                                        <b>Ticket Plan</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        name="ticket_group_id"
                                        id="ticket_group_id"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values.ticket_group_id ||
                                          ""
                                        }
                                        invalid={
                                          validation.touched.ticket_group_id &&
                                          validation.errors.ticket_group_id
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {ticketPlans.map(
                                          plan =>
                                            plan.ticket_group_status ===
                                              "active" && (
                                              <option
                                                key={plan.ticket_group_id}
                                                value={plan.ticket_group_id}
                                              >
                                                {plan.ticket_group_name}
                                              </option>
                                            )
                                        )}
                                      </Input>
                                      {validation.touched.game_type_id &&
                                      validation.errors.game_type_id ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.game_type_id}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="game_jackpot_config_id">
                                        <b> Jackpot Configuration</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        name="game_jackpot_config_id"
                                        id="game_jackpot_config_id"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values
                                            .game_jackpot_config_id || ""
                                        }
                                        invalid={
                                          validation.touched
                                            .game_jackpot_config_id &&
                                          validation.errors
                                            .game_jackpot_config_id
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {jackpotConfigs.map(config => (
                                          <option
                                            key={config.game_jackpot_config_id}
                                            value={
                                              config.game_jackpot_config_id
                                            }
                                          >
                                            {config.jackpot_config_name}
                                          </option>
                                        ))}
                                      </Input>
                                      {validation.touched
                                        .game_jackpot_config_id &&
                                      validation.errors
                                        .game_jackpot_config_id ? (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors
                                              .game_jackpot_config_id
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}

                            {isGlobal && (
                              <div className="mb-3">
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputState">
                                        <b>Nigeria Ticket Plan</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control"
                                        onChange={e =>
                                          setNgTicketPlan(e.target.value)
                                        }
                                        defaultValue={
                                          editBingoProfile
                                            ? JSON.parse(
                                                editBingoProfile.ticket_group_id
                                              )["ng"]
                                            : ""
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {ticketPlans
                                          .filter(plan => plan.country === "ng")
                                          .map(
                                            plan =>
                                              plan.ticket_group_status ===
                                                "active" && (
                                                <option
                                                  key={plan.ticket_group_id}
                                                  value={plan.ticket_group_id}
                                                >
                                                  {plan.ticket_group_name}
                                                </option>
                                              )
                                          )}
                                      </Input>
                                    </div>
                                  </Col>

                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputState">
                                        <b>Ghana Ticket Plan</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control"
                                        onChange={e =>
                                          setGhTicketPlan(e.target.value)
                                        }
                                        defaultValue={
                                          editBingoProfile
                                            ? JSON.parse(
                                                editBingoProfile.ticket_group_id
                                              )["gh"]
                                            : ""
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {ticketPlans
                                          .filter(plan => plan.country === "gh")
                                          .map(
                                            plan =>
                                              plan.ticket_group_status ===
                                                "active" && (
                                                <option
                                                  key={plan.ticket_group_id}
                                                  value={plan.ticket_group_id}
                                                >
                                                  {plan.ticket_group_name}
                                                </option>
                                              )
                                          )}
                                      </Input>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {isGlobal && (
                              <div className="mb-3">
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputState">
                                        <b>Zambia Ticket Plan</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control"
                                        onChange={e =>
                                          setZmTicketPlan(e.target.value)
                                        }
                                        defaultValue={
                                          editBingoProfile
                                            ? JSON.parse(
                                                editBingoProfile.ticket_group_id
                                              )["zm"]
                                            : ""
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {ticketPlans
                                          .filter(plan => plan.country === "zm")
                                          .map(
                                            plan =>
                                              plan.ticket_group_status ===
                                                "active" && (
                                                <option
                                                  key={plan.ticket_group_id}
                                                  value={plan.ticket_group_id}
                                                >
                                                  {plan.ticket_group_name}
                                                </option>
                                              )
                                          )}
                                      </Input>
                                    </div>
                                  </Col>

                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputState">
                                        <b>DRC Ticket Plan</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control"
                                        onChange={e =>
                                          setCdTicketPlan(e.target.value)
                                        }
                                        defaultValue={
                                          editBingoProfile
                                            ? JSON.parse(
                                                editBingoProfile.ticket_group_id
                                              )["cd"]
                                            : ""
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {ticketPlans
                                          .filter(plan => plan.country === "cd")
                                          .map(
                                            plan =>
                                              plan.ticket_group_status ===
                                                "active" && (
                                                <option
                                                  key={plan.ticket_group_id}
                                                  value={plan.ticket_group_id}
                                                >
                                                  {plan.ticket_group_name}
                                                </option>
                                              )
                                          )}
                                      </Input>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {isGlobal && (
                              <div className="mb-3">
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="game_jackpot_config_id">
                                        <b>Nigeria Jackpot Configuration</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control"
                                        onChange={e =>
                                          setNgJpConfig(e.target.value)
                                        }
                                        defaultValue={
                                          editBingoProfile
                                            ? JSON.parse(
                                                editBingoProfile.game_jackpot_config_id
                                              )["ng"]
                                            : ""
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {jackpotConfigs.map(
                                          config =>
                                            config.country === "ng" && (
                                              <option
                                                key={
                                                  config.game_jackpot_config_id
                                                }
                                                value={
                                                  config.game_jackpot_config_id
                                                }
                                              >
                                                {config.jackpot_config_name}
                                              </option>
                                            )
                                        )}
                                      </Input>
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="game_jackpot_config_id">
                                        <b>Ghana Jackpot Configuration</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control"
                                        onChange={e =>
                                          setGhJpConfig(e.target.value)
                                        }
                                        defaultValue={
                                          editBingoProfile
                                            ? JSON.parse(
                                                editBingoProfile.game_jackpot_config_id
                                              )["gh"]
                                            : ""
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {jackpotConfigs.map(
                                          config =>
                                            config.country === "gh" && (
                                              <option
                                                key={
                                                  config.game_jackpot_config_id
                                                }
                                                value={
                                                  config.game_jackpot_config_id
                                                }
                                              >
                                                {config.jackpot_config_name}
                                              </option>
                                            )
                                        )}
                                      </Input>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {isGlobal && (
                              <div className="mb-3">
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="game_jackpot_config_id">
                                        <b>Zambia Jackpot Configuration</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control"
                                        onChange={e =>
                                          setZmJpConfig(e.target.value)
                                        }
                                        defaultValue={
                                          editBingoProfile
                                            ? JSON.parse(
                                                editBingoProfile.game_jackpot_config_id
                                              )["zm"]
                                            : ""
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {jackpotConfigs.map(
                                          config =>
                                            config.country === "zm" && (
                                              <option
                                                key={
                                                  config.game_jackpot_config_id
                                                }
                                                value={
                                                  config.game_jackpot_config_id
                                                }
                                              >
                                                {config.jackpot_config_name}
                                              </option>
                                            )
                                        )}
                                      </Input>
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="game_jackpot_config_id">
                                        <b>DRC Jackpot Configuration</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control"
                                        onChange={e =>
                                          setCdJpConfig(e.target.value)
                                        }
                                        defaultValue={
                                          editBingoProfile
                                            ? JSON.parse(
                                                editBingoProfile.game_jackpot_config_id
                                              )["cd"]
                                            : ""
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {jackpotConfigs.map(
                                          config =>
                                            config.country === "cd" && (
                                              <option
                                                key={
                                                  config.game_jackpot_config_id
                                                }
                                                value={
                                                  config.game_jackpot_config_id
                                                }
                                              >
                                                {config.jackpot_config_name}
                                              </option>
                                            )
                                        )}
                                      </Input>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {isGlobal && (
                              <>
                                <div className="mb-3">
                                  <Row>
                                    <Col lg={6}>
                                      <div className="mb-3">
                                        <Label>
                                          <b>Nigeria Banner</b>
                                        </Label>
                                        {editBingoProfile && (
                                          <div className="mb-2">
                                            <img
                                              style={{ width: 200 }}
                                              src={
                                                editBingoProfile
                                                  ? JSON.parse(
                                                      editBingoProfile.banner
                                                    )["ng"]
                                                  : ""
                                              }
                                              alt="bingo_banner"
                                            />
                                          </div>
                                        )}
                                        <Input
                                          type="file"
                                          onChange={e =>
                                            handleFileInput(
                                              e,
                                              setSelectedNgFile
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputState">
                                          <b>Ghana Banner</b>
                                        </Label>
                                        {editBingoProfile && (
                                          <div className="mb-2">
                                            <img
                                              style={{ width: 200 }}
                                              src={
                                                editBingoProfile
                                                  ? JSON.parse(
                                                      editBingoProfile.banner
                                                    )["gh"]
                                                  : ""
                                              }
                                              alt="bingo_banner"
                                            />
                                          </div>
                                        )}
                                        <Input
                                          type="file"
                                          onChange={e =>
                                            handleFileInput(
                                              e,
                                              setSelectedGhFile
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputState">
                                          <b>Zambia Banner</b>
                                        </Label>
                                        {editBingoProfile && (
                                          <div className="mb-2">
                                            <img
                                              style={{ width: 200 }}
                                              src={
                                                editBingoProfile
                                                  ? JSON.parse(
                                                      editBingoProfile.banner
                                                    )["zm"]
                                                  : ""
                                              }
                                              alt="bingo_banner"
                                            />
                                          </div>
                                        )}
                                        <Input
                                          type="file"
                                          onChange={e =>
                                            handleFileInput(
                                              e,
                                              setSelectedZmFile
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputState">
                                          <b>DR Congo Banner</b>
                                        </Label>
                                        {editBingoProfile && (
                                          <div className="mb-2">
                                            <img
                                              style={{ width: 200 }}
                                              src={
                                                editBingoProfile
                                                  ? JSON.parse(
                                                      editBingoProfile.banner
                                                    )["cd"]
                                                  : ""
                                              }
                                              alt="bingo_banner"
                                            />
                                          </div>
                                        )}
                                        <Input
                                          type="file"
                                          onChange={e =>
                                            handleFileInput(
                                              e,
                                              setSelectedCdFile
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            )}
                            <div className="mb-3">
                              <Row>
                                {!isGlobal && (
                                  <Col lg={6}>
                                    <Label htmlFor="formrow-InputState">
                                      <b>Banner Image</b>
                                    </Label>
                                    <Input
                                      type="file"
                                      onChange={e =>
                                        handleFileInput(e, setSelectedFile)
                                      }
                                    />
                                    <Progress
                                      bar
                                      color="success"
                                      value={progress}
                                    />
                                  </Col>
                                )}
                                {isGlobal && (
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputState">
                                        <b>Bingo Country</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        name="country_profile_id"
                                        id="country_profile_id"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values
                                            .country_profile_id || ""
                                        }
                                        invalid={
                                          validation.touched
                                            .country_profile_id &&
                                          validation.errors.country_profile_id
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {localeProfiles.map(locale => (
                                          <option
                                            key={locale.country_profile_id}
                                            value={locale.country_profile_id}
                                          >
                                            {locale.country}
                                          </option>
                                        ))}
                                      </Input>
                                      {validation.touched.country_profile_id &&
                                      validation.errors.country_profile_id ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.country_profile_id}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                )}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="game_jackpot_config_id">
                                      <b> Minimum Players</b>
                                    </Label>
                                    <Input
                                      type="number"
                                      name="min_players"
                                      id="min_players"
                                      placeholder="min players"
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.min_players || ""
                                      }
                                      invalid={
                                        validation.touched.min_players &&
                                        validation.errors.min_players
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.min_players &&
                                    validation.errors.min_players ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.min_players}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            <div className="mb-3">
                              <Row>
                                <Col lg={6}>
                                  <Label className="form-label">
                                    <b>Bingo Tag</b>
                                  </Label>
                                  <Input
                                    name="tag_label"
                                    id="tag_label"
                                    type="select"
                                    placeholder="Enter game tag label"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.tag_label || ""}
                                    invalid={
                                      validation.touched.tag_label &&
                                      validation.errors.tag_label
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="">Select one...</option>
                                    <option value="none">None</option>
                                    <option value="featured">Featured</option>
                                  </Input>
                                  {validation.touched.tag_label &&
                                  validation.errors.tag_label ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.tag_label}
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                                <Col span={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-InputState">
                                      <b>Bingo Status</b>
                                    </Label>
                                    <Input
                                      type="select"
                                      name="game_status"
                                      id="game_status"
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.game_status || ""
                                      }
                                      invalid={
                                        validation.touched.game_status &&
                                        validation.errors.game_status
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value="">Select one...</option>
                                      <option value="active">Active</option>
                                      <option value="disabled">Disabled</option>
                                    </Input>
                                    {validation.touched.game_status &&
                                    validation.errors.game_status ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.game_status}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            <div className="mb-3">
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="min_tickets">
                                      <b> Minimum Tickets</b>
                                    </Label>
                                    <Input
                                      type="number"
                                      name="min_tickets"
                                      id="min_tickets"
                                      placeholder="min tickets"
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.min_tickets || ""
                                      }
                                      invalid={
                                        validation.touched.min_tickets &&
                                        validation.errors.min_tickets
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.min_tickets &&
                                    validation.errors.min_tickets ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.min_tickets}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>

                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="max_tickets">
                                      <b> Maximum Tickets Per Player</b>
                                    </Label>
                                    <input
                                      type="text"
                                      name="max_tickets"
                                      id="max_tickets"
                                      min={0}
                                      placeholder="max tickets"
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.max_tickets || ""
                                      }
                                      invalid={
                                        validation.touched.max_tickets &&
                                        validation.errors.max_tickets
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.max_tickets &&
                                    validation.errors.max_tickets ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.max_tickets}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <Col lg={6}>
                              <Col span={6}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-InputState">
                                    <b>Auto rotate</b>
                                  </Label>
                                  <Input
                                    type="checkbox"
                                    checked={autoRotate}
                                    name="auto_rotate_min_players"
                                    id="auto_rotate_min_players"
                                    className="form-control"
                                    onChange={e => {
                                      console.log(e.target.checked);
                                      setAutoRotate(e.target.checked);
                                      validation.handleChange(e);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values
                                        .auto_rotate_min_players ||
                                      autoRotate ||
                                      false
                                    }
                                    invalid={
                                      validation.touched
                                        .auto_rotate_min_players &&
                                      validation.errors.auto_rotate_min_players
                                        ? true
                                        : false
                                    }
                                  ></Input>
                                  {validation.touched.auto_rotate_min_players &&
                                  validation.errors.auto_rotate_min_players ? (
                                    <FormFeedback type="invalid">
                                      {
                                        validation.errors
                                          .auto_rotate_min_players
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              {(editBingoProfile?.auto_rotate_min_players ||
                                autoRotate) && (
                                <>
                                  <Col span={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputState">
                                        <b>Auto rotate min</b>
                                      </Label>
                                      <Input
                                        type="number"
                                        name="auto_rotate_min_players_min"
                                        id="auto_rotate_min_players_min"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values
                                            .auto_rotate_min_players_min || ""
                                        }
                                        invalid={
                                          validation.touched.game_status &&
                                          validation.errors.game_status
                                            ? true
                                            : false
                                        }
                                      ></Input>
                                      {validation.touched
                                        .auto_rotate_min_players_min &&
                                      validation.errors
                                        .auto_rotate_min_players_min ? (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors
                                              .auto_rotate_min_players_min
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col span={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputState">
                                        <b>Auto rotate max</b>
                                      </Label>
                                      <Input
                                        type="number"
                                        name="auto_rotate_min_players_max"
                                        id="auto_rotate_min_players_max"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values
                                            .auto_rotate_min_players_max || ""
                                        }
                                        invalid={
                                          validation.touched
                                            .auto_rotate_min_players_max &&
                                          validation.errors
                                            .auto_rotate_min_players_max
                                            ? true
                                            : false
                                        }
                                      ></Input>
                                      {validation.touched
                                        .auto_rotate_min_players_max &&
                                      validation.errors
                                        .auto_rotate_min_players_max ? (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors
                                              .auto_rotate_min_players_max
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </>
                              )}
                            </Col>
                            {!isGlobal && (
                              <div className="mb-3">
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputState">
                                        <b>Bingo Country</b>
                                      </Label>
                                      <Input
                                        type="select"
                                        name="country_profile_id"
                                        id="country_profile_id"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values
                                            .country_profile_id || ""
                                        }
                                        invalid={
                                          validation.touched
                                            .country_profile_id &&
                                          validation.errors.country_profile_id
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="">Select one...</option>
                                        {localeProfiles.map(locale => (
                                          <option
                                            key={locale.country_profile_id}
                                            value={locale.country_profile_id}
                                          >
                                            {locale.country}
                                          </option>
                                        ))}
                                      </Input>
                                      {validation.touched.country_profile_id &&
                                      validation.errors.country_profile_id ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.country_profile_id}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  {selectedFile && (
                                    <Col lg={6}>
                                      <div>
                                        <Label htmlFor="formrow-InputState">
                                          <b>Bingo Banner</b>
                                        </Label>
                                      </div>
                                      <div className="mb-3">
                                        <img
                                          style={{ width: 200 }}
                                          src={selectedFile}
                                          alt="bingo_banner"
                                        />
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={confirmCancel}
                        className="btn btn-danger"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <Button
                        type="button"
                        onClick={validation.handleSubmit}
                        disabled={createBingoProfileLoading || uploadLoading}
                        className="btn btn-success "
                        // disabled={editBingoProfile}
                      >
                        {editBingoProfile ? "Edit" : "Create"} Bingo
                      </Button>
                    </div>
                  </Modal>
                  {openWhiteList && <WhiteList
                    openWhiteList={openWhiteList}
                    editBingoProfile={editBingoProfile}
                    setOpenWhiteList={setOpenWhiteList}
                  />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateBingos);
