import { combineReducers } from "redux";

/*import reducers */

//Admin

import {
  signinSlice,
  createRoleSlice,
  createPermissionSlice,
  getAllAdminSlice,
  getAllRoleSlice,
  updateAdminSlice,
  registerAdminSlice,
} from "./admin";

/* end of import reducers */

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const profilePersistConfig = {
  key: "authUser",
  storage,
  whitelist: ["user"],
};

export const reducer = combineReducers({
  // signin: signinSlice.reducer,
  registerAdmin: registerAdminSlice.reducer,
  updateAdmin:updateAdminSlice.reducer,
  createRole: createRoleSlice.reducer,
  createPermission: createPermissionSlice.reducer,
  getAllAdmins: getAllAdminSlice.reducer,
  getAllRoles: getAllRoleSlice.reducer,
  signin: persistReducer(profilePersistConfig, signinSlice.reducer),
});
