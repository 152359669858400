import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { withRouter, Link, useHistory } from "react-router-dom";
import { logOut } from "../../../redux/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  signinSelector,
} from "../../../redux/reducers/admin/signin";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = () => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { adminDetails } = useSelector(signinSelector);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={adminDetails?.profile_picture || user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {adminDetails?.username || "Admin"}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Profile
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link
            to="/#"
            className="dropdown-item"
            onClick={() => {
              dispatch(logOut(history));
              dispatch(clearState());
            }}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(ProfileMenu);
