export default class Commerce {
  constructor(client) {
    this.client = client;
  }

  // Withdrawals
  getAllWithdrawals(limit, page, search) {
    return this.client.get(
      `/user/all-wallet-withdrawals?limit=${limit}&page=${page}${search}`
    );
  }
  fetchDeposit(payload) {
    return this.client.get(`user/withdrawal-request/${payload}`);
  }
  getAllWithdrawalRequests(limit, page, search) {
    return this.client.get(
      `/user/all-withdrawal-requests?limit=${limit}&page=${page}${search}`
    );
  }
  getAllDeposits(limit, page, search) {
    return this.client.get(
      `/user/all-wallet-deposits?limit=${limit}&page=${page}${search}`
    );
  }
  getAllSingleUserDeposits(id) {
    return this.client.get(
      `/user/all-wallet-deposits?limit=100000000&page=1&userId=${id}`
    );
  }

  getAllSingleUserSlots(id) {
    return this.client.get(
      `/user/all-slots?limit=100000000&page=1&userId=${id}`
    );
  }

  getAllSingleUserWithdrawals(id) {
    return this.client.get(
      `/user/all-wallet-withdrawals?limit=100000000&page=1&userId=${id}`
    );
  }
  getAllSingleUserWithdrawalRequests(id) {
    return this.client.get(
      `/user/all-withdrawal-requests?limit=100000000&page=1&userId=${id}`
    );
  }

  getAllSlots(limit, page, search) {
    return this.client.get(
      `/user/all-slot-transactions?limit=${limit}&page=${page}${search}`
    );
  }
  getAllSingleUserSlots(id) {
    return this.client.get(
      `/user/all-slot-transactions?limit=100000000&page=1&userId=${id}`
    );
  }

  verifyDRCPayment(payload) {
    return this.client.post("/mock-unipesa-webhook", payload);
  }

  //users
  getAllUsers(limit, page, search) {
    return this.client.get(
      `/user/all-users-profile?limit=${limit}&page=${page}${search}`
    );
  }
  getAllUserWallets(limit, page, search) {
    return this.client.get(
      `/user/all-users-wallets?limit=${limit}&page=${page}${search}`
    );
  }
  updateUserStatus(id, payload) {
    return this.client.patch(`/user/change-user-status/${id}`, payload);
  }
  updateUserWallet(payload) {
    return this.client.post(`/user/update-wallet`, payload);
  }
  revertUserDeposit(payload) {
    return this.client.post(`/user/reverse-deposit`, payload);
  }

  // Payment methods
  getAllLocaleProfiles() {
    return this.client.get("/locale/profile/all");
  }
  createLocaleProfile(payload) {
    return this.client.post("/locale/profile", payload);
  }
  updateLocaleProfile(id, payload) {
    return this.client.patch(`/locale/profile/${id}`, payload);
  }
}
