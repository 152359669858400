import React, { useEffect, useMemo, useState } from "react";
import api from "../../../api";
import { UncontrolledTooltip, Alert, Button } from "reactstrap";
import { Card } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "../GroupsTable";
import CustomModal from "../../../reusables/CustomModal";
import { Tabs } from "antd";

const { TabPane } = Tabs;
const LiveBingos = () => {
  const [liveBingos, setLiveBingos] = useState({});
  const [getLiveBingosLoading, setGetLiveBingosLoading] = useState(false);
  const [getLiveBingosError, setGetLiveBingosError] = useState(null);
  const [pageCount, setPageCount] = useState(4);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");
  const [cancelLiveBingoLoading, setCancelLiveBingoLoading] = useState(false);
  const [cancelLiveBingoError, setCancelLiveBingoError] = useState(false);
  const [customModal, setCustomModal] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Bingo Name",
        accessor: "game_name",
      },
      {
        Header: "Bingo Type",
        accessor: "game_type",
      },
      {
        Header: "Bingo Group",
        accessor: "group_name",
      },
      {
        Header: "Bingo Duration",
        accessor: "game_duration",
      },
      {
        Header: "Columns",
        accessor: "column_count",
      },
      {
        Header: "Rows",
        accessor: "row_count",
      },
      {
        Header: "Start Date",
        accessor: "game_start",
        Cell: data => new Date(data.value).toLocaleString(),
      },
      {
        Header: "Status",
        accessor: "game_status",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "Actions",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Button
                to="#"
                className="text-danger btn-danger"
                disabled={
                  cellProps.row.original.game_status === "canceled" ||
                  cellProps.row.original.game_status === "completed"
                }
                onClick={() => {
                  const data = cellProps.row.original;
                  handleCancelBingo(data.game_events_id);
                }}
              >
                <i className="mdi mdi-close font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Cancel
                </UncontrolledTooltip>
              </Button>
            </div>
          );
        },
      },
    ],

    []
  );

  const handleNextPage = () => {
    setPageIndex(pageIndex => pageIndex + 1);
  };
  const handlePrevPage = () => {
    setPageIndex(pageIndex => pageIndex - 1);
  };
  const setHandleReset = () => {
    setPageIndex(1);
  };
  const getAllLiveBingos = (filter = "") => {
    setGetLiveBingosLoading(true);
    api.games
      .getAllBingoEvents(pageSize, pageIndex, filter)
      .then(res => {
        setGetLiveBingosLoading(false);

        if (res.status === 200) {
          console.log(res.data.data);
          setLiveBingos(res.data.data);
          setPageCount(res.data.data.totalPages);
        }
      })
      .catch(err => {
        setGetLiveBingosLoading(false);
        setGetLiveBingosError(err.response?.data.message || err.message);
      });
  };

  const cancelLiveBingo = id => {
    setCancelLiveBingoLoading(true);
    api.games
      .cancelBingoEvent(id)
      .then(res => {
        setCancelLiveBingoLoading(false);
        if (res.status === 200) {
          getAllLiveBingos();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo Event Cancelled Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCancelLiveBingoLoading(false);
        setCancelLiveBingoError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    getAllLiveBingos(filters);
    console.log(filters);
  }, [pageIndex, pageSize, filters]);

  useEffect(() => {
    getLiveBingosError &&
      setTimeout(() => {
        setGetLiveBingosError(null);
      }, 3000);
  });

  const handleCancelBingo = id => {
    setCustomModal(
      <CustomModal
        title="Cancel Bingo Event??"
        loading={cancelLiveBingoLoading}
        content={
          <p>
            {cancelLiveBingoError && (
              <Alert
                color="danger"
                fade={true}
                isOpen={!!cancelLiveBingoError}
                toggle={() => setCancelLiveBingoError(null)}
              >
                {cancelLiveBingoError}
              </Alert>
            )}
            Do you want to cancel this game event?
          </p>
        }
        onOk={() => {
          cancelLiveBingo(id);
        }}
        okText="Yes"
        closeModal={() => setCustomModal(false)}
        cancelText="No"
        visible={true}
      />
    );
  };

  //meta title
  document.title = "Bingo Events";

  return (
    <div className="page-content">
      {getLiveBingosError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getLiveBingosError}
          toggle={() => setGetLiveBingosError(null)}
        >
          {getLiveBingosError}
        </Alert>
      )}
      {customModal}
      <div className="container-fluid">
        <Breadcrumbs title="Bingos" breadcrumbItem="Bingo Events" />

        {Object.keys(liveBingos).length > 0 ? (
          <Tabs onChange={setHandleReset} defaultActiveKey="1">
            <TabPane tab="Live Events" key="1">
              <Card loading={getLiveBingosLoading}>
                <TableContainer
                  columns={columns}
                  data={liveBingos.live.events}
                  addButtonText={null}
                  search={getAllLiveBingos}
                  setShowDetailsModal={() => {}}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  customPageCount={liveBingos.live.totalPages}
                  customCanPrevPage={{
                    exists: true,
                    value: pageCount == 1 || !pageIndex == 1,
                  }}
                  customPrevPage={handlePrevPage}
                  customNextPage={handleNextPage}
                  customCanNextPage={{
                    exists: true,
                    value: pageIndex !== pageCount,
                  }}
                  setFilters={setFilters}
                  searchValue={filters}
                  setPageIndex={setPageIndex}
                  customSetPageSize={setPageSize}
                  controlledPageIndex={pageIndex}
                  customPageSize={pageSize}
                  className="custom-header-css"
                  addNewGroup={() => {}}
                />
              </Card>
            </TabPane>
            <TabPane tab="Completed Events" key="2">
              <Card loading={getLiveBingosLoading}>
                <TableContainer
                  columns={columns}
                  data={liveBingos.completed.events}
                  addButtonText={null}
                  setShowDetailsModal={() => {}}
                  isGlobalFilter={false}
                  search={getAllLiveBingos}
                  isAddOptions={false}
                  customPageCount={liveBingos.completed.totalPages}
                  customCanPrevPage={{
                    exists: true,
                    value: pageCount == 1 || !pageIndex == 1,
                  }}
                  customPrevPage={handlePrevPage}
                  customNextPage={handleNextPage}
                  customCanNextPage={{
                    exists: true,
                    value: pageIndex !== pageCount,
                  }}
                  setFilters={setFilters}
                  setPageIndex={setPageIndex}
                  customSetPageSize={setPageSize}
                  controlledPageIndex={pageIndex}
                  customPageSize={pageSize}
                  searchValue={filters}
                  className="custom-header-css"
                  addNewGroup={() => {}}
                />
              </Card>
            </TabPane>
            <TabPane tab="Canceled Events" key="3">
              <Card loading={getLiveBingosLoading}>
                <TableContainer
                  columns={columns}
                  data={liveBingos.canceled.events}
                  addButtonText={null}
                  setShowDetailsModal={() => {}}
                  isGlobalFilter={false}
                  search={getAllLiveBingos}
                  isAddOptions={false}
                  customPageCount={liveBingos.canceled.totalPages}
                  customCanPrevPage={{
                    exists: true,
                    value: pageCount == 1 || !pageIndex == 1,
                  }}
                  customPrevPage={handlePrevPage}
                  customNextPage={handleNextPage}
                  customCanNextPage={{
                    exists: true,
                    value: pageIndex !== pageCount,
                  }}
                  setFilters={setFilters}
                  setPageIndex={setPageIndex}
                  customSetPageSize={setPageSize}
                  controlledPageIndex={pageIndex}
                  searchValue={filters}
                  customPageSize={pageSize}
                  className="custom-header-css"
                  addNewGroup={() => {}}
                />
              </Card>
            </TabPane>
            <TabPane tab="Upcoming Events" key="4">
              <Card loading={getLiveBingosLoading}>
                <TableContainer
                  columns={columns}
                  data={liveBingos.upcoming.events}
                  addButtonText={null}
                  setShowDetailsModal={() => {}}
                  isGlobalFilter={false}
                  searchValue={filters}
                  isAddOptions={false}
                  search={getAllLiveBingos}
                  customPageCount={liveBingos.upcoming.totalPages}
                  customCanPrevPage={{
                    exists: true,
                    value: pageCount == 1 || !pageIndex == 1,
                  }}
                  customPrevPage={handlePrevPage}
                  customNextPage={handleNextPage}
                  customCanNextPage={{
                    exists: true,
                    value: pageIndex !== pageCount,
                  }}
                  setFilters={setFilters}
                  setPageIndex={setPageIndex}
                  customSetPageSize={setPageSize}
                  controlledPageIndex={pageIndex}
                  customPageSize={pageSize}
                  className="custom-header-css"
                  addNewGroup={() => {}}
                />
              </Card>
            </TabPane>
          </Tabs>
        ) : (
          <p>loading...</p>
        )}
      </div>
    </div>
  );
};
export default LiveBingos;
