import React, { useEffect, useMemo, useState } from "react";
import api from "../../api";
import { Alert } from "reactstrap";
import { Card } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../Withdrawals/GroupsTable";
import CustomModal from "../../reusables/CustomModal";
import { CSVLink } from "react-csv";

const Slots = () => {
  const [slots, setSlots] = useState([]);
  const [getSlotsLoading, setGetSlotsLoading] = useState(false);
  const [getSlotsError, setGetSlotsError] = useState(null);
  const [customModal, setCustomModal] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone No.",
        accessor: "phone_number",
      },
      {
        Header: "Game Name",
        accessor: "gameName",
      },
      {
        Header: "Type",
        accessor: "roundType",
      },
      {
        Header: "Bet",
        accessor: "bet",
        Cell: data => data.value / 100,
      },
      {
        Header: "Wins",
        accessor: "wins",
        Cell: data => data.value / 100,
      },
      {
        Header: "Base Amount",
        accessor: "base_amount",
        Cell: data => data.value / 100,
      },
      {
        Header: "Local Amount",
        accessor: "local_amount",
        Cell: data => (data.value / 100).toLocaleString(),
      },
      {
        Header: "Date",
        accessor: "created",
        Cell: data => new Date(Number(data.value)).toLocaleString(),
      },
    ],

    []
  );

  const openDepositDetailsModal = slot => {
    setCustomModal(
      <CustomModal
        title={<b>Slot Details</b>}
        content={
          <div>
            <p>
              <b>Partner Transaction ID:</b> {slot.partnerTrx_id}
            </p>
            <p>
              <b>Genesis Transaction ID:</b> {slot.genesisTrx_id}
            </p>
            <p>
              <b>Round ID:</b> {slot.roundId}
            </p>
            <p>
              <b>Round Type:</b> {slot.roundType}
            </p>
            <p>
              <b>Base Amount:</b> {slot.base_amount / 100}
            </p>
            <p>
              <b>Local Amount:</b> {slot.local_amount / 100}
            </p>
            <p>
              <b>Bet:</b> {slot.bet / 100}
            </p>
            <p>
              <b>Wins:</b> {slot.wins / 100}
            </p>
            <p>
              <b>Date:</b> {new Date(Number(slot.created)).toString()}
            </p>
            <p>
              <b>Country:</b> {slot.user_profile.locale_country_profile.country}
            </p>

            <p>
              <b>User Name:</b> {slot.user_profile.first_name}{" "}
              {slot.user_profile.last_name}
            </p>
            <p>
              <b>User Email:</b> {slot.user_profile.email}
            </p>
            <p>
              <b>Phone Number:</b> {slot.user_profile.phone_num}
            </p>
            <p>
              <b>Account Level:</b> {slot.user_profile.account_level}
            </p>
            <p>
              <b>Account Status:</b> {slot.user_profile.account_status}
            </p>
            <p>
              <b>Is Verified:</b> {slot.user_profile.is_verified.toString()}
            </p>
            <p>
              <b>User ID:</b> {slot.user_profile.user_id}
            </p>
          </div>
        }
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const getAllSlots = (limit, page, search) => {
    setGetSlotsLoading(true);
    api.commerce
      .getAllSlots(limit, page, search)
      .then(res => {
        setGetSlotsLoading(false);
        if (res.status === 200) {
          setSlots(res.data.data.transactions);
          setPageCount(res.data.data.totalPages);
        }
      })
      .catch(err => {
        setGetSlotsLoading(false);
        setGetSlotsError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    getSlotsError &&
      setTimeout(() => {
        setGetSlotsError(null);
      }, 3000);
  });

  const acceptedSlotsHeaders = [
    {
      label: "Game Name",
      key: "gameName",
    },
    {
      label: "User",
      key: "user",
    },
    {
      label: "Bet",
      key: "bet",
    },
    {
      label: "Wins",
      key: "wins",
    },
    {
      label: "Base Amount",
      key: "base_amount",
    },
    {
      label: "Local Amount",
      key: "local_amount",
    },
    {
      label: "Type",
      key: "roundType",
    },
    {
      label: "Date",
      key: "created",
    },
  ];

  const exportButton = (
    <CSVLink
      id="csvDownloadBtn"
      key="all-users-download"
      data={slots.map(slot => ({
        ...slot,
        user: slot.user_profile.first_name + " " + slot.user_profile.last_name,
        created: new Date(Number(slot.created)).toLocaleString(),
        bet: slot.bet / 100,
        wins: slot.wins / 100,
        local_amount: slot.local_amount / 100,
        base_amount: slot.base_amount / 100,
      }))}
      headers={acceptedSlotsHeaders}
      filename={`all-slots-data-${localStorage.getItem("country")}.csv`}
      target="_blank"
    >
      <button className="btn btn-primary">Export Slots</button>
    </CSVLink>
  );

  useEffect(() => {
    getAllSlots(pageSize, pageIndex + 1, "");
  }, [pageIndex, pageSize]);

  useEffect(() => {
    getAllSlots(pageSize, 1, filters);
  }, [filters]);

  //meta title
  document.title = "Slots";

  return (
    <div className="page-content">
      {getSlotsError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getSlotsError}
          toggle={() => setGetSlotsError(null)}
        >
          {getSlotsError}
        </Alert>
      )}
      {customModal}
      <div className="container-fluid">
        <Breadcrumbs title="Slots" breadcrumbItem="Slots History" />
        <Card loading={getSlotsLoading}>
          <TableContainer
            columns={columns}
            data={slots.map(slot => ({
              ...slot,
              email: slot.user_profile.email,
              phone_number: slot.user_profile.phone_num,
            }))}
            addButtonText={null}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={pageSize}
            setShowDetailsModal={val => openDepositDetailsModal(val.original)}
            className="custom-header-css"
            addNewGroup={() => {}}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            controlledPageIndex={pageIndex}
            pageCount={pageCount}
            exportButton={exportButton}
          />
        </Card>
      </div>
    </div>
  );
};
export default Slots;
