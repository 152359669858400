export default class Bingos {
  constructor(client) {
    this.client = client;
  }

  // Bingo Groups
  getAllBingoGroups() {
    return this.client.get("/games/all-game-group");
  }
  createBingoGroup(payload) {
    return this.client.post("/games/game-group", payload);
  }

  getAllVipProfiles() {
    return this.client.get("/games/vip-groups");
  }

  getSingleVipProfile(game_id) {
    return this.client.get(`/games/vip-games/${game_id}`);
  }

  updateSingleVipProfile(payload) {
    const game_id = payload.game_profile_id;
    delete payload.game_profile_id;
    return this.client.patch(`games/vip-games/${game_id}`, payload);
  }

  createSingleVipProfile(payload) {
    return this.client.post(`games/vip-games/`, payload);
  }
  updateBingoGroup(payload) {
    const group_id = payload.group_id;
    delete payload.group_id;
    return this.client.patch(`/games/game-group/${group_id}`, payload);
  }
  deleteBingoGroup(group_id) {
    return this.client.delete(`/games/game-group/${group_id}`);
  }

  getAllLiveBingos(size = 10, index = 1, filter = "") {
    return this.client.get(
      `/games/game-live-events-admin/&page=${index}&limit=${size}`
    );
  }
  getAllBingoEvents(size = 50, index = 1, filter = "") {
    console.log(filter);
    return filter && filter !== ""
      ? this.client.get(
          `/games/game-events-admin?&page=${index}&limit=${size}&string=${filter}`
        )
      : this.client.get(
          `/games/game-events-admin?&page=${index}&limit=${size}`
        );
  }
  cancelBingoEvent(id) {
    return this.client.patch(`games/game-events-manual-cancel-admin/${id}`);
  }

  // Bingo Types
  getAllBingoTypes() {
    return this.client.get("/games/all-game-type");
  }
  createBingoType(payload) {
    return this.client.post("/games/game-type", payload);
  }
  deleteBingoType(type_id) {
    return this.client.delete(`/games/game-type/${type_id}`);
  }
  updateBingoType(type_id, payload) {
    return this.client.patch(`/games/game-type/${type_id}`, payload);
  }

  // Bingo Profiles
  getAllBingoProfiles() {
    return this.client.get("/games/all-game-profile");
  }
  createBingoProfile(payload) {
    return this.client.post("/games/game-profile", payload);
  }
  updateBingoProfile(game_id, payload) {
    return this.client.patch(`/games/game-profile/${game_id}`, payload);
  }
  getBingoProfile(profile_id) {
    return this.client.get(`/games/game-profile/${profile_id}`);
  }
  deleteBingoProfile(profile_id) {
    return this.client.delete(`/games/game-profile/${profile_id}`);
  }

  // Bingo Jackpot Patterns
  getAllJackpotPatterns() {
    return this.client.get("/games/jackpot-pattern");
  }
  getJackpotPattern(pattern_id) {
    return this.client.get(`/games/jackpot-pattern/${pattern_id}`);
  }
  createJackpotPattern(payload) {
    return this.client.post("/games/jackpot-pattern", payload);
  }
  deleteJackpotPattern(pattern_id) {
    return this.client.delete(`/games/jackpot-pattern/${pattern_id}`);
  }
  updateJackpotPattern(pattern_id, payload) {
    return this.client.patch(`/games/jackpot-pattern/${pattern_id}`, payload);
  }

  // Bingo Jackpot Configurations
  getAllJackpotConfigs() {
    return this.client.get("/games/jackpot-config");
  }
  getJackpotConfig(config_id) {
    return this.client.get(`/games/jackpot-config/${config_id}`);
  }
  createJackpotConfig(payload) {
    return this.client.post("/games/jackpot-config", payload);
  }
  deleteJackpotConfig(config_id) {
    return this.client.delete(`/games/jackpot-config/${config_id}`);
  }
  updateJackpotConfig(config_id, payload) {
    return this.client.patch(`/games/jackpot-config/${config_id}`, payload);
  }

  // Bingo Calendars
  getAllBingoCalendars() {
    return this.client.get("/games/all-game-calendar");
  }
  getBingoCalendar(calendar_id) {
    return this.client.get(`/games/game-calendar/${calendar_id}`);
  }
  createBingoCalendar(payload) {
    return this.client.post("/games/game-calendar", payload);
  }
  deleteBingoCalendar(calendar_id) {
    return this.client.delete(`/games/game-calendar/${calendar_id}`);
  }
  updateBingoCalendar(calendar_id, payload) {
    return this.client.patch(`/games/game-calendar/${calendar_id}`, payload);
  }

  // Single Bingo Calendars
  getBingoCalendarSingle(calendar_id) {
    return this.client.get(`/games/game-calendar-single/${calendar_id}`);
  }
  createBingoCalendarSingle(payload) {
    return this.client.post("/games/game-calendar-single", payload);
  }
  deleteBingoCalendarSingle(calendar_id) {
    return this.client.delete(`/games/game-calendar-single/${calendar_id}`);
  }
  updateBingoCalendarSingle(calendar_id, payload) {
    return this.client.patch(
      `/games/game-calendar-single/${calendar_id}`,
      payload
    );
  }

  // Monthly Bingo Calendars
  getBingoCalendarMonthly(calendar_id) {
    return this.client.get(`/games/game-calendar-monthly/${calendar_id}`);
  }
  createBingoCalendarMonthly(payload) {
    return this.client.post("/games/game-calendar-monthly", payload);
  }
  deleteBingoCalendarMonthly(calendar_id) {
    return this.client.delete(`/games/game-calendar-monthly/${calendar_id}`);
  }
  updateBingoCalendarMonthly(calendar_id, payload) {
    return this.client.patch(
      `/games/game-calendar-monthly/${calendar_id}`,
      payload
    );
  }

  // Recurring Bingo Calendars
  getBingoCalendarRecurring(calendar_id) {
    return this.client.get(`/games/game-calendar-recur/${calendar_id}`);
  }
  createBingoCalendarRecurring(payload) {
    return this.client.post("/games/game-calendar-recur", payload);
  }
  deleteBingoCalendarRecurring(calendar_id) {
    return this.client.delete(`/games/game-calendar-recur/${calendar_id}`);
  }
  updateBingoCalendarRecurring(calendar_id, payload) {
    return this.client.patch(
      `/games/game-calendar-recur/${calendar_id}`,
      payload
    );
  }

  //Free Game settings

  getFreeGame(game_profile_id) {
    return this.client.get(`/games/free-game/${game_profile_id}`);
  }

  createFreegame(payload) {
    return this.client.post(`/games/free-game`, payload);
  }

  updateFreegame(payload) {
    const gameId = payload?.free_game_id;
    delete payload.free_game_id;
    return this.client.patch(`/games/free-game/${gameId}`, payload);
  }

  deleteFreeGame(game_profile_id) {
    return this.client.delete(`/games/free-game/${game_profile_id}`);
  }

  //Whitelist

  AddToWhiteList(payload) {
    return this.client.post(`/games/free-game-whitelisting`, payload);
  }
  getWhiteList(game_profile_id) {
    return this.client.get(`/games/free-game-whitelisting/${game_profile_id}`);
  }
  deleteFromWhiteList(white_list_id) {
    return this.client.delete(`/games/free-game-whitelisting/${white_list_id}`);
  }
  // RTP Settings
  getAllBingoRTP() {
    return this.client.get("/games/all-rtp");
  }
  getBingoRTP(config_id) {
    return this.client.get(`/games/rtp/${config_id}`);
  }
  createBingoRTP(payload) {
    return this.client.post("/games/rtp", payload);
  }
  deleteBingoRTP(config_id) {
    return this.client.delete(`/games/rtp/${config_id}`);
  }
  updateBingoRTP(config_id, payload) {
    return this.client.put(`/games/rtp/${config_id}`, payload);
  }

  // Custom RTP Settings
  getAllCustomBingoRTP() {
    return this.client.get("/games/all-custom-rtp");
  }
  getCustomBingoRTP(config_id) {
    return this.client.get(`/games/custom-rtp/${config_id}`);
  }
  createCustomBingoRTP(payload) {
    return this.client.post("/games/custom-rtp", payload);
  }
  deleteCustomBingoRTP(config_id) {
    return this.client.delete(`/games/custom-rtp/${config_id}`);
  }
  updateCustomBingoRTP(config_id, payload) {
    return this.client.put(`/games/custom-rtp/${config_id}`, payload);
  }

  // Game Breaks
  getAllGameBreaks(config_id) {
    return this.client.get(`/games/all-game-break/${config_id}`);
  }
  createGameBreak(payload) {
    return this.client.post("/games/game-break", payload);
  }
  deleteGameBreak(config_id) {
    return this.client.delete(`/games/game-break/${config_id}`);
  }
  updateGameBreak(config_id, payload) {
    return this.client.patch(`/games/game-break/${config_id}`, payload);
  }
}
