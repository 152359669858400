import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Alert,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import { Card, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  getAllRoles,
  createRole,
  createPermission,
} from "../../redux/actions/admin";
import {
  getRolesSelector,
  clearState as clearGetRoleState,
} from "../../redux/reducers/admin/getAllRoles";
import {
  createRoleSelector,
  clearState as clearCreateRoleState,
} from "../../redux/reducers/admin/createRole";

import CustomModal from "../../reusables/CustomModal";

import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Roles = () => {
  //meta title
  document.title = "Roles";

  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [editRole, setEditRole] = useState(null);
  const [customModal, setCustomModal] = useState(null);
  const [addPermissionModalOpen, setAddPermissionModalOpen] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState(null);
  const [permissionError, setPermissionError] = useState(null);

  useEffect(() => {
    getRolesError &&
      setTimeout(() => {
        dispatch(clearGetRoleState());
      }, 3000);
    createRoleError &&
      setTimeout(() => {
        dispatch(clearCreateRoleState());
      }, 3000);
  });

  const {
    allRoles,
    errors: getRolesError,
    loading: getRolesLoading,
  } = useSelector(getRolesSelector);
  const {
    loading: createRoleLoading,
    errors: createRoleError,
    success: createRoleSuccess,
  } = useSelector(createRoleSelector);

  const roleObj = {};
  for (let role of allRoles) {
    roleObj[role.role_id] = role;
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      role: editRole?.role || "",
    },
    validationSchema: Yup.object({
      role: Yup.string().required("Please enter role name"),
    }),
    onSubmit: values => {
      dispatch(createRole(values));
    },
  });

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "role",
        filterable: true,
      },
      {
        Header: "Permissions",
        accessor: "permissions",
        filterable: true,
        Cell: data =>
          data.value ? data.value.map(val => <p key={val}>{val}</p>) : "None",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterable: true,
        Cell: data => new Date(data.value).toLocaleString(),
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  setOpenModal(true);
                  setEditRole(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleDeleteAdmin(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const permissionsCol = [
    {
      label: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];

  const closeModal = () => {
    setEditRole(null);
    setOpenModal(false);
  };

  useEffect(() => {
    createRoleSuccess &&
      setCustomModal(
        <CustomModal
          title="Role Added Successfully!"
          onOk={() => {
            validation.handleReset();
            dispatch(getAllRoles());
            closeModal();
            setCustomModal(false);
          }}
          okText="Okay"
          closeModal={() => {
            validation.handleReset();
            setCustomModal(false);
          }}
          visible={true}
        />
      );
  }, [createRoleSuccess]);

  const handleDeleteRole = role => {
    setCustomModal(
      <CustomModal
        title="Delete Role?"
        content={<p>Do you want to delete this role?</p>}
        onOk={() => {
          setCustomModal(false);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const handleAddPermission = () => {
    if (!selectedPermissions)
      return setPermissionError("Select permissions to continue");
    const payload = {
      role_id: editRole.role_id,
      perms: {},
    };
    for (let perm of selectedPermissions) {
      payload["perms"][perm.value] = true;
    }
    dispatch(createPermission(payload));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {getRolesError && (
            <Alert
              color="danger"
              fade={true}
              isOpen={!!getRolesError}
              toggle={() => dispatch(clearGetRoleState())}
            >
              {getRolesError.message || getRolesError}
            </Alert>
          )}
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Admins" breadcrumbItem="Roles" />
          <Row>
            <Col lg="12">
              <Card loading={getRolesLoading}>
                {customModal}
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={allRoles}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    addButtonText="Add New Role"
                    handleUserClick={() => setOpenModal(true)}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                  <Modal
                    isOpen={addPermissionModalOpen}
                    toggle={() => {
                      setPermissionError(null);
                      setAddPermissionModalOpen(false);
                    }}
                  >
                    {permissionError && (
                      <Alert
                        color="danger"
                        fade={true}
                        isOpen={!!permissionError}
                        toggle={() => setPermissionError(null)}
                      >
                        {permissionError}
                      </Alert>
                    )}
                    <ModalHeader
                      toggle={() => {
                        setPermissionError(null);
                        setAddPermissionModalOpen(false);
                      }}
                      tag="h4"
                    >
                      Add Permission
                    </ModalHeader>
                    <ModalBody>
                      <div className="mb-4">
                        <Row>
                          <Select
                            isMulti={true}
                            onChange={val => setSelectedPermissions(val)}
                            options={allPermissions}
                            classNamePrefix="select2-selection"
                          />
                        </Row>
                      </div>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="button"
                              onClick={handleAddPermission}
                              className="btn btn-success save-user"
                              disabled={createRoleLoading}
                              loading={createRoleError ? "true" : "false"}
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>

                  <Modal isOpen={openModal} toggle={closeModal}>
                    {createRoleError && (
                      <Alert
                        color="danger"
                        fade={true}
                        isOpen={!!createRoleError}
                        toggle={() => dispatch(clearCreateRoleState())}
                      >
                        {createRoleError.message || createRoleError}
                      </Alert>
                    )}
                    <ModalHeader toggle={closeModal} tag="h4">
                      {!!editRole ? "Edit Role" : "Add Role"}
                    </ModalHeader>
                    <ModalBody>
                      <Form>
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="role"
                                id="role"
                                type="text"
                                placeholder="Enter role name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.role || ""}
                                invalid={
                                  validation.touched.role &&
                                  validation.errors.role
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.role &&
                              validation.errors.role ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.role}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        {editRole && (
                          <Table
                            title={() => "Role Permissions"}
                            dataSource={editRole.permissions || []}
                            columns={permissionsCol}
                          />
                        )}
                        {editRole && (
                          <div className="mt-2 mb-2">
                            <button
                              type="button"
                              onClick={() => {
                                setAddPermissionModalOpen(true);
                              }}
                              className="btn btn-primary"
                              loading={createRoleError ? "true" : "false"}
                            >
                              Add Permission
                            </button>
                          </div>
                        )}
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="button"
                                onClick={validation.handleSubmit}
                                className="btn btn-success save-user"
                                disabled={createRoleLoading}
                                loading={createRoleLoading ? "true" : "false"}
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Roles;

const allPermissions = [
  "Add Admins",
  "Delete Admins",
  "Add Admin Roles",
  "Delete Admin Roles",
  "Create Bingo Types",
  "Create Bingo Schhedules",
  "Create Bingo Rooms",
  "Create Bingo Profiles",
  "Create Bingo Tickets",
  "Create Jackpot Configs",
  "Create RTP Settings",
  "Approve Withdrawals",
  "See All Live Bingos",
  "See All Transactions",
  "See All Deposits",
  "See All Withdrawals",
  "See All Users",
  "Delete Users",
  "Approve User KYC",
].map(value => ({ label: value, value }));
