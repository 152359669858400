import { createSlice } from "@reduxjs/toolkit";
import { updateAdminDetails } from "../../actions/admin";

export const updateAdminSlice = createSlice({
  name: "updateAdmin",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
  },
  reducers: {
    clearState: state => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      console.log("state cleared")
      return state;
    },
  },
  extraReducers: {
    [updateAdminDetails.pending]: state => {
      state.loading = true;
    },
    [updateAdminDetails.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
    [updateAdminDetails.rejected]: (state, { payload }) => {
      state.errors = payload.error;
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});
export const { clearState } = updateAdminSlice.actions;

export const updateAdminSelector = state => state.updateAdmin;
