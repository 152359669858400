// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Col,
  Row,
  Label,
  ModalHeader,
  ModalBody,
  Alert,
  UncontrolledTooltip,
  FormFeedback,
  Form as ReactForm,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";

import { Card } from "antd";
import CustomModal from "../../../reusables/CustomModal";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import TableContainer from "../GroupsTable";

import api from "../../../api";

const BingoTicketsPlans = ({ ticketGroup, visible, closeModal }) => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [plans, setPlans] = useState([]);
  const [customModal, setCustomModal] = useState(null);
  const [getPlanError, setGetPlanError] = useState(null);
  const [editPlan, setEditPlan] = useState(null);
  const [createPlanError, setCreatePlanError] = useState(null);
  const [createTicketPlanLoading, setCreateTicketPlanLoading] = useState(false);
  const [getPlansLoading, setGetPlansLoading] = useState(false);
  
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ticket_plan_name: editPlan?.ticket_plan_name || "",
      default_flag: editPlan?.default_flag || false,
      number_of_tickets: editPlan?.number_of_tickets || "0",
      number_of_strips: editPlan?.number_of_strips || "0",
      ticket_price: editPlan?.ticket_price / 100 || "0",
      strip_price: editPlan?.strip_price / 100 || "0",
      discount_percent: editPlan?.discount_percent || "0",
      locale: "ng",
    },
    validationSchema: Yup.object({
      ticket_plan_name: Yup.string().required("Please Enter Ticket Group Name"),
      number_of_tickets: Yup.string().required(
        "Please enter number of tickets"
      ),
      number_of_strips: Yup.string().required("Please enter number of strips"),
      ticket_price: Yup.string().required("Please enter ticket price"),
      strip_price: Yup.string().required("Please enter strip price"),
      discount_percent: Yup.string().required(
        "Please enter discount percentage"
      ),
    }),
    onSubmit: values => {
      editPlan
        ? editTicketPlan(editPlan.ticket_plan_id, {
            ...values,
            ticket_group_id: ticketGroup.ticket_group_id,
          })
        : createTicketPlan({
            ...values,
            ticket_group_id: ticketGroup.ticket_group_id,
          });
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Plan Name",
        accessor: "ticket_plan_name",
      },
      {
        Header: "Number of Strips",
        accessor: "number_of_strips",
      },
      {
        Header: "Number of Tickets",
        accessor: "number_of_tickets",
      },
      {
        Header: "Strip Price",
        accessor: "strip_price",
        Cell: data => data.value / 100,
      },
      {
        Header: "Ticket Price",
        accessor: "ticket_price",
        Cell: data => data.value / 100,
      },
      {
        Header: "Discount Price",
        accessor: "discount_price",
        Cell: data => data.value / 100,
      },
      {
        Header: "Default",
        accessor: "default_flag",
        Cell: data => (data.value ? "True" : "False"),
      },
      {
        Header: "Actions",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  console.log(data)
                  setOpenAddGroupModal(true);
                  setEditPlan(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleDeletePlan(data);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const confirmCancel = () => {
    setCustomModal(
      <CustomModal
        title="Cancel Create Ticket?"
        content={
          <>
            <p>Do you want to stop creating ticket plans?</p>
            <p>Ticket plans might not be saved</p>
          </>
        }
        onOk={() => {
          setCustomModal(null);
          setOpenAddGroupModal(false);
          setEditPlan(null);
        }}
        okText="Okay"
        closeModal={() => {
          setCustomModal(null);
        }}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const createTicketPlan = payload => {
    setCreateTicketPlanLoading(true);
    api.tickets
      .createTicketPlan(payload)
      .then(res => {
        setCreateTicketPlanLoading(false);
        if (res.status === 201) {
          getAllTicketPlans(ticketGroup.ticket_group_id);
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Ticket Plan Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateTicketPlanLoading(false);
        setCreatePlanError(err.response?.data.message || err.message);
      });
  };
  const editTicketPlan = (id, payload) => {
    setCreateTicketPlanLoading(true);
    api.tickets
      .editTicketPlan(id, payload)
      .then(res => {
        setCreateTicketPlanLoading(false);
        if (res.status === 200) {
          getAllTicketPlans(ticketGroup.ticket_group_id);
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Ticket Plan Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateTicketPlanLoading(false);
        setCreatePlanError(err.response?.data.message || err.message);
      });
  };

  const getAllTicketPlans = group_id => {
    setGetPlansLoading(true);
    api.tickets
      .getAllTicketPlans(group_id)
      .then(res => {
        setGetPlansLoading(false);
        if (res.status === 200) {
          setPlans(res.data.data.plans);
        }
      })
      .catch(err => {
        setGetPlansLoading(false);
        setGetPlanError(err.response?.data.message || err.message);
      });
  };

  const deleteTicketPlan = id => {
    setGetPlansLoading(true);
    api.tickets
      .deleteTicketPlan(id)
      .then(res => {
        setGetPlansLoading(false);
        if (res.status === 200) {
          getAllTicketPlans(ticketGroup.ticket_group_id);
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Ticket Plan Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setGetPlanError(err.response?.data.message || err.message);
      });
  };

  const handleDeletePlan = data => {
    setCustomModal(
      <CustomModal
        title="Delete Ticket Plan?"
        content={<p>Do you want to delete this ticket plan?</p>}
        onOk={() => {
          deleteTicketPlan(data.ticket_plan_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  useEffect(() => {
    getAllTicketPlans(ticketGroup.ticket_group_id);
  }, []);

  useEffect(() => {
    createPlanError &&
      setTimeout(() => {
        setCreatePlanError(null);
      }, 3000);
    getPlanError &&
      setTimeout(() => {
        setGetPlanError(null);
      }, 3000);
  });
  //meta title
  document.title = "Bingo Tickets";

  return (
    <div className="page-content">
      <Modal isOpen={visible} size="lg" toggle={closeModal}>
        {getPlanError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!getPlanError}
            toggle={() => setGetPlanError(null)}
          >
            {getPlanError}
          </Alert>
        )}

        <ModalHeader toggle={closeModal} tag="h4">
          {ticketGroup.ticket_group_name}
        </ModalHeader>

        <ModalBody>
          <div className="container-fluid">
            <Card loading={getPlansLoading}>
              <TableContainer
                columns={columns}
                data={plans}
                addButtonText="Create Ticket Plan"
                isGlobalFilter={true}
                setShowDetailsModal={() => {}}
                isAddOptions={true}
                customPageSize={10}
                className="custom-header-css"
                addNewGroup={() => setOpenAddGroupModal(true)}
              />
            </Card>
          </div>

          <Modal isOpen={openAddGroupModal} size="lg" toggle={confirmCancel}>
            <ModalHeader toggle={confirmCancel} tag="h4">
              {editPlan ? "Edit" : "Create"} Ticket Plan
            </ModalHeader>
            {createPlanError && (
              <Alert
                color="danger"
                fade={true}
                isOpen={!!createPlanError}
                toggle={() => setCreatePlanError(null)}
              >
                {createPlanError}
              </Alert>
            )}

            <Card loading={getPlansLoading}>
              <ModalBody>
                {getPlanError && (
                  <Alert
                    color="danger"
                    fade={true}
                    isOpen={!!getPlanError}
                    toggle={() => setGetPlanError(null)}
                  >
                    {getPlanError}
                  </Alert>
                )}
                <ReactForm>
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Row>
                          <Col lg={6}>
                            <Label className="form-label">Plan Name</Label>
                            <Input
                              name="ticket_plan_name"
                              id="ticket_plan_name"
                              type="text"
                              placeholder="Enter ticket plan name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.ticket_plan_name ||""
                              }
                              invalid={
                                validation.touched.ticket_plan_name &&
                                validation.errors.ticket_plan_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.ticket_plan_name &&
                            validation.errors.ticket_plan_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ticket_plan_name}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                      <div className="mb-3">
                        <Row>
                          <Col lg={6}>
                            <Label className="form-label">
                              Number of Tickets
                            </Label>
                            <Input
                              name="number_of_tickets"
                              id="number_of_tickets"
                              type="number"
                              placeholder="Number of tickets"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.number_of_tickets || ""}
                              invalid={
                                validation.touched.number_of_tickets &&
                                validation.errors.number_of_tickets
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.number_of_tickets &&
                            validation.errors.number_of_tickets ? (
                              <FormFeedback type="invalid">
                                {validation.errors.number_of_tickets}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={6}>
                            <Label className="form-label">
                              Number of Strips
                            </Label>
                            <Input
                              name="number_of_strips"
                              id="number_of_strips"
                              type="number"
                              placeholder="Number of strips"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.number_of_strips || ""}
                              invalid={
                                validation.touched.number_of_strips &&
                                validation.errors.number_of_strips
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.number_of_strips &&
                            validation.errors.number_of_strips ? (
                              <FormFeedback type="invalid">
                                {validation.errors.number_of_strips}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>

                      <div className="mb-3">
                        <Row>
                          <Col lg={6}>
                            <Label className="form-label">Ticket Price</Label>
                            <Input
                              name="ticket_price"
                              id="ticket_price"
                              type="ticket_price"
                              placeholder="Enter ticket price"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.ticket_price || ""}
                              invalid={
                                validation.touched.ticket_price &&
                                validation.errors.ticket_price
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.ticket_price &&
                            validation.errors.ticket_price ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ticket_price}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={6}>
                            <Label className="form-label">Strip Price</Label>
                            <Input
                              name="strip_price"
                              id="strip_price"
                              type="strip_price"
                              placeholder="Enter strip price"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.strip_price || ""}
                              invalid={
                                validation.touched.strip_price &&
                                validation.errors.strip_price
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.strip_price &&
                            validation.errors.strip_price ? (
                              <FormFeedback type="invalid">
                                {validation.errors.strip_price}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                      <div className="mb-3">
                        <Row>
                          <Col lg={6}>
                            <Label className="form-label">Discount Price</Label>
                            <Input
                              name="discount_percent"
                              id="discount_percent"
                              type="discount_percent"
                              placeholder="Enter discount percent"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.discount_percent || ""}
                              invalid={
                                validation.touched.discount_percent &&
                                validation.errors.discount_percent
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.discount_percent &&
                            validation.errors.discount_percent ? (
                              <FormFeedback type="invalid">
                                {validation.errors.discount_percent}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={6}>
                            <div
                              style={{
                                display: "flex",
                                //alignItems: "center",
                                marginTop: "30px",
                              }}
                            >
                              <Label
                                style={{
                                  marginTop: "2px",
                                  marginRight: "10px",
                                }}
                                className="form-label"
                              >
                                {editPlan?.default_flag
                                  ? "Default"
                                  : "Mark as default"}
                              </Label>
                              <Input
                                style={{
                                  width: "1rem",
                                  height: "1rem",
                                }}
                                name="default_flag"
                                id="default_flag"
                                type="checkbox"
                                checked={validation.values.default_flag}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.default_flag||false}
                              />
                              {validation.touched.strip_price &&
                              validation.errors.strip_price ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.strip_price}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </ReactForm>
              </ModalBody>
            </Card>

            <div className="modal-footer">
              <button
                type="button"
                onClick={confirmCancel}
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                onClick={validation.handleSubmit}
                loading={createTicketPlanLoading ? "true" : "false"}
                type="button"
                className="btn btn-success "
              >
                {editPlan ? "Edit" : "Add"} Ticket Plan
              </button>
            </div>
          </Modal>
        </ModalBody>
        <div className="modal-footer">
          <button
            type="button"
            onClick={closeModal}
            className="btn btn-danger"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>

      {customModal}
    </div>
  );
};
BingoTicketsPlans.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default BingoTicketsPlans;
