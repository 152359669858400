import React, { useEffect, useMemo, useState } from "react";
import api from "../../api";
import { UncontrolledTooltip, Alert, Input, Button, Label } from "reactstrap";
import { Card, Tabs, Tooltip } from "antd";
import { Link } from "react-router-dom";
import TableContainer from "./GroupsTable";
import CustomModal from "../../reusables/CustomModal";

const country = localStorage.getItem("country") || "ng";
console.log(country)
const InReviewWithdrawals = () => { 
  const [withdrawals, setWithdrawals] = useState([]);

  const [getWithdrawalsLoading, setGetWithdrawalsLoading] = useState(false);
  const [getWithdrawalsError, setGetWithdrawalsError] = useState(null);
  const [approveWithdrawalLoading, setApproveWithdrawalLoading] =
    useState(false);
  const [approveWithdrawalError, setApproveWithdrawalError] = useState(false);
  const [customModal, setCustomModal] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");
  const [reject, setReject] = useState("");
  const [singleWithdrawal, setSingleWithdrawal] = useState({});
  const [fetchWithdrawalLoading, setFetchWithdrawalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [processor, setProcessor] = useState("");
  const [canWithdraw, setCanWithdraw] = useState(false);
  const [isManual, setIsManual] = useState(false)
  const [approvalError, setApprovalError] = useState(false)
  const [allProcessors, setAllProcessors] = useState("");
  const [declineCustomModal, setDeclineCustomModal] = useState({
    id: "",
    visible: false,
  });

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone No.",
        accessor: "phone_number",
      },
      {
        Header: "Local Amount",
        accessor: "local_request_amount",
        Cell: data => (data.value / 100).toLocaleString(),
      },
      {
        Header: "Base Amount",
        accessor: "base_request_amount",
        Cell: data => data.value / 100,
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Date",
        accessor: "request_date",
        Cell: data => new Date(data.value).toLocaleString(),
      },
    //   {
    //     Header: "Actions",
    //     Cell: cellProps => {
    //       return (
    //         <div className="d-flex gap-3">
    //           <Link
    //             to="#"
    //             className="text-success"
    //             onClick={() => {
    //               const data = cellProps.row.original;
    //               handleApproveWithdrawal(data.withdrawal_request_id);
    //             }}
    //           >
    //             <i className="mdi mdi-check font-size-18" id="edittooltip" />
    //             <UncontrolledTooltip placement="top" target="edittooltip">
    //               Approve
    //             </UncontrolledTooltip>
    //           </Link>
    //           <Link
    //             to="#"
    //             className="text-danger"
    //             onClick={() => {
    //               const data = cellProps.row.original;
    //               // handleDeclineWithdrawal(data.withdrawal_request_id);
    //               setDeclineCustomModal({
    //                 visible: true,
    //                 id: data.withdrawal_request_id,
    //               });
    //             }}
    //           >
    //             <i className="mdi mdi-close font-size-18" id="deletetooltip" />
    //             <UncontrolledTooltip placement="top" target="deletetooltip">
    //               Decline
    //             </UncontrolledTooltip>
    //           </Link>
    //         </div>
    //       );
    //     },
    //   },
    ],

    []
  );
  const getAllProcessors = () => {
    api.deposits
      .getAllProcessors()
      .then(res => {
        if (res.status === 200) {
          const defaultProc = res.data.data[0];
          setAllProcessors(res.data.data);
          setProcessor(defaultProc.payment_method_id);
        }
      })
      .catch(err => {
        setGetWithdrawalsError(err.response?.data.message || err.message);
      });
  };
  const getAllWithdrawals = (limit, page, search) => {
    setGetWithdrawalsLoading(true);
    api.commerce
      .getAllWithdrawalRequests(limit, page, search + "&status=in-review")
      .then(res => {
        setGetWithdrawalsLoading(false);
        if (res.status === 200) {
          setWithdrawals(res.data.data.withdrawal_requests);
          setPageCount(res.data.data.totalPages);
        }
      })
      .catch(err => {
        setGetWithdrawalsLoading(false);
        setGetWithdrawalsError(err.response?.data.message || err.message);
      });
  };
  const handleProcessorChange = e => {
    console.log(e.target.value);
    setProcessor(e.target.value);
  };
  const approveWithdrawal = id => {
    setApproveWithdrawalLoading(true);
    api.deposits
      .approveWithdrawal({
        withdrawal_request_id: id,
        request_status: "approved",
        payment_method_id: processor,
        is_manual_withdrawal:isManual
      })
      .then(res => {
        setApproveWithdrawalLoading(false);
        if (res.status === 200) {
          getAllWithdrawals(pageSize, pageIndex);
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Withdrawal Approved Successfully!"
              onOk={() => {
                setCustomModal(null);
                setShowModal(false);
              }}
              closeModal={() => {
                setCustomModal(null);
                setShowModal(false);
              }}
              okText="Ok"
              visible={true}
            />
          );
        } else {
          setApproveWithdrawalError(res.data.message);
        }
      })
      .catch(err => {
        setApproveWithdrawalLoading(false);
        setCustomModal(null);
        setApproveWithdrawalError(err.response?.data.message || err.message);
      });
  };
  const fetchWithdrawalDetails = id => {
    setFetchWithdrawalLoading(true);
    api.commerce
      .fetchDeposit(id)
      .then(res => {
        if (res.status === 200) {
          console.log(singleWithdrawal);
          setFetchWithdrawalLoading(false);
          setSingleWithdrawal(res.data.data);
        }
      })
      .catch(err => {
        setFetchWithdrawalLoading(false);
        setCustomModal(null);
        setApproveWithdrawalError(err.response?.data.message || err.message);
      });
  };


   const checkWithdrawalApproval = id => {
     setFetchWithdrawalLoading(true);
     api.withdrawals
       .fetchApproval(id)
       .then(res => {
         if (res.status === 200) {
           console.log(res.data.data)
       
           setCanWithdraw(true);
         }
       })
       .catch(err => {
        setCanWithdraw(false);
         setCustomModal(null);
         setApprovalError(err.response?.data.message || err.message);
       });
   };
  const declineWithdrawal = (id, reason) => {
    setApproveWithdrawalLoading(true);
    api.deposits
      .approveWithdrawal({
        withdrawal_request_id: id,
        request_status: "rejected",
        reason,
      })
      .then(res => {
        setApproveWithdrawalLoading(false);
        if (res.status === 200) {
          getAllWithdrawals(pageSize, pageIndex);
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Withdrawal Declined Successfully!"
              onOk={() => {
                setCustomModal(null);
                setShowModal(false);
              }}
              closeModal={() => {
                setCustomModal(null);
                setShowModal(false);
              }}
              okText="Ok"
              visible={true}
            />
          );
        } else {
          setApproveWithdrawalError(res.data.message);
        }
      })
      .catch(err => {
        setApproveWithdrawalLoading(false);
        setCustomModal(null);
        setApproveWithdrawalError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    approveWithdrawalError &&
      setTimeout(() => {
        setApproveWithdrawalError(null);
      }, 3000);
        approvalError &&
          setTimeout(() => {
          setApprovalError(null)
          }, 5000);
    getWithdrawalsError &&
      setTimeout(() => {
        setGetWithdrawalsError(null);
      }, 3000);
  });

  const handleApproveWithdrawal = id => {
    setCustomModal(
      <CustomModal
        title="Approve withdrawal request?"
        loading={approveWithdrawalLoading}
        content={<p>Do you want to approve this withdrawal request?</p>}
        onOk={() => {
          approveWithdrawal(id);
        }}
        okText="Approve"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  useEffect(() => {
    getAllWithdrawals(pageSize, pageIndex + 1, filters);
    getAllProcessors();
  }, [pageIndex, pageSize]);

  useEffect(() => {
    getAllWithdrawals(pageSize, 1, filters);
    getAllProcessors();
  }, [filters]);

  //meta title
  document.title = "Withdrawal Requests";

  return (
    <div>
      {showModal && (
        <CustomModal
          title={<b>Withdrawal Request Details</b>}
          content={
            Object.keys(singleWithdrawal).length !== 0 ? (
              <div>
                <p>
                  <b>Account Name:</b>{" "}
                  {
                    singleWithdrawal.user_withdraw_option?.withdrawal_details
                      .account_name
                  }
                </p>
                <p>
                  <b>Account Number:</b>{" "}
                  {
                    singleWithdrawal.user_withdraw_option?.withdrawal_details
                      .account_number
                  }
                </p>

                <p>
                  <b>User ID:</b> {singleWithdrawal.user_profile.user_id}
                </p>
                <p>
                  <b>Transaction reference:</b>{" "}
                  {singleWithdrawal.user_withdrawal.transaction_reference}
                </p>
                <p>
                  <b>Base Amount:</b>{" "}
                  {singleWithdrawal.base_request_amount / 100}
                </p>
                <p>
                  <b>Local Amount:</b>{" "}
                  {(
                    singleWithdrawal.local_request_amount / 100
                  ).toLocaleString()}
                </p>
                {singleWithdrawal.user_wallets.map(w => {
                  return (
                    <p key={w.user_wallet_id}>
                      <b>
                        {w.wallet_type[0].toUpperCase() +
                          w.wallet_type.substring(1)}{" "}
                        Balance:
                      </b>{" "}
                      {w.local_wallet_value / 100}
                    </p>
                  );
                })}
                <p>
                  <b>Request Date:</b>{" "}
                  {new Date(singleWithdrawal.request_date).toString()}
                </p>
                <p>
                  <b>Country:</b>{" "}
                  {singleWithdrawal.user_profile.locale_country_profile.country}
                </p>
                <p>
                  <b>Withdrawal Status:</b> {singleWithdrawal.request_status}
                </p>

                <p>
                  <b>User Name:</b> {singleWithdrawal.user_profile.first_name}{" "}
                  {singleWithdrawal.user_profile.last_name}
                </p>
                <p>
                  <b>User Email:</b> {singleWithdrawal.user_profile.email}
                </p>
                <p>
                  <b>Phone Number:</b> {singleWithdrawal.user_profile.phone_num}
                </p>

                <p>
                  <b>Account Level:</b>{" "}
                  {singleWithdrawal.user_profile.account_level}
                </p>
                <p>
                  <b>Account Status:</b>{" "}
                  {singleWithdrawal.user_profile.account_status}
                </p>
                <p>
                  <b>Is Verified:</b>{" "}
                  {singleWithdrawal.user_profile.is_verified.toString()}
                </p>
                <p>
                  <b>Bank Name:</b>{" "}
                  {
                    singleWithdrawal.user_withdraw_option?.withdrawal_details
                      .bank_name
                  }
                </p>
                <p>
                  <Label className="form-label">Processor</Label>
                  <Input
                    type="select"
                    name="role_id"
                    id="role_id"
                    className="form-select"
                    onChange={handleProcessorChange}
                    value={processor || ""}
                  >
                    {allProcessors.map(m => (
                      <option
                        key={m.payment_method_id}
                        value={m.payment_method_id}
                      >
                        {m.method}
                      </option>
                    ))}
                  </Input>
                </p>
               {country !== "ng" && <div style={{ display: "flex" }}>
                  <p style={{ marginRight: "10px" }}> Manual Withdrawal</p>
                  <Input
                    onChange={e => setIsManual(e.target.checked)}
                    checked={isManual}
                    type="checkbox"
                  />
                </div>}
                <div
                  style={{
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{ marginRight: "10px" }}
                    type="button"
                    disabled={!canWithdraw}
                    className="btn btn-success "
                    onClick={() => {
                      handleApproveWithdrawal(
                        singleWithdrawal.withdrawal_request_id
                      );
                    }}
                  >
                    Approve
                  </button>

                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={() => {
                      console.log("heyy");
                      //  handleDeclineWithdrawal(data.withdrawal_request_id);
                      setDeclineCustomModal({
                        visible: true,
                        id: singleWithdrawal.withdrawal_request_id,
                      });
                    }}
                  >
                    Decline
                  </button>
                </div>
                {approvalError && (
                  <Alert
                  style={{marginTop:"10px"}}
                    color="danger"
                    fade={true}
                    isOpen={!!approvalError}
                    toggle={() => setApprovalError(null)}
                  >
                    {approvalError}
                  </Alert>
                )}
              </div>
            ) : (
              <div>Loading...</div>
            )
          }
          closeModal={() => setShowModal(false)}
          cancelText="Cancel"
          visible={showModal}
        />
      )}
      {declineCustomModal.visible && (
        <CustomModal
          title="Decline withdrawal request?"
          loading={approveWithdrawalLoading}
          content={
            <div>
              {approveWithdrawalError && (
                <Alert
                  color="danger"
                  fade={true}
                  isOpen={!!approveWithdrawalError}
                  toggle={() => setApproveWithdrawalError(null)}
                >
                  {approveWithdrawalError}
                </Alert>
              )}
              <p style={{ fontSize: "0.8rem" }}>
                {" "}
                Do you want to decline this withdrawal request?
              </p>
              <div style={{ marginTop: "20px" }}>
                <Tooltip
                  trigger={["focus"]}
                  title={"Please add a reason for declining withdrawal"}
                  placement="topLeft"
                >
                  <Input
                    name="rejection_reason"
                    maxLength={100}
                    showCount
                    id="rejection_reason"
                    type="text"
                    style={{
                      height: 60,
                    }}
                    multiline
                    placeholder="why do you want to decline this withdrawal?"
                    value={reject}
                    onChange={e => {
                      e.stopPropagation();
                      setReject(e.target.value);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          }
          onOk={() => {
            declineWithdrawal(declineCustomModal.id, reject);
            setDeclineCustomModal({ visible: false, id: "" });
          }}
          okText="Decline"
          closeModal={() => setDeclineCustomModal({ visible: false, id: "" })}
          cancelText="Cancel"
          visible={true}
        />
      )}
      {getWithdrawalsError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getWithdrawalsError}
          toggle={() => setGetWithdrawalsError(null)}
        >
          {getWithdrawalsError}
        </Alert>
      )}
      {approveWithdrawalError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!approveWithdrawalError}
          toggle={() => setApproveWithdrawalError(null)}
        >
          {approveWithdrawalError}
        </Alert>
      )}
      {customModal}

      <Card loading={getWithdrawalsLoading}>
        <TableContainer
          columns={columns}
          data={withdrawals.map(withdrawal => ({
            ...withdrawal,
            email: withdrawal.user_profile.email,
            phone_number: withdrawal.user_profile.phone_num,
            status: withdrawal.request_status,
          }))}
          addButtonText={null}
          setShowDetailsModal={data => {
            fetchWithdrawalDetails(data.original.withdrawal_request_id);
            checkWithdrawalApproval(data.original.withdrawal_request_id);
            setShowModal(true);
          }}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={pageSize}
          className="custom-header-css"
          addNewGroup={() => {}}
          pageCount={pageCount}
          setFilters={setFilters}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          controlledPageIndex={pageIndex}
        />
      </Card>
    </div>
  );
};
export default InReviewWithdrawals;
