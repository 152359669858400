export default class Tickets {
  constructor(client) {
    this.client = client;
  }

  //winning Factor

  getAllWinningFactors (){
    return this.client.get(`/ticket/bingo-winning-factor`)
  }

  createWinningFactor (body){
    return this.client.post(`ticket/bingo-winning-factor`, body)
  }

editWinningFactor (body){
  const id = body.winning_factor_id
  delete body.winning_factor_id
  return this.client.patch(`ticket/bingo-winning-factor/${id}`, body)
}

deleteWinningFactor (id){
  return this.client.delete(`ticket/bingo-winning-factor/${id}`)
}


  // Ticket Groups
  getAllTicketGroups() {
    return this.client.get("/ticket/all-groups");
  }
  getTicketGroup(group_id) {
    return this.client.get(`/ticket/groups/${group_id}`);
  }
  createTicketGroup(payload) {
    return this.client.post("/ticket/create-group", payload);
  }
  deleteTicketGroup(group_id) {
    return this.client.delete(`/ticket/groups/${group_id}`);
  }

  updateTicketGroup(group_id, payload) {
    return this.client.put(`/ticket/groups/${group_id}`, payload);
  }

  // Ticket Plans
  getAllTicketPlans(group_id) {
    return this.client.get(`/ticket/all-plans/${group_id}`);
  }
  getTicketGroup(plan_id) {
    return this.client.get(`/ticket/plan/${plan_id}`);
  }
  createTicketPlan(payload) {
    return this.client.post("/ticket/create-plan", payload);
  }
  editTicketPlan(id, payload) {
    return this.client.put(`/ticket/plan/${id}`, payload);
  }
  deleteTicketPlan(plan_id) {
    return this.client.delete(`/ticket/plan/${plan_id}`);
  }
  // Reports
  getTotalTicketsSold(country_id) {
    return this.client.get(`/ticket/admin/get-total-tickets/${country_id}`);
  }
  getAverageTicketsSold(country_id) {
    return this.client.get(
      `/ticket/admin/get-average-ticket-sales/${country_id}`
    );
  }
}
