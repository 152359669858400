// src/components/filter.
import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import {
  Modal,
  Col,
  Row,
  ModalHeader,
  ModalBody,
  Alert,
  FormFeedback,
  Form as ReactForm,
  Input,
} from "reactstrap";
import moment from "moment";
import { DatePicker } from "antd";
import CustomModal from "../../../reusables/CustomModal";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import api from "../../../api";

const CalendarTypes = ({ gameCalendar, visible, closeModal }) => {
  const [customModal, setCustomModal] = useState(null);
  const [calendarSchedule, setCalendarSchedule] = useState(null);
  const [getCalendarScheduleError, setGetCalendarScheduleError] =
    useState(null);
  const [createCalendarScheduleError, setCreateCalendarScheduleError] =
    useState(null);
  const [createCalendarScheduleLoading, setCreateCalendarScheduleLoading] =
    useState(false);
  const [getCalendarScheduleLoading, setGetCalendarScheduleLoading] =
    useState(false);
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());

  const [frequency, setFrequency] = useState({
    mon: calendarSchedule?.mon || false,
    tue: calendarSchedule?.tue || false,
    wed: calendarSchedule?.wed || false,
    thurs: calendarSchedule?.thurs || false,
    fri: calendarSchedule?.fri || false,
    sat: calendarSchedule?.sat || false,
    sun: calendarSchedule?.sun || false,
  });
  useEffect(() => {
    console.log(frequency);
  }, [frequency]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      week_position: calendarSchedule?.week_position || "",
      day_type: calendarSchedule?.day_type || "",
      interval: calendarSchedule?.interval || "",
    },
    validationSchema: Yup.object({
      week_position: Yup.string(),
      day_type: Yup.string(),
      interval: Yup.number(),
    }),
    onSubmit: values => {
      calendarSchedule
        ? editCalendarSchedule(calendarSchedule, {
            ...values,
            calendar_config_id: gameCalendar.calendar_config_id,
          })
        : createCalendarSchedule({
            ...values,
            calendar_config_id: gameCalendar.calendar_config_id,
          });
    },
  });

  const confirmCancel = () => {
    setCustomModal(
      <CustomModal
        title="Cancel Create Calendar?"
        content={
          <>
            <p>Do you want to stop creating calendar?</p>
          </>
        }
        onOk={() => {
          setCustomModal(null);
          closeModal();
          setCalendarSchedule(null);
        }}
        okText="Okay"
        closeModal={() => setCustomModal(null)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const createCalendarSchedule = payload => {
    const { week_position, day_type, interval, calendar_config_id } = payload;
    let start_date = startDate + getCountryOffset(country);
    let end_date = endDate + getCountryOffset(country);

    setCreateCalendarScheduleLoading(true);
    if (calendar_type === "single") {
      return api.games
        .createBingoCalendarSingle({ start_date, calendar_config_id })
        .then(res => {
          setCreateCalendarScheduleLoading(false);
          if (res.status === 200) {
            getCalendarSchedule(gameCalendar.calendar_config_id);
            setCustomModal(
              <CustomModal
                title="Success!"
                content="Single Calendar Schedule Created Successfully!"
                onOk={() => {
                  setCustomModal(null);
                  validation.handleReset();
                  closeModal();
                }}
                closeModal={() => {
                  setCustomModal(null);
                  validation.handleReset();
                  closeModal();
                }}
                okText="Ok"
                visible={true}
              />
            );
          }
        })
        .catch(err => {
          setCreateCalendarScheduleLoading(false);
          setCreateCalendarScheduleError(
            err.response?.data.message || err.message
          );
        });
    }
    if (calendar_type === "monthly") {
      return api.games
        .createBingoCalendarMonthly({
          start_date,
          end_date,
          day_type,
          week_position,
          calendar_config_id,
        })
        .then(res => {
          setCreateCalendarScheduleLoading(false);
          if (res.status === 200) {
            getCalendarSchedule(gameCalendar.calendar_config_id);
            setCustomModal(
              <CustomModal
                title="Success!"
                content="Monthly Calendar Schedule Created Successfully!"
                onOk={() => {
                  setCustomModal(null);
                  validation.handleReset();
                  closeModal();
                }}
                closeModal={() => {
                  setCustomModal(null);
                  validation.handleReset();
                  closeModal();
                }}
                okText="Ok"
                visible={true}
              />
            );
          }
        })
        .catch(err => {
          setCreateCalendarScheduleLoading(false);
          setCreateCalendarScheduleError(
            err.response?.data.message || err.message
          );
        });
    }
    if (calendar_type === "recurring") {
      return api.games
        .createBingoCalendarRecurring({
          start_date,
          end_date,
          interval,
          ...frequency,
          calendar_config_id,
        })
        .then(res => {
          setCreateCalendarScheduleLoading(false);
          if (res.status === 200) {
            getCalendarSchedule(gameCalendar.calendar_config_id);
            setCustomModal(
              <CustomModal
                title="Success!"
                content="Recurring Calendar Schedule Created Successfully!"
                onOk={() => {
                  setCustomModal(null);
                  validation.handleReset();
                  closeModal();
                }}
                closeModal={() => {
                  setCustomModal(null);
                  validation.handleReset();
                  closeModal();
                }}
                okText="Ok"
                visible={true}
              />
            );
          }
        })
        .catch(err => {
          setCreateCalendarScheduleLoading(false);
          setCreateCalendarScheduleError(
            err.response?.data.message || err.message
          );
        });
    }
  };

  const editCalendarSchedule = (calendar, payload) => {
    const { week_position, day_type, calendar_config_id, interval } = payload;
    let start_date = startDate;
    let end_date = endDate;

    if (!start_date.includes("+")) {
      start_date += getCountryOffset(country);
    }
    if (end_date && !end_date.includes("+")) {
      end_date += getCountryOffset(country)
    }
    setCreateCalendarScheduleLoading(true)
    if (calendar_type === "single") {
      return api.games
        .updateBingoCalendarSingle(calendar.cal_config_single_id, {
          start_date,
          calendar_config_id,
        })
        .then(res => {
          setCreateCalendarScheduleLoading(false);
          if (res.status === 200) {
            getCalendarSchedule(gameCalendar.calendar_config_id);
            setCustomModal(
              <CustomModal
                title="Success!"
                content="Single Calendar Schedule Updated Successfully!"
                onOk={() => {
                  setCustomModal(null);
                  setCalendarSchedule(null);
                  closeModal();
                }}
                closeModal={() => {
                  setCalendarSchedule(null);
                  closeModal();
                  setCustomModal(null);
                }}
                okText="Ok"
                visible={true}
              />
            );
          }
        })
        .catch(err => {
          setCreateCalendarScheduleLoading(false);
          setCreateCalendarScheduleError(
            err.response?.data.message || err.message
          );
        });
    }
    if (calendar_type === "monthly") {
      return api.games
        .updateBingoCalendarMonthly(calendar.cal_config_monthly_id, {
          start_date,
          end_date,
          day_type,
          week_position,
          calendar_config_id,
        })
        .then(res => {
          setCreateCalendarScheduleLoading(false);
          if (res.status === 200) {
            getCalendarSchedule(gameCalendar.calendar_config_id);
            setCustomModal(
              <CustomModal
                title="Success!"
                content="Monthly Calendar Schedule Updated Successfully!"
                onOk={() => {
                  setCustomModal(null);
                  setCalendarSchedule(null);
                  closeModal();
                }}
                closeModal={() => {
                  setCustomModal(null);
                  setCalendarSchedule(null);
                  closeModal();
                }}
                okText="Ok"
                visible={true}
              />
            );
          }
        })
        .catch(err => {
          setCreateCalendarScheduleLoading(false);
          setCreateCalendarScheduleError(
            err.response?.data.message || err.message
          );
        });
    }
    if (calendar_type === "recurring") {
      return api.games
        .updateBingoCalendarRecurring(calendar.cal_config_recurr_id, {
          start_date,
          end_date,
          ...frequency,
          interval,
          calendar_config_id,
        })
        .then(res => {
          setCreateCalendarScheduleLoading(false);
          if (res.status === 200) {
            getCalendarSchedule(gameCalendar.calendar_config_id);
            setCustomModal(
              <CustomModal
                title="Success!"
                content="Recurring Calendar Schedule Updated Successfully!"
                onOk={() => {
                  setCalendarSchedule(null);
                  closeModal();
                  setCustomModal(null);
                }}
                closeModal={() => {
                  setCalendarSchedule(null);
                  closeModal();
                  setCustomModal(null);
                }}
                okText="Ok"
                visible={true}
              />
            );
          }
        })
        .catch(err => {
          setCreateCalendarScheduleLoading(false);
          setCreateCalendarScheduleError(
            err.response?.data.message || err.message
          );
        });
    }
  };

  const getCalendarSchedule = group_id => {
    setGetCalendarScheduleLoading(true);
    if (calendar_type === "single") {
      return api.games
        .getBingoCalendarSingle(group_id)
        .then(res => {
          setGetCalendarScheduleLoading(false);
          if (res.status === 200) {
            setCalendarSchedule(res.data.data);
          }
        })
        .catch(err => {
          setGetCalendarScheduleLoading(false);
          setGetCalendarScheduleError(
            err.response?.data.message || err.message
          );
        });
    }
    if (calendar_type === "monthly") {
      return api.games
        .getBingoCalendarMonthly(group_id)
        .then(res => {
          setGetCalendarScheduleLoading(false);
          if (res.status === 200) {
            setCalendarSchedule(res.data.data);
          }
        })
        .catch(err => {
          setGetCalendarScheduleLoading(false);
          setGetCalendarScheduleError(
            err.response?.data.message || err.message
          );
        });
    }
    if (calendar_type === "recurring") {
      return api.games
        .getBingoCalendarRecurring(group_id)
        .then(res => {
          setGetCalendarScheduleLoading(false);
          if (res.status === 200) {
            setCalendarSchedule(res.data.data);
          }
        })
        .catch(err => {
          setGetCalendarScheduleLoading(false);
          setGetCalendarScheduleError(
            err.response?.data.message || err.message
          );
        });
    }
  };

  const country = localStorage.getItem("country") || "ng";
  const getCountryOffset = country => {
    switch (country) {
      case "ng":
        return "+01:00";
      case "gh":
        return "+00:00";
      case "zm":
        return "+02:00";
      case "cd":
        return "+01:00";
      default:
        return "+01:00";
    }
  };

  const handleDateChange = val => {
    const [startMoment, endMoment] = val;
    if (!startMoment || !endMoment) {
      return setCreateCalendarScheduleError(
        "Enter start and end dates to continue!"
      );
    }

    const startDate = moment(startMoment).format("YYYY-MM-DD HH:mm:ss");

    const endDate = moment(endMoment).format("YYYY-MM-DD HH:mm:ss");
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleSingleDateChange = val => {
    const startDate = moment(val).format("YYYY-MM-DD HH:mm:ss");
    setStartDate(startDate);
  };

  useEffect(() => {
    getCalendarSchedule(gameCalendar.calendar_config_id, calendar_type);
  }, []);

  useEffect(() => {
    createCalendarScheduleError &&
      setTimeout(() => {
        setCreateCalendarScheduleError(null);
      }, 3000);
    getCalendarScheduleError &&
      setTimeout(() => {
        setGetCalendarScheduleError(null);
      }, 3000);
  });

  useEffect(() => {
    calendarSchedule &&
      setFrequency({
        mon: calendarSchedule?.mon || false,
        tue: calendarSchedule?.tue || false,
        wed: calendarSchedule?.wed || false,
        thurs: calendarSchedule?.thurs || false,
        fri: calendarSchedule?.fri || false,
        sat: calendarSchedule?.sat || false,
        sun: calendarSchedule?.sun || false,
      });
    if (calendarSchedule) {
      let startDate = calendarSchedule.start_date;
      let endDate = calendarSchedule.end_date;
      const start_index = startDate.indexOf("+");
      startDate = startDate.slice(0, start_index);
      if (endDate) {
        const end_index = endDate.indexOf("+");
        endDate = endDate.slice(0, end_index);
      }
      setStartDate(startDate);
      setEndDate(endDate);
    }
  }, [calendarSchedule]);

  //meta title
  document.title = "Bingo Tickets";

  const calendar_type = gameCalendar.calender_type;

  return (
    <div className="page-content">
      {getCalendarScheduleError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getCalendarScheduleError}
          toggle={() => setGetCalendarScheduleError(null)}
        >
          {getCalendarScheduleError}
        </Alert>
      )}
      <Modal isOpen={visible} size="lg" toggle={confirmCancel}>
        <ModalHeader toggle={confirmCancel} tag="h4">
          Calendar Schedule Details
        </ModalHeader>

        {createCalendarScheduleError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!createCalendarScheduleError}
            toggle={() => setCreateCalendarScheduleError(null)}
          >
            {createCalendarScheduleError}
          </Alert>
        )}

        <ModalBody>
          {getCalendarScheduleError && (
            <Alert
              color="danger"
              fade={true}
              isOpen={!!getCalendarScheduleError}
              toggle={() => setGetCalendarScheduleError(null)}
            >
              {getCalendarScheduleError}
            </Alert>
          )}
          <ReactForm>
            <Row>
              <Col xs={12}>
                {calendar_type === "single" && (
                  <>
                    <p className="mt-4">
                      <b>Single Bingo</b>
                    </p>
                    <div className="mb-3">
                      <div>
                        <DatePicker
                          size="large"
                          onChange={handleSingleDateChange}
                          value={moment(startDate)}
                          format="YYYY-MM-DD hh:mm a"
                          showTime={{
                            format: "hh:mm a",
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {calendar_type === "recurring" && (
                  <>
                    <p className="mt-4">
                      <b>Recurring Bingo</b>
                    </p>
                    <div className="mb-3">
                      <p style={{ margin: 0 }}>
                        <b>Frequency</b>
                      </p>
                      <Row>
                        {daysOfWeek.map(day => (
                          <div
                            style={{ marginLeft: "10px" }}
                            key={day}
                            className="form-check form-checkbox mt-3"
                          >
                            <Checkbox
                              checked={frequency[day]}
                              onChange={e => {
                                setFrequency({
                                  ...frequency,
                                  [day]: e.target.checked,
                                });
                                console.log("");
                              }}
                              value={day}
                            >
                              {" "}
                              {day}
                            </Checkbox>
                          </div>
                        ))}
                      </Row>
                    </div>
                    <div className="mb-3">
                      <Row>
                        <Col lg={6}>
                          <p className="mb-3">
                            <b>Interval (Mins)</b>
                          </p>
                          <Input
                            type="number"
                            name="interval"
                            id="interval"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.interval || ""}
                            invalid={
                              validation.touched.interval &&
                              validation.errors.interval
                                ? true
                                : false
                            }
                          />

                          {validation.touched.interval &&
                          validation.errors.interval ? (
                            <FormFeedback type="invalid">
                              {validation.errors.interval}
                            </FormFeedback>
                          ) : null}
                        </Col>

                        <Col lg={6}>
                          <p className="mb-3">
                            <b>Duration</b>
                          </p>
                          <div>
                            <DatePicker.RangePicker
                              size="large"
                              format="YYYY-MM-DD hh:mm a"
                              value={[moment(startDate), moment(endDate)]}
                              onChange={handleDateChange}
                              showTime={true}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </>
                )}

                {calendar_type === "monthly" && (
                  <>
                    <p className="mt-4 mb-2">
                      <b>Monthly Bingo</b>
                    </p>

                    <div className="mb-3">
                      <Row>
                        <Col lg={1}>
                          <p style={{ marginTop: 10 }}>Every</p>
                        </Col>
                        <Col lg={4}>
                          <Input
                            type="select"
                            name="week_position"
                            id="week_position"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.week_position || ""}
                            invalid={
                              validation.touched.week_position &&
                              validation.errors.week_position
                                ? true
                                : false
                            }
                          >
                            <option value="">Select one...</option>
                            {positions.map(val => (
                              <option key={val} value={val}>
                                {val}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.week_position &&
                          validation.errors.week_position ? (
                            <FormFeedback type="invalid">
                              {validation.errors.week_position}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col lg={4}>
                          <Input
                            type="select"
                            name="day_type"
                            id="day_type"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.day_type || ""}
                            invalid={
                              validation.touched.day_type &&
                              validation.errors.day_type
                                ? true
                                : false
                            }
                          >
                            <option value="">Select one...</option>
                            {daysOfWeek.map(val => (
                              <option key={val} value={val}>
                                {val}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.day_type &&
                          validation.errors.day_type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.day_type}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col lg={2}>
                          <p style={{ marginTop: 10 }}> Of every month</p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-3">
                      <DatePicker.RangePicker
                        size="large"
                        showTime={true}
                        onChange={handleDateChange}
                        format="YYYY-MM-DD hh:mm a"
                        value={[moment(startDate), moment(endDate)]}
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </ReactForm>
        </ModalBody>

        <div className="modal-footer">
          <button
            type="button"
            onClick={confirmCancel}
            className="btn btn-danger"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            onClick={validation.handleSubmit}
            loading={createCalendarScheduleLoading ? "true" : "false"}
            type="button"
            className="btn btn-success "
          >
            {calendarSchedule ? "Edit" : "Add"} Calendar Schedule
          </button>
        </div>
      </Modal>
      {customModal}
    </div>
  );
};

export default CalendarTypes;

const daysOfWeek = ["mon", "tue", "wed", "thurs", "fri", "sat", "sun"];
const positions = ["first", "second", "third", "last"];
