import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import api from "../../api";
import { UncontrolledTooltip, Alert, Button } from "reactstrap";
import {
  Card,
  Tabs,
  Table,
  Modal,
  message,
  Form,
  Upload,
  Input,
  Space,
} from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../Withdrawals/GroupsTable";
import CustomModal from "../../reusables/CustomModal";
import { CSVLink } from "react-csv";
import { PlusOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const { TabPane } = Tabs;
const { Dragger } = Upload;

const AllUsers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [getUsersLoading, setGetUsersLoading] = useState(false);
  const [getUsersError, setGetUsersError] = useState(null);
  const [activateUserLoading, setActivateUserLoading] = useState(false);
  const [activateUserError, setActivateUserError] = useState(false);
  const [customModal, setCustomModal] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [importUsers, setImportUsers] = useState(false);
  const [uploadedCsvFile, setUploadedCsvFile] = useState(null);
  const [uploadUsersLoading, setUploadUsersLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");

  const [getUserDepositDetailsLoading, setGetUserDepositDetailsLoading] =
    useState(false);
  const [getUserSlotDetailsLoading, setGetUserSlotDetailsLoading] =
    useState(false);
  const [getUserWithdrawalDetailsLoading, setGetUserWithdrawalDetailsLoading] =
    useState(false);
  const [
    getUserWithdrawalRequestsLoading,
    setGetUserWithdrawalRequestsLoading,
  ] = useState(false);
  const [userSlotDetails, setUserSlotDetails] = useState([]);
  const [userWithdrawalDetails, setUserWithdrawalDetails] = useState([]);
  const [userWithdrawalRequestsDetails, setUserWithdrawalRequestsDetails] =
    useState([]);
  const [userDepositDetails, setUserDepositDetails] = useState([]);

  const [userDetails, setUserDetails] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState("");
	const searchInput = useRef(null);
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "first_name",
        Cell: data =>
          data.row.original.first_name + " " + data.row.original.last_name,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Level",
        accessor: "account_level",
      },
      {
        Header: "Status",
        accessor: "account_status",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
      },
      {
        Header: "Verified",
        accessor: "is_verified",
        Cell: data => data.value.toString(),
      },
      {
        Header: "Date",
        accessor: "creation_date",
        Cell: data => new Date(data.value).toLocaleString(),
      },
      {
        Header: "Actions",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {cellProps.row.original.account_status === "disabled" && (
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const data = cellProps.row.original;
                    handleActivateUser(data.user_id);
                  }}
                >
                  <i className="mdi mdi-check font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Activate
                  </UncontrolledTooltip>
                </Link>
              )}
              {cellProps.row.original.account_status === "active" && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const data = cellProps.row.original;
                    handleDeactivateUser(data.user_id);
                  }}
                >
                  <i
                    className="mdi mdi-close font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Deactivate
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const allDataColumn = [
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Level",
      key: "account_level",
    },
    {
      label: "Status",
      key: "account_status",
    },
    {
      label: "Gender",
      key: "gender",
    },
    {
      label: "Phone Number",
      key: "phone_num",
    },
    {
      label: "Verified",
      key: "is_verified",
    },
    {
      label: "Date",
      key: "creation_date",
    },
  ];
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getAllUsers = (limit, page, search) => {
    setGetUsersLoading(true);
    api.commerce
      .getAllUsers(limit, page, search)
      .then(res => {
        setGetUsersLoading(false);
        if (res.status === 200) {
          setAllUsers(res.data.data.users);
          setPageCount(res.data.data.totalPages);
        }
      })
      .catch(err => {
        setGetUsersLoading(false);
        setGetUsersError(err.response?.data.message || err.message);
      });
  };
  const getAllUserDeposits = id => {
    setGetUserDepositDetailsLoading(true);
    api.commerce
      .getAllSingleUserDeposits(id)
      .then(res => {
        setGetUserDepositDetailsLoading(false);
        if (res.status === 200) {
          setUserDepositDetails(res.data.data.deposits);
        }
      })
      .catch(err => {
        setGetUserDepositDetailsLoading(false);
        console.error(err.response?.data.message || err.message);
      });
  };

  const getAllUserSlots = id => {
    setGetUserSlotDetailsLoading(true);
    api.commerce
      .getAllSingleUserSlots(id)
      .then(res => {
        setGetUserSlotDetailsLoading(false);
        if (res.status === 200) {
          setUserSlotDetails(res.data.data.transactions);
        }
      })
      .catch(err => {
        setGetUserSlotDetailsLoading(false);
        console.error(err.response?.data.message || err.message);
      });
  };

  const getAllUserWithdrawals = id => {
    setGetUserWithdrawalDetailsLoading(true);
    api.commerce
      .getAllSingleUserWithdrawals(id)
      .then(res => {
        setGetUserWithdrawalDetailsLoading(false);
        if (res.status === 200) {
          setUserWithdrawalDetails(res.data.data.withdrawals);
        }
      })
      .catch(err => {
        setGetUserWithdrawalDetailsLoading(false);
        console.error(err.response?.data.message || err.message);
      });
  };
  const getAllUserWithdrawalRequests = id => {
    setGetUserWithdrawalRequestsLoading(true);
    api.commerce
      .getAllSingleUserWithdrawalRequests(id)
      .then(res => {
        setGetUserWithdrawalRequestsLoading(false);
        if (res.status === 200) {
          setUserWithdrawalRequestsDetails(res.data.data.withdrawal_requests);
        }
      })
      .catch(err => {
        setGetUserWithdrawalRequestsLoading(false);
        console.error(err.response?.data.message || err.message);
      });
  };

  const activateUser = id => {
    setActivateUserLoading(true);
    api.commerce
      .updateUserStatus(id, { status: "active" })
      .then(res => {
        setActivateUserLoading(false);
        if (res.status === 200) {
          getAllUsers(10, 1, search);
          setCustomModal(
            <CustomModal
              title="Success!"
              content="User Activated Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setActivateUserLoading(false);
        setActivateUserError(err.response?.data.message || err.message);
      });
  };

  const deActivateUser = id => {
    setActivateUserLoading(true);
    api.commerce
      .updateUserStatus(id, { status: "disabled" })
      .then(res => {
        setActivateUserLoading(false);
        if (res.status === 200) {
          getAllUsers(10, 1, search);
          setCustomModal(
            <CustomModal
              title="Success!"
              content="User Deactivated Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setActivateUserLoading(false);
        setActivateUserError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    activateUserError &&
      setTimeout(() => {
        setActivateUserError(null);
      }, 3000);
    getUsersError &&
      setTimeout(() => {
        setGetUsersError(null);
      }, 3000);
  });

  const handleActivateUser = id => {
    setCustomModal(
      <CustomModal
        title="Activate User Account"
        loading={activateUserLoading}
        content={
          <p>
            {activateUserError && (
              <Alert
                color="danger"
                fade={true}
                isOpen={!!activateUserError}
                toggle={() => setActivateUserError(null)}
              >
                {activateUserError}
              </Alert>
            )}
            Do you want to activate this user account?
          </p>
        }
        onOk={() => {
          activateUser(id);
        }}
        okText="Activate"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const handleDeactivateUser = id => {
    setCustomModal(
      <CustomModal
        title="Deactivate User Account?"
        loading={activateUserLoading}
        content={
          <p>
            {activateUserError && (
              <Alert
                color="danger"
                fade={true}
                isOpen={!!activateUserError}
                toggle={() => setActivateUserError(null)}
              >
                {activateUserError}
              </Alert>
            )}
            Do you want to deactivate this user account?
          </p>
        }
        onOk={() => {
          deActivateUser(id);
        }}
        okText="Deactivate"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  useEffect(() => {
    userDetails && getAllUserDeposits(userDetails.user_id);
    userDetails && getAllUserWithdrawals(userDetails.user_id);
    userDetails && getAllUserWithdrawalRequests(userDetails.user_id);
    userDetails && getAllUserSlots(userDetails.user_id);
  }, [userDetails]);

  const openDepositDetailsModal = data => {
    setUserDetails(data);
  };

  const walletColumns = [
    {
      title: "Wallet Type",
      dataIndex: "wallet_type",
      key: "wallet_type",
      sorter: (a, b) => a.wallet_type?.length - b.wallet_type?.length,
    },
    {
      title: "Base Value",
      dataIndex: "base_wallet_value",
      key: "base_wallet_value",
      render: data => data / 100,
      sorter: (a, b) => a.base_wallet_value - b.base_wallet_value,
    },
    {
      title: "Local Value",
      dataIndex: "local_wallet_value",
      key: "local_wallet_value",
      render: data => (data / 100).toLocaleString(),
      sorter: (a, b) => a.local_wallet_value - b.local_wallet_value,
    },
  ];

  const withdrawalRequestColumns = [
    {
      title: "Status",
      dataIndex: "request_status",
      key: "request_status",
      sorter: (a, b) => a.request_status?.length - b.request_status?.length,
      ...getColumnSearchProps("request_status"),
    },
    {
      title: "Method",
      dataIndex: "withdrawal_type",
      key: "withdrawal_type",
      ...getColumnSearchProps("withdrawal_type"),
    },
    {
      title: "Local Amount",
      dataIndex: "local_request_amount",
      key: "local_request_amount",
      render: data => (data / 100).toLocaleString(),
      sorter: (a, b) => a.local_request_amount - b.local_request_amount,
    },
    {
      title: "Date",
      dataIndex: "request_date",
      key: "request_date",
      sorter: (a, b) => new Date(a.request_date) - new Date(b.request_date),
      render: data => new Date(data).toLocaleString(),
    },
  ];

  const withdrawalColumns = [
    {
      title: "Status",
      dataIndex: "transaction_status",
      key: "transaction_status",
      sorter: (a, b) => a.transaction_status - b.transaction_status,
      ...getColumnSearchProps("transaction_status"),
    },
    {
      title: "Base Amount",
      dataIndex: "user_withdrawal_request",
      key: "user_withdrawal_request",
      sorter: (a, b) =>
        a.user_withdrawal_request?.base_request_amount -
        b.user_withdrawal_request?.base_request_amount,
      render: data => data?.base_request_amount / 100,
    },
    {
      title: "Local Amount",
      dataIndex: "user_withdrawal_request",
      key: "withdrawal_id",
      sorter: (a, b) =>
        a.user_withdrawal_request?.local_request_amount -
        b.user_withdrawal_request?.local_request_amount,
      render: data => (data?.local_request_amount / 100).toLocaleString(),
    },
    {
      title: "Date",
      dataIndex: "withdrawal_date",
      key: "withdrawal_date",
      sorter: (a, b) =>
        new Date(a.withdrawal_date) - new Date(b.withdrawal_date),
      render: data => new Date(data).toLocaleString(),
    },
  ];

  const slotsColumns = [
    {
      title: "Bingo Name",
      dataIndex: "gameName",
      key: "gameName",
      sorter: (a, b) => a.gameName.length - b.gameName.length,
    },
    {
      title: "Base Amount",
      dataIndex: "base_amount",
      key: "base_amount",
      sorter: (a, b) => a.base_amount - b.base_amount,
      render: data => data / 100,
    },
    {
      title: "Local Amount",
      dataIndex: "local_amount",
      key: "local_amount",
      sorter: (a, b) => a.local_amount - b.local_amount,
      render: data => (data / 100).toLocaleString(),
    },
    {
      title: "Bet",
      dataIndex: "bet",
      key: "bet",
      sorter: (a, b) => a.bet - b.bet,
      render: data => data / 100,
    },
    {
      title: "Wins",
      dataIndex: "wins",
      key: "wins",
      sorter: (a, b) => a.wins - b.wins,
      render: data => data / 100,
    },

    {
      title: "Date",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => Number(a.created) - Number(b.created),
      render: data => new Date(Number(data)).toLocaleString(),
    },
  ];

  const depositColumns = [
    {
      title: "Reference",
      dataIndex: "transaction_reference",
      key: "transaction_reference",
      ...getColumnSearchProps("transaction_reference"),
    },
    {
      title: "Method",
      dataIndex: "deposit_type_name",
      key: "deposit_type_name",
      ...getColumnSearchProps("deposit_type_name"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
    },
    {
      title: "Local Amount",
      dataIndex: "local_amount_deposits",
      key: "local_amount_deposits",
      render: data => (data / 100).toLocaleString(),
      sorter: (a, b) => a.local_amount_deposits - b.local_amount_deposits,
      ...getColumnSearchProps("local_amount_deposits"),
    },
    {
      title: "Date",
      dataIndex: "deposits_date",
      key: "deposits_date",
      sorter: (a, b) => new Date(a.deposits_date) - new Date(b.deposits_date),
      render: data => new Date(data).toLocaleString(),
    },
  ];

  const cancelDetailsModal = () => {
    setUserDetails(null);
    setUserDepositDetails([]);
    setUserSlotDetails([]);
    setUserWithdrawalDetails([]);
    setUserWithdrawalRequestsDetails([]);
  };

  const acceptedHeaders = [
    {
      label: "First Name",
      key: "first_name",
    },
    {
      label: "Last Name",
      key: "last_name",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Phone Number",
      key: "phone_num",
    },
    {
      label: "Account Level",
      key: "account_level",
    },
    {
      label: "Account Status",
      key: "account_status",
    },
    {
      label: "Verified",
      key: "is_verified",
    },
    {
      label: "No of Deposits",
      key: "userDepositDetails",
    },
    {
      label: "No of Withdrawals",
      key: "userWithdrawalDetails",
    },
    {
      label: "No of Withdrawal Requests",
      key: "userWithdrawalRequestsDetails",
    },
    {
      label: "No of Slots",
      key: "userSlotDetails",
    },
  ];

  const acceptedWalletHeaders = [
    {
      label: "Wallet Type",
      key: "wallet_type",
    },
    {
      label: "Base Value",
      key: "base_value",
    },
    {
      label: "Local Value",
      key: "local_value",
    },
  ];

  const acceptedWithdrawalHeaders = [
    {
      label: "Status",
      key: "transaction_status",
    },
    {
      label: "Base Amount",
      key: "base_amount",
    },
    {
      label: "Local Amount",
      key: "local_amount",
    },
    {
      label: "Date",
      key: "withdrawal_date",
    },
  ];

  const acceptedWithdrawalRequestHeaders = [
    {
      label: "Status",
      key: "request_status",
    },
    {
      label: "Base Amount",
      key: "base_request_amount",
    },
    {
      label: "Local Amount",
      key: "local_requests_amount",
    },
    {
      label: "Date",
      key: "request_date",
    },
  ];

  const acceptedSlotsHeaders = [
    {
      label: "Game Name",
      key: "gameName",
    },
    {
      label: "Bet",
      key: "bet",
    },
    {
      label: "Wins",
      key: "wins",
    },
    {
      label: "Base Amount",
      key: "base_amount",
    },
    {
      label: "Local Amount",
      key: "local_amount",
    },
    {
      label: "Date",
      key: "created",
    },
  ];

  const handleFileSubmit = () => {
    form.validateFields().then(values => {
      setUploadUsersLoading(true);
      const data = new FormData();
      data.append("users", values.users.fileList[0]);

      api.coreHttpClient
        .post("/user/user-bulk-csv-upload", data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then(res => {
          setUploadUsersLoading(false);
          if (res.status === 200) {
            Modal.success({
              title: "Users uploaded sucessfully",
              onOk: () => {
                setUploadedCsvFile(null);
                setImportUsers(false);
              },
            });
          }
        })
        .catch(err => {
          setUploadUsersLoading(false);
          message.error(err.response?.data.message || err.message);
        });
    });
  };

  const exportButton = (
    <div>
      <button
        style={{ marginRight: 20 }}
        className="btn btn-secondary"
        onClick={() => setImportUsers(true)}
      >
        Import Users
      </button>
      <CSVLink
        id="csvDownloadBtn"
        key="all-users-download"
        data={allUsers.map(user => ({
          ...user,
          name: user.first_name + " " + user.last_name,
          creation_date: new Date(user.creation_date).toLocaleString(),
        }))}
        headers={allDataColumn}
        filename={`all-users-data.csv`}
        target="_blank"
      >
        <button className="btn btn-primary">Export Users</button>
      </CSVLink>
    </div>
  );
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const [form] = Form.useForm();
  const uploadProps = {
    name: "users",
    multiple: false,
    accept: ".csv",

    onChange(info) {
      setUploadedCsvFile(info.file);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
      setUploadedCsvFile(e.dataTransfer.files[0]);
    },
  };

  useEffect(() => {
    getAllUsers(pageSize, pageIndex + 1, "");
  }, [pageIndex, pageSize]);

  useEffect(() => {
    getAllUsers(pageSize, 1, filters);
  }, [filters]);

  //meta title
  document.title = "Users";

  useEffect(() => {
    // updateUserWallet("+2348036718452", 2000, "1.72209E+25");
  }, []);

  return (
    <div className="page-content">
      {getUsersError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getUsersError}
          toggle={() => setGetUsersError(null)}
        >
          {getUsersError}
        </Alert>
      )}
      {importUsers && (
        <Modal
          title="Import Users"
          visible={importUsers}
          onCancel={() => {
            setUploadedCsvFile(null);
            setImportUsers(false);
          }}
          footer={[
            <button
              className="btn btn-danger"
              key="cancel"
              onClick={() => {
                setUploadedCsvFile(null);
                setImportUsers(false);
              }}
            >
              Cancel
            </button>,
            <button
              className="btn btn-secondary"
              loading={uploadUsersLoading ? "true" : "false"}
              onClick={handleFileSubmit}
              style={{ marginLeft: 30 }}
              key="import"
            >
              Upload
            </button>,
          ]}
        >
          <Form
            form={form}
            layout="vertical"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form.Item
              name="users"
              label="Upload CSV file"
              rules={[{ required: true, message: "Please upload a csv file" }]}
            >
              {uploadedCsvFile ? (
                <p>
                  <b>{uploadedCsvFile.name}</b>
                </p>
              ) : (
                <Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              )}
            </Form.Item>
          </Form>
        </Modal>
      )}
      {customModal}
      <div className="container-fluid">
        <Breadcrumbs title="Users" breadcrumbItem="All Users" />
        <Card loading={getUsersLoading}>
          <TableContainer
            columns={columns}
            data={allUsers.map(user => ({
              ...user,
              phone_number: user.phone_num,
            }))}
            addButtonText={null}
            setShowDetailsModal={data => openDepositDetailsModal(data.original)}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={pageSize}
            className="custom-header-css"
            addNewGroup={() => {}}
            pageCount={pageCount}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            controlledPageIndex={pageIndex}
            exportButton={exportButton}
          />
        </Card>

        {userDetails && (
          <Modal
            visible={!!userDetails}
            onCancel={cancelDetailsModal}
            width={700}
            footer={[
              <Button onClick={cancelDetailsModal} key={1}>
                Cancel
              </Button>,

              <Dropdown
                key={3}
                isOpen={showDropdown}
                toggle={() => setShowDropdown(prev => !prev)}
                className="d-inline-block"
              >
                <DropdownToggle className="btn header-item " tag="button">
                  <button
                    className="btn btn-primary"
                    style={{
                      border: "none",
                      marginLeft: 20,
                    }}
                    key={2}
                  >
                    Export Data
                  </button>
                </DropdownToggle>
                <DropdownMenu className="language-switch dropdown-menu-end">
                  <DropdownItem>
                    <CSVLink
                      id="csvDownloadBtn"
                      key={2}
                      data={[
                        {
                          ...userDetails,
                          userDepositDetails: userDepositDetails.length,
                          userWithdrawalDetails: userWithdrawalDetails.length,
                          userWithdrawalRequestsDetails:
                            userWithdrawalRequestsDetails.length,
                          userSlotDetails: userSlotDetails.length,
                        },
                      ]}
                      headers={acceptedHeaders}
                      filename={`${userDetails.first_name}-${userDetails.last_name}.csv`}
                      // asyncOnClick={true}
                      target="_blank"
                    >
                      Export User Details
                    </CSVLink>
                  </DropdownItem>
                  {userDetails.wallets.length > 0 && (
                    <DropdownItem>
                      <CSVLink
                        key="wallets"
                        data={userDetails.wallets.map(wallet => ({
                          ...wallet,
                          base_value: wallet.base_wallet_value / 100,
                          local_value: wallet.local_wallet_value / 100,
                        }))}
                        headers={acceptedWalletHeaders}
                        filename={`${userDetails.first_name}-${userDetails.last_name}-wallets.csv`}
                        // asyncOnClick={true}
                        target="_blank"
                      >
                        Export Wallets
                      </CSVLink>
                    </DropdownItem>
                  )}
                  {userWithdrawalDetails.length > 0 && (
                    <DropdownItem>
                      <CSVLink
                        key="withdrawals"
                        data={userWithdrawalDetails.map(data => ({
                          ...data,
                          base_amount:
                            data.user_withdrawal_request.base_request_amount /
                            100,
                          local_amount:
                            data.user_withdrawal_request.local_request_amount /
                            100,
                          withdrawal_date: new Date(
                            data.withdrawal_date
                          ).toLocaleString(),
                        }))}
                        headers={acceptedWithdrawalHeaders}
                        filename={`${userDetails.first_name}-${userDetails.last_name}-withdrawals.csv`}
                        // asyncOnClick={true}
                        target="_blank"
                      >
                        Export Withdrawals
                      </CSVLink>
                    </DropdownItem>
                  )}
                  {userWithdrawalRequestsDetails.length > 0 && (
                    <DropdownItem>
                      <CSVLink
                        key="withdrawals"
                        data={userWithdrawalRequestsDetails.map(req => ({
                          ...req,
                          base_amount: req.base_request_amount / 100,
                          local_amount: req.local_request_amount / 100,
                          request_date: new Date(
                            req.request_date
                          ).toLocaleString(),
                        }))}
                        headers={acceptedWithdrawalRequestHeaders}
                        filename={`${userDetails.first_name}-${userDetails.last_name}-withdrawal-requests.csv`}
                        // asyncOnClick={true}
                        target="_blank"
                      >
                        Export Withdrawal Requests
                      </CSVLink>
                    </DropdownItem>
                  )}
                  {userSlotDetails.length > 0 && (
                    <DropdownItem>
                      <CSVLink
                        key="slots"
                        data={userSlotDetails.map(slot => ({
                          ...slot,
                          base_amount: slot.base_amount / 100,
                          local_amount: slot.local_amount / 100,
                          bet: slot.bet / 100,
                          wins: slot.wins / 100,
                          created: new Date(
                            Number(slot.created)
                          ).toLocaleString(),
                        }))}
                        headers={acceptedSlotsHeaders}
                        filename={`${userDetails.first_name}-${userDetails.last_name}-slots.csv`}
                        // asyncOnClick={true}
                        target="_blank"
                      >
                        Export Slots
                      </CSVLink>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>,
            ]}
            title={<b>User Details</b>}
          >
            <Tabs defaultActiveKey="1">
              <TabPane tab="Profile" key="1">
                <div>
                  <p>
                    <b>Name:</b> {userDetails.first_name}{" "}
                    {userDetails.last_name}
                  </p>
                  <p>
                    <b>User Email:</b> {userDetails.email}
                  </p>
                  <p>
                    <b>Phone Number:</b> {userDetails.phone_num}
                  </p>
                  <p>
                    <b>Account Level:</b> {userDetails.account_level}
                  </p>
                  <p>
                    <b>Account Status:</b> {userDetails.account_status}
                  </p>
                  <p>
                    <b>Is Verified:</b> {userDetails.is_verified.toString()}
                  </p>
                  <p>
                    <b>Date of Birth:</b> {userDetails.date_of_birth}
                  </p>
                  <p>
                    <b>Country:</b> {userDetails.locale_country_profile.country}
                  </p>
                  <p>
                    <b>User ID:</b> {userDetails.user_id}
                  </p>
                </div>
              </TabPane>
              <TabPane tab="Wallets" key="2">
                <Table
                  dataSource={userDetails.wallets}
                  columns={walletColumns}
                />
              </TabPane>
              <TabPane tab="Withdrawals" key="3">
                <Card loading={getUserWithdrawalDetailsLoading}>
                  <Table
                    id="user_withdrawals_table"
                    dataSource={userWithdrawalDetails}
                    columns={withdrawalColumns}
                  />
                </Card>
              </TabPane>
              <TabPane tab="Requests" key="4">
                <Card loading={getUserWithdrawalRequestsLoading}>
                  <Table
                    id="user_requests_table"
                    dataSource={userWithdrawalRequestsDetails.map(request => ({
                      ...request,
                      withdrawal_type:
                        request.user_withdraw_option.withdrawal_type,
                    }))}
                    columns={withdrawalRequestColumns}
                  />
                </Card>
              </TabPane>
              <TabPane tab="Deposits" key="5">
                <Card loading={getUserDepositDetailsLoading}>
                  <Table
                    id="user_deposit_table"
                    dataSource={userDepositDetails.map(deposit => ({
                      ...deposit,
                      deposit_type_name:
                        deposit.user_deposits_option.deposit_type_name,
                      status: deposit.payment_deposit.deposit_status,
                    }))}
                    columns={depositColumns}
                  />
                </Card>
              </TabPane>
              <TabPane tab="Slots" key="6">
                <Card loading={getUserSlotDetailsLoading}>
                  <Table dataSource={userSlotDetails} columns={slotsColumns} />
                </Card>
              </TabPane>
            </Tabs>
          </Modal>
        )}
      </div>
    </div>
  );
};
export default AllUsers;
