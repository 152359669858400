import React, { useEffect, useMemo, useState } from "react";
import api from "../../api";
import { Alert } from "reactstrap";
import { Card } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../Withdrawals/GroupsTable";
import { CSVLink } from "react-csv";

const AllWallets = () => {
  const [allWallets, setAllWallets] = useState([]);
  const [getWalletsLoading, setGetWalletsLoading] = useState(false);
  const [getWalletsError, setGetWalletsError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone No.",
        accessor: "phone_number",
      },
      {
        Header: "Base Value",
        accessor: "base_wallet_value",
        Cell: data => data.value / 100,
      },
      {
        Header: "Local Value",
        accessor: "local_wallet_value",
        Cell: data => (data.value / 100).toLocaleString(),
      },
      {
        Header: "Wallet Type",
        accessor: "wallet_type",
      },
      {
        Header: "Status",
        accessor: "account_status",
        Cell: data => data.row.original.user_profile?.account_status,
      },
      {
        Header: "Verified",
        accessor: "updated_at",
        Cell: data => data.row.original.user_profile?.is_verified.toString(),
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: data => new Date(data.value).toLocaleString(),
      },
    ],

    []
  );

  const getAllWallets = (limit, page, search) => {
    setGetWalletsLoading(true);
    api.commerce
      .getAllUserWallets(limit, page, search)
      .then(res => {
        setGetWalletsLoading(false);
        if (res.status === 200) {
          setAllWallets(res.data.data.users);
          setPageCount(res.data.data.totalPages);
        }
      })
      .catch(err => {
        setGetWalletsLoading(false);
        setGetWalletsError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    getWalletsError &&
      setTimeout(() => {
        setGetWalletsError(null);
      }, 3000);
  });

  const acceptedWalletHeaders = [
    {
      label: "User",
      key: "user",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Phone Number",
      key: "phone_number",
    },
    {
      label: "Wallet Type",
      key: "wallet_type",
    },
    {
      label: "Base Amount",
      key: "base_amount",
    },
    {
      label: "Local Amount",
      key: "base_amount",
    },
    {
      label: "Status",
      key: "status",
    },
    {
      label: "Verified",
      key: "verified",
    },
    {
      label: "Created Date",
      key: "created_at",
    },
  ];

  const exportButton = (
    <CSVLink
      id="csvDownloadBtn"
      key="all-users-download"
      data={allWallets.map(wallet => ({
        ...wallet,
        user:
          wallet.user_profile.first_name + " " + wallet.user_profile.last_name,
        email: wallet.user_profile.email,
        phone_number: wallet.user_profile.phone_num,
        status: wallet.user_profile.account_status,
        verified: wallet.user_profile.is_verified,
        created_at: new Date(wallet.created_at).toLocaleString(),
        local_amount: wallet.local_wallet_value / 100,
        base_amount: wallet.base_wallet_value / 100,
      }))}
      headers={acceptedWalletHeaders}
      filename={`all-wallets-data-${localStorage.getItem("country_code")}.csv`}
      target="_blank"
    >
      <button className="btn btn-primary">Export Wallets</button>
    </CSVLink>
  );

  useEffect(() => {
    getAllWallets(pageSize, pageIndex + 1, "");
  }, [pageIndex, pageSize]);

  useEffect(() => {
    getAllWallets(pageSize, 1, filters);
  }, [filters]);

  //meta title
  document.title = "Wallets";

  return (
    <div className="page-content">
      {getWalletsError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getWalletsError}
          toggle={() => setGetWalletsError(null)}
        >
          {getWalletsError}
        </Alert>
      )}
      <div className="container-fluid">
        <Breadcrumbs title="Wallets" breadcrumbItem="All Wallets" />
        <Card loading={getWalletsLoading}>
          <TableContainer
            columns={columns}
            data={allWallets.map(wallet => ({
              ...wallet,
              email: wallet.user_profile.email,
              phone_number: wallet.user_profile.phone_num,
            }))}
            addButtonText={null}
            setShowDetailsModal={() => {}}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={pageSize}
            className="custom-header-css"
            exportButton={exportButton}
            pageCount={pageCount}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            controlledPageIndex={pageIndex}
          />
        </Card>
      </div>
    </div>
  );
};
export default AllWallets;
