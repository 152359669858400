import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const createPermission = createAsyncThunk(
  "admin/createPermission",
  async (body, thunkAPI) =>
    api.admin
      .createPermission(body)
      .then(res => {
        if (res.status === 201) {
          return res.data.data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      })
      .catch(err =>
        thunkAPI.rejectWithValue({
          error: err.response?.data || err.message,
        })
      )
);

export const createRole = createAsyncThunk(
  "admin/createRole",
  async (body, thunkAPI) =>
    api.admin
      .createRole(body)
      .then(res => {
        if (res.status === 200) {
          return res.data;
        } else {
          return thunkAPI.rejectWithValue(res.data);
        }
      })
      .catch(err =>
        thunkAPI.rejectWithValue({
          error: err.response?.data || err.message,
        })
      )
);

export const getAllRoles = createAsyncThunk(
  "admin/getAllRoles",
  async (body, thunkAPI) =>
    api.admin
      .getAllRoles()
      .then(res => {
        if (res.status === 200) {
          return res.data.data;
        } else {
          return thunkAPI.rejectWithValue(res.data);
        }
      })
      .catch(err =>
        thunkAPI.rejectWithValue({
          error: err.response?.data || err.message,
        })
      )
);

export const getAllAdmins = createAsyncThunk(
  "admin/getAllAdmins",
  async (body, thunkAPI) =>
    api.admin
      .getAllAdmins()
      .then(res => {
        if (res.status === 200) {
          return res.data.data;
        } else {
          return thunkAPI.rejectWithValue(res.data);
        }
      })
      .catch(err =>
        thunkAPI.rejectWithValue({
          error: err.response?.data || err.message,
        })
      )
);

export const loginAdmin = createAsyncThunk(
  "loginAdmin",
  async ({ email, password, ip, history }, thunkAPI) =>
    api.admin
      .loginAdmin({ email, password, ip })
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem("betty_token", res.data?.data.token);
          history.push("/dashboard");
          return res.data?.data;
        } else {
          return thunkAPI.rejectWithValue(res.data);
        }
      })
      .catch(err =>
        thunkAPI.rejectWithValue({
          error: err.response?.data || err.message,
        })
      )
);

export const registerNewAdmin = createAsyncThunk(
  "admin/registerNewAdmin",
  async (body, thunkAPI) =>
    api.admin
      .createAdmin(body)
      .then(res => {
        if (res.status === 200) {
          sessionStorage.setItem("signUpData", JSON.stringify(res.data));
          return res.data;
        } else {
          return thunkAPI.rejectWithValue(res.data);
        }
      })
      .catch(err =>
        thunkAPI.rejectWithValue({
          error: err.response?.data || err.message,
        })
      )
);
export const updateAdminDetails = createAsyncThunk(
  "admin/updateAdminDetails",
  async (body, thunkAPI) =>
    api.admin
      .updateAdmin(body)
      .then(res => {
        if (res.status === 200) {
         console.log('updated admin');
          return res.data;
        } else {
          return thunkAPI.rejectWithValue(res.data);
        }
      })
      .catch(err =>
        thunkAPI.rejectWithValue({
          error: err.response?.data || err.message,
        })
      )
);

export const deleteAdmin = createAsyncThunk(
  "admin/updateAdminDetails",
  async (body, thunkAPI) =>
    api.admin
      .deleteAdmin(body)
      .then(res => {
        if (res.status === 200) {
          console.log("admin deleted");
          return res.data;
        } else {
          return thunkAPI.rejectWithValue(res.data);
        }
      })
      .catch(err =>
        thunkAPI.rejectWithValue({
          error: err.response?.data || err.message,
        })
      )
);
export const logOut = createAsyncThunk("logout", async history => {
  localStorage.removeItem("betty_token");
  localStorage.removeItem("authUser");
  sessionStorage.clear();
  history.push("/login");
});
