import React, { useEffect, useState } from "react";

import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import Pages404 from "./pages/Utility/pages-404";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";
import { updateAdminDetails } from "./redux/reducers/admin/signin";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { useDispatch } from "react-redux";

// Import scss
import "antd/dist/antd.min.css";
import "./assets/scss/theme.scss";
import "./app.less";
import axios from "axios";
import { message } from "antd";

const App = () => {
  const [redirect, setRedirect] = useState(null);

  function getLayout() {
    let layoutCls = VerticalLayout;
    document.body.setAttribute("data-sidebar", "dark");
    return layoutCls;
  }
  const dispatch = useDispatch();

  const getIpAddress = () => {
    axios
      .get(`https://ipapi.co/json/`)
      .then(res => {
        if (res.status === 200) {
          if (res.data.ip !== "18.133.131.7") {
            setRedirect("/pages-404");
          }
        } else setRedirect("/pages-404");
      })
      .catch(err => {
        message.error(err.response?.data.message || err.message);
        setRedirect("/pages-404");
      });
  };

  useEffect(() => {
    const adminDetails = localStorage.getItem("authUser");
    adminDetails && dispatch(updateAdminDetails(JSON.parse(adminDetails)));
  });

  useEffect(() => {
    getIpAddress();
  }, []);

  const Layout = getLayout();

  if (redirect) return <Pages404 message="You are not authorized to view this page" />;
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          <Route>
            <Redirect to="/pages-404" replace />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
};
export default App;
