import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const CustomModal = ({
  visible,
  onOk,
  title,
  closeModal,
  content,
  loading,
  okText,
  cancelText,
}) => {
  return (
    <Modal isOpen={visible} toggle={closeModal}>
      {title && (
        <ModalHeader toggle={closeModal} tag="h4">
          {title || ""}
        </ModalHeader>
      )}
      {content && <ModalBody>{content || ""}</ModalBody>}
      <div className="modal-footer">
        {cancelText && (
          <button
            type="button"
            onClick={closeModal}
            className="btn btn-danger"
            data-dismiss="modal"
          >
            {cancelText || "Cancel"}
          </button>
        )}
        {okText && (
          <button
            onClick={onOk}
            loading={loading ? "true" : "false"}
            type="button"
            className="btn btn-success "
          >
            {okText || Ok}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default CustomModal;
