import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import CustomModal from "../../reusables/CustomModal";
import TableContainer from "../../components/Common/TableContainer";
import api from "../../api";
import { message } from "antd";

const LatestTranaction = () => {
  const [deposits, setDeposits] = useState([]);
  const [getDepositsLoading, setGetDepositsLoading] = useState(false);
  const [getDepositsError, setGetDepositsError] = useState(null);
  const [customModal, setCustomModal] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "user_wallet",
        Cell: data =>
          data.value.user_profile.first_name +
          " " +
          data.value.user_profile.last_name,
      },
      {
        Header: "Account Level",
        accessor: "transaction_reference",
        Cell: data => data.row.original.user_wallet?.user_profile.account_level,
      },
      {
        Header: "Base Amount",
        accessor: "base_amount_deposits",
        Cell: data => data.value / 100,
      },
      {
        Header: "Local Amount",
        accessor: "local_amount_deposits",
        Cell: data => (data.value / 100).toLocaleString(),
      },
      {
        Header: "Date",
        accessor: "deposits_date",
        Cell: data => new Date(data.value).toLocaleString(),
      },
      {
        Header: "Type",
        accessor: "user_deposits_option",
        Cell: data => data.value.deposit_type_name,
      },
    ],

    []
  );

  const getAllDeposits = (limit, page, search) => {
    setGetDepositsLoading(true);
    api.commerce
      .getAllDeposits(limit, page, search)
      .then(res => {
        setGetDepositsLoading(false);
        if (res.status === 200) {
          setDeposits(res.data.data.deposits);
        }
      })
      .catch(err => {
        setGetDepositsLoading(false);
        message.error(err.response?.data.message || err.message);
      });
  };

  const openDepositDetailsModal = deposit => {
    setCustomModal(
      <CustomModal
        title={<b>Deposit Details</b>}
        content={
          <div>
            <p>
              <b>Transaction Reference:</b> {deposit.transaction_reference}
            </p>
            <p>
              <b>Base Amount:</b> {deposit.base_amount_deposits / 100}
            </p>
            <p>
              <b>Local Amount:</b>{" "}
              {(deposit.local_amount_deposits / 100).toLocaleString()}
            </p>
            <p>
              <b>Transaction Date:</b>{" "}
              {new Date(deposit.deposits_date).toString()}
            </p>
            <p>
              <b>Country:</b>{" "}
              {deposit.user_deposits_option.config_details.country}
            </p>
            <p>
              <b>Deposit Type Name:</b>{" "}
              {deposit.user_deposits_option.deposit_type_name}
            </p>
            <p>
              <b>Deposit Type Status:</b>{" "}
              {deposit.user_deposits_option.deposits_options_status}
            </p>

            <p>
              <b>User Name:</b> {deposit.user_wallet.user_profile.first_name}{" "}
              {deposit.user_wallet.user_profile.last_name}
            </p>
            <p>
              <b>User Email:</b> {deposit.user_wallet.user_profile.email}
            </p>
            <p>
              <b>Phone Number:</b> {deposit.user_wallet.user_profile.phone_num}
            </p>
            <p>
              <b>Account Level:</b>{" "}
              {deposit.user_wallet.user_profile.account_level}
            </p>
            <p>
              <b>Account Status:</b>{" "}
              {deposit.user_wallet.user_profile.account_status}
            </p>
            <p>
              <b>Is Verified:</b>{" "}
              {deposit.user_wallet.user_profile.is_verified.toString()}
            </p>
            <p>
              <b>User ID:</b> {deposit.user_wallet.user_profile.user_id}
            </p>
          </div>
        }
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  useEffect(() => {
    getDepositsError &&
      setTimeout(() => {
        setGetDepositsError(null);
      }, 3000);
  });

  useEffect(() => {
    getAllDeposits(10, 1, "");
  }, []);

  return (
    <React.Fragment>
      <Card>
        {customModal}
        <CardBody>
          <div className="mb-4 h4 card-title">Latest Transaction(s)</div>
          <TableContainer
            columns={columns}
            data={deposits.slice(0, 10)}
            addButtonText={null}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={10}
            setShowDetailsModal={val => openDepositDetailsModal(val.original)}
            className="custom-header-css"
            addNewGroup={() => {}}
            />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

LatestTranaction.propTypes = {
  orders: PropTypes.array,
};

export default withRouter(LatestTranaction);
