import React, { useEffect, useMemo, useState } from "react";
import api from "../../api";
import { Alert } from "reactstrap";
import { Card } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../Withdrawals/GroupsTable";
import { CSVLink } from "react-csv";
import moment from "moment";

const SumOfWins = () => {
  const [bingoHistory, setBingoHistory] = useState({});
  const [slots, setSlots] = useState({});
  const [getHistoryLoading, setGetHistoryLoading] = useState(false);
  const [getHistoryError, setGetHistoryError] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");

  const columns = [
    {
      Header: "Date",
      accessor: "created_at",
    },
    {
      Header: "Bingo 75",
      accessor: "bingo75",
    },
    {
      Header: "Bingo 90",
      accessor: "bingo90",
    },
    {
      Header: "Fast 5",
      accessor: "fast5",
    },
    {
      Header: "Slots",
      accessor: "slots",
    },
    {
      Header: "Grand Total",
      accessor: "total",
    },
  ];

  const getAllReports = () => {
    setGetHistoryLoading(true);
    api.commsAxiosInstance
      .get("/report/bingo-games-history")
      .then(res => {
        setGetHistoryLoading(false);
        if (res.status === 200) {
          const data = [];
          for (let dayEvents of res.data.data) {
            data.push(...dayEvents.response);
          }

          const dateObj = {};
          for (let event of data) {
            const datePlayed = moment(event.game_event?.game_start).format(
              "DD MMMM"
            );
            const totalWin = event.jackpot
              .map(jp => Number(jp.base_total_win) / 100)
              .reduce((a, b) => a + b, 0);
            const gameType = event.game_profile?.game_type?.game_type;

            dateObj[datePlayed] = dateObj[datePlayed]
              ? [...dateObj[datePlayed], { totalWin, gameType }]
              : [{ totalWin, gameType }];
          }

          for (let date in dateObj) {
            const fast5TotalWins = getTotalWins(
              getGameCategory(dateObj[date], "fast 5")
            );
            const bingo75TotalWins = getTotalWins(
              getGameCategory(dateObj[date], "bingo 75")
            );
            const bingo90TotalWins = getTotalWins(
              getGameCategory(dateObj[date], "bingo 90")
            );

            dateObj[date] = {
              created_at: date,
              fast5: fast5TotalWins.toFixed(1),
              bingo75: bingo75TotalWins.toFixed(1),
              bingo90: bingo90TotalWins.toFixed(1),
            };
          }
          setBingoHistory(dateObj);
        }
      })
      .catch(err => {
        setGetHistoryLoading(false);
        setGetHistoryError(err.response?.data.message || err.message);
      });
  };

  const getAllSlots = (limit, page) => {
    setGetHistoryLoading(true);
    api.commerce
      .getAllSlots(limit, page)
      .then(res => {
        setGetHistoryLoading(false);
        if (res.status === 200) {
          const data = res.data.data?.transactions;
          let slotsObj = {};
          for (let event of data) {
            const datePlayed = moment(Number(event.created)).format("DD MMMM");
            const rate =
            (Number(event.local_amount) * 100) / Number(event.base_amount);

            slotsObj[datePlayed] = slotsObj[datePlayed]
              ? (slotsObj[datePlayed] += Number(event.wins) / rate)
              : Number(event.wins) / rate;
          }

          setSlots(slotsObj);
        }
      })
      .catch(err => {
        setGetHistoryLoading(false);
        setGetSlotsError(err.response?.data.message || err.message);
      });
  };

  const getGameCategory = (games, category) =>
    games.filter(game => game.gameType === category);
  const getTotalWins = games =>
    games.map(game => game.totalWin).reduce((a, b) => a + b, 0);

  useEffect(() => {
    getHistoryError &&
      setTimeout(() => {
        setGetHistoryError(null);
      }, 3000);
  });

  useEffect(() => {
    getAllReports();
    getAllSlots(100000000, 1);
  }, []);

  const acceptedHeaders = [
    {
      label: "Date",
      key: "created_at",
    },
    {
      label: "Bingo 75",
      key: "bingo75",
    },
    {
      label: "Bingo 90",
      key: "bingo90",
    },
    {
      label: "Fast 5",
      key: "fast5",
    },
    {
      label: "Slots",
      key: "slots",
    },
    {
      label: "Grand Total",
      key: "total",
    },
  ];

  //meta title

  const getAllData = (bingo, slots) => {
    const displayData = [];

    Object.keys(bingo).forEach(day => {
      const game = {
        ...bingo[day],
        slots: slots[day]?.toLocaleString() || 0,
        total:
          Number(bingo[day].fast5) +
          Number(bingo[day].bingo75) +
          Number(bingo[day].bingo90) +
          Number(slots[day] || 0),
      };
      displayData.push(game);
    });
    Object.keys(slots).forEach(day => {
      if (!bingo[day]) {
        const game = {
          created_at: day,
          fast5: 0,
          bingo75: 0,
          bingo90: 0,
          slots: slots[day]?.toLocaleString() || 0,
          total: slots[day]?.toLocaleString() || 0,
        };
        displayData.push(game);
      }
    });
    return displayData.sort(
      (a, b) => moment(a.created_at) - moment(b.created_at)
    );
  };

  const exportButton = (
    <CSVLink
      id="csvDownloadBtn"
      key="all-users-download"
      data={getAllData(bingoHistory, slots)}
      headers={acceptedHeaders}
      filename={`all-wins-reports.csv`}
      target="_blank"
    >
      <button className="btn btn-primary">Export Report</button>
    </CSVLink>
  );

  document.title = "Winnings Report";

  return (
    <div className="page-content">
      {getHistoryError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getHistoryError}
          toggle={() => setGetHistoryError(null)}
        >
          {getHistoryError}
        </Alert>
      )}
      <div className="container-fluid">
        <Breadcrumbs title="Reports" breadcrumbItem="Winnngs Report" />
        <Card loading={getHistoryLoading}>
          <TableContainer
            columns={columns}
            data={getAllData(bingoHistory, slots)}
            addButtonText={null}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={20}
            setShowDetailsModal={() => {}}
            className="custom-header-css"
            addNewGroup={() => {}}
            exportButton={exportButton}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            controlledPageIndex={pageIndex}
            pageCount={pageCount}
          />
        </Card>
      </div>
    </div>
  );
};
export default SumOfWins;
