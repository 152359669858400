import React, { useEffect, useMemo, useState } from "react";
import api from "../../api";
import { Alert } from "reactstrap";
import { Card } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../Withdrawals/GroupsTable";
import { CSVLink } from "react-csv";
import moment from "moment";

const SumOfWins = () => {
  const [bingoHistory, setBingoHistory] = useState([]);
  const [getHistoryLoading, setGetHistoryLoading] = useState(false);
  const [getHistoryError, setGetHistoryError] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");

  const columns = [
    {
      Header: "Date",
      accessor: "created_at",
    },
    {
      Header: "Sum of Wagers",
      accessor: "wagers",
    },
    {
      Header: "Wins",
      accessor: "wins",
    },
    {
      Header: "GGR",
      accessor: "ggr",
    },
    {
      Header: "Margin",
      accessor: "margin",
    },
  ];

  const getAllReports = () => {
    setGetHistoryLoading(true);
    api.commsAxiosInstance
      .get("/report/bingo-games-history")
      .then(res => {
        setGetHistoryLoading(false);
        if (res.status === 200) {
          const data = [];
          for (let dayEvents of res.data.data) {
            data.push(...dayEvents.response);
          }

          const dateObj = {};
          for (let event of data) {
            const datePlayed = moment(event.game_event?.game_start).format(
              "DD MMMM"
            );
            const totalWin = event.jackpot
              .map(jp => Number(jp.base_total_win) / 100)
              .reduce((a, b) => a + b, 0);
            const totalSales = event.sales
              .map(sale => Number(sale.base_ticket_total) / 100)
              .reduce((a, b) => a + b, 0);
            const ggr = totalSales - totalWin;

            dateObj[datePlayed] = dateObj[datePlayed]
              ? [...dateObj[datePlayed], { totalWin, totalSales, ggr }]
              : [{ totalWin, totalSales, ggr }];
          }
          for (let date in dateObj) {
            const sumOfWages = dateObj[date]
              .map(game => game.totalSales)
              .reduce((a, b) => a + b, 0);
            const sumOfWins = dateObj[date]
              .map(game => game.totalWin)
              .reduce((a, b) => a + b, 0);
            const totalGGr = dateObj[date]
              .map(game => game.ggr)
              .reduce((a, b) => a + b, 0);
            const margin = Number((totalGGr / sumOfWages) * 100).toFixed(1);
            dateObj[date] = {
              created_at: date,
              wagers: sumOfWages.toFixed(1),
              wins: sumOfWins.toFixed(1),
              ggr: totalGGr.toFixed(1),
              margin,
            };
          }
          setBingoHistory(
            Object.values(dateObj).sort(
              (a, b) => moment(a.created_at) - moment(b.created_at)
            )
          );
        }
      })
      .catch(err => {
        setGetHistoryLoading(false);
        setGetHistoryError(err.response?.data.message || err.message);
      });
  };

  const getNewReports = () => {
    api.commsAxiosInstance
      .get("/report/all-games?page=1&limit=40&start_date=2022-09-01&end_date=2022-09-20")
      .then(res => {
        if (res.status === 200) {
       console.log(res.data)
        }
      })
      .catch(err => {
        setGetHistoryLoading(false);
        setGetHistoryError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    getHistoryError &&
      setTimeout(() => {
        setGetHistoryError(null);
      }, 3000);
  });

  useEffect(() => {
    getAllReports();
    getNewReports()
  }, []);

  const acceptedHeaders = [
    {
      label: "Date",
      key: "created_at",
    },
    {
      label: "Sum of Wagers",
      key: "wagers",
    },
    {
      label: "Wins",
      key: "wins",
    },
    {
      label: "GGR",
      key: "ggr",
    },
    {
      label: "Margin",
      key: "margin",
    },
  ];

  const exportButton = (
    <CSVLink
      id="csvDownloadBtn"
      key="all-users-download"
      data={bingoHistory}
      headers={acceptedHeaders}
      filename={`all-jackpots-reports.csv`}
      target="_blank"
    >
      <button className="btn btn-primary">Export Report</button>
    </CSVLink>
  );

  //meta title
  document.title = "Bingo Reports";

  return (
    <div className="page-content">
      {getHistoryError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getHistoryError}
          toggle={() => setGetHistoryError(null)}
        >
          {getHistoryError}
        </Alert>
      )}
      <div className="container-fluid">
        <Breadcrumbs title="Reports" breadcrumbItem="Daily Jackpots" />
        <Card loading={getHistoryLoading}>
          <TableContainer
            columns={columns}
            data={bingoHistory}
            addButtonText={null}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={20}
            setShowDetailsModal={() => {}}
            className="custom-header-css"
            addNewGroup={() => {}}
            exportButton={exportButton}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            controlledPageIndex={pageIndex}
            pageCount={pageCount}
          />
        </Card>
      </div>
    </div>
  );
};
export default SumOfWins;
