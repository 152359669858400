import React from "react";
import { Tabs } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import InReviewWithdrawals from "./InReviewWithdrawals";
import ApprovedWithdrawals from "./ApprovedWithdrawals";
import CancelledWithdrawals from "./CancelledWithdrawals";
import RejectedWithdrawals from "./RejectedWithdrawals";

const { TabPane } = Tabs;
const WithdrawalRequests = () => {
  //meta title
  document.title = "Withdrawal Requests";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Withdrawals" breadcrumbItem="Withdrawal Requests" />
        <Tabs defaultActiveKey="1">
          <TabPane tab="In Review" key="1">
            <InReviewWithdrawals />
          </TabPane>
          <TabPane tab="Approved" key="2">
            <ApprovedWithdrawals />
          </TabPane>
          <TabPane tab="Cancelled" key="3">
            <CancelledWithdrawals />
          </TabPane>
          <TabPane tab="Rejected" key="4">
            <RejectedWithdrawals />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default WithdrawalRequests;
