import React, { useEffect, useMemo, useState } from "react";
import api from "../../api";
import { Alert } from "reactstrap";
import { Card } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../Withdrawals/GroupsTable";
import { CSVLink } from "react-csv";

const UserReports = () => {
  const [bingoHistory, setBingoHistory] = useState([]);
  const [getHistoryLoading, setGetHistoryLoading] = useState(false);
  const [getHistoryError, setGetHistoryError] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState("");

  const columns = [
    {
      Header: "Date",
      accessor: "created_at",
    },
    {
      Header: "RDC",
      accessor: "rdc",
    },
    {
      Header: "DAU",
      accessor: "dau",
    },
    {
      Header: "Total Reg",
      accessor: "total_reg",
    },
    {
      Header: "FTD",
      accessor: "ftd",
    },
    {
      Header: "Conversion",
      accessor: "conversion",
    },
    {
      Header: "Funded Players",
      accessor: "funded_players",
    },
    {
      Header: "Sum of Deposits",
      accessor: "sum_of_deposits",
    },
  ];

  const getAllReports = () => {
    setGetHistoryLoading(true);
    api.commsAxiosInstance
      .get("/report/bingo-games-history")
      .then(res => {
        setGetHistoryLoading(false);
        if (res.status === 200) {
          //   setBingoHistory(res.data.data);
        }
      })
      .catch(err => {
        setGetHistoryLoading(false);
        setGetHistoryError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    getHistoryError &&
      setTimeout(() => {
        setGetHistoryError(null);
      }, 3000);
  });

  useEffect(() => {
    getAllReports();
  }, []);

  const acceptedSlotsHeaders = [
    {
      label: "Game Name",
      key: "gameName",
    },
  ];

  //   const exportButton = (
  //     <CSVLink
  //       id="csvDownloadBtn"
  //       key="all-users-download"
  //       data={bingoHistory.map(slot => ({
  //         ...slot,
  //         user: slot.user_profile.first_name + " " + slot.user_profile.last_name,
  //         created: new Date(Number(slot.created)).toLocaleString(),
  //         bet: slot.bet / 100,
  //         wins: slot.wins / 100,
  //         local_amount: slot.local_amount / 100,
  //         base_amount: slot.base_amount / 100,
  //       }))}
  //       headers={acceptedSlotsHeaders}
  //       filename={`all-slots-data-${localStorage.getItem("country")}.csv`}
  //       target="_blank"
  //     >
  //       <button className="btn btn-primary">Export Slots</button>
  //     </CSVLink>
  //   );

  //meta title
  document.title = "User Reports";

  return (
    <div className="page-content">
      {getHistoryError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getHistoryError}
          toggle={() => setGetHistoryError(null)}
        >
          {getHistoryError}
        </Alert>
      )}
      <div className="container-fluid">
        <Breadcrumbs title="Reports" breadcrumbItem="User Reports" />
        <Card loading={getHistoryLoading}>
          <TableContainer
            columns={columns}
            data={bingoHistory}
            addButtonText={null}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={20}
            setShowDetailsModal={() => {}}
            className="custom-header-css"
            addNewGroup={() => {}}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            controlledPageIndex={pageIndex}
            pageCount={pageCount}
          />
        </Card>
      </div>
    </div>
  );
};
export default UserReports;
