// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import api from "../../api";

import {
  Modal,
  Col,
  Row,
  Label,
  Form,
  FormFeedback,
  Input,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";
import { Card } from "antd";
import { Link } from "react-router-dom";
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "./GroupsTable";
import CustomModal from "../../reusables/CustomModal";

import * as Yup from "yup";
import { useFormik } from "formik";

const BingoRooms = () => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [gameRooms, setBingoRooms] = useState([]);
  const [getBingoRoomsLoading, setGetBingoRoomsLoading] = useState(false);
  const [getRoomError, setGetRoomError] = useState(null);
  const [createBingoRoomsLoading, setCreateBingoRoomsLoading] = useState(false);
  const [createRoomError, setCreateRoomError] = useState(null);
  const [editBingoRoom, setEditBingoRoom] = useState(null);
  const [deleteBingoRoomLoading, setDeleteBingoRoomLoading] = useState(false);
  const [customModal, setCustomModal] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Room Name",
        accessor: "group_name",
      },
      {
        Header: "Description",
        accessor: "group_description",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: data => data.value || "Open",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: data => new Date(data.value).toLocaleString(),
      },
      {
        Header: "Actions",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  setOpenAddGroupModal(true);
                  setEditBingoRoom(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleDeleteBingoRoom(data);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],

    []
  );

  const getAllBingoRooms = () => {
    setGetBingoRoomsLoading(true);
    api.games
      .getAllBingoGroups()
      .then(res => {
        setGetBingoRoomsLoading(false);
        if (res.status === 200) {
          setBingoRooms(res.data.data);
        }
      })
      .catch(err => {
        setGetBingoRoomsLoading(false);
        setGetRoomError(err.response?.data.message || err.message);
      });
  };

  const createBingoRoom = ({ group_name, group_description, wager_source }) => {
    setCreateBingoRoomsLoading(true);
    api.games
      .createBingoGroup({ group_name, group_description, wager_source })
      .then(res => {
        setCreateBingoRoomsLoading(false);
        if (res.status === 200) {
          getAllBingoRooms();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo Room Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoRoomsLoading(false);
        setCreateRoomError(err.response?.data.message || err.message);
      });
  };

  const updateBingoRoom = ({
    group_name,
    group_description,
    group_id,
    wager_source,
  }) => {
    console.log(wager_source);
    setCreateBingoRoomsLoading(true);
    api.games
      .updateBingoGroup({
        group_name,
        group_description,
        group_id,
        wager_source,
      })
      .then(res => {
        setCreateBingoRoomsLoading(false);
        if (res.status === 200) {
          getAllBingoRooms();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Bingo Room Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreateBingoRoomsLoading(false);
        setCreateRoomError(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    getAllBingoRooms();
  }, []);
  useEffect(() => {
    getRoomError &&
      setTimeout(() => {
        setGetRoomError(null);
      }, 3000);
  });
  useEffect(() => {
    createRoomError &&
      setTimeout(() => {
        setCreateRoomError(null);
      }, 3000);
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      group_id: editBingoRoom?.game_group_id,
      group_name: editBingoRoom?.group_name || "",
      group_description: editBingoRoom?.group_description || "",
      account_type: editBingoRoom?.account_type || "",
      wager_source: editBingoRoom?.wager_source || "",
      min_balance: editBingoRoom?.min_balance || "",
    },
    validationSchema: Yup.object({
      group_name: Yup.string().required("Please enter room name"),
      group_description: Yup.string().required("Please enter room description"),
      // account_type: Yup.string().required("Please select account type"),
      // min_balance: Yup.string().required("Please enter mininmum fund balance"),
      wager_source: Yup.string().required("Please select wager source"),
    }),
    onSubmit: values => {
      console.log("hi");
      if (editBingoRoom !== null) {
        updateBingoRoom(values);
        return;
      }
      console.log("yesss", editBingoRoom, values);
      createBingoRoom(values);
    },
  });

  const deleteBingoRoom = id => {
    setDeleteBingoRoomLoading(true);
    api.games
      .deleteBingoGroup(id)
      .then(res => {
        setDeleteBingoRoomLoading(false);
        if (res.status === 200) {
          getAllBingoRooms();
          setCustomModal(
            <CustomModal
              title="Succes!"
              content="Bingo Room Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setDeleteBingoRoomLoading(false);
        setCreateRoomError(err.response?.data.message || err.message);
      });
  };

  const handleDeleteBingoRoom = data => {
    setCustomModal(
      <CustomModal
        title="Delete Bingo Room?"
        loading={deleteBingoRoomLoading}
        content={<p>Do you want to delete this game room?</p>}
        onOk={() => {
          deleteBingoRoom(data.game_group_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  //meta title
  document.title = "Bingo Rooms";

  return (
    <div className="page-content">
      {getRoomError && (
        <Alert
          color="danger"
          fade={true}
          isOpen={!!getRoomError}
          toggle={() => setGetRoomError(null)}
        >
          {getRoomError}
        </Alert>
      )}
      {customModal}
      <div className="container-fluid">
        <Breadcrumbs title="Bingo" breadcrumbItem="Bingo Rooms" />
        <Card loading={getBingoRoomsLoading}>
          <TableContainer
            columns={columns}
            data={gameRooms}
            addButtonText="Add New Room"
            setShowDetailsModal={() => {}}
            isGlobalFilter={true}
            isAddOptions={true}
            customPageSize={10}
            className="custom-header-css"
            addNewGroup={() => setOpenAddGroupModal(true)}
          />
        </Card>
      </div>
      <Modal
        size="lg"
        isOpen={openAddGroupModal}
        toggle={() => {
          setEditBingoRoom(null);
          setOpenAddGroupModal(false);
        }}
      >
        {createRoomError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!createRoomError}
            toggle={() => setCreateRoomError(null)}
          >
            {createRoomError}
          </Alert>
        )}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {editBingoRoom === null || Object.keys(editBingoRoom).length === 0
              ? "Add New Bingo Room"
              : "Edit Bingo Room"}
          </h5>
          <button
            type="button"
            onClick={() => {
              setEditBingoRoom(null);
              setOpenAddGroupModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="mb-3">
              <Label>Room name</Label>
              <Input
                type="text"
                className="form-control"
                id="group_name"
                name="group_name"
                placeholder="Enter room Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.group_name || ""}
                invalid={
                  validation.touched.group_name && validation.errors.group_name
                    ? true
                    : false
                }
              />
              {validation.touched.group_name && validation.errors.group_name ? (
                <FormFeedback type="invalid">
                  {validation.errors.group_name}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label htmlFor="group_description">Description</Label>
              <Input
                type="textarea"
                className="form-control"
                id="group_description"
                name="group_description"
                placeholder="Enter game room description"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.group_description || ""}
                invalid={
                  validation.touched.group_description &&
                  validation.errors.group_description
                    ? true
                    : false
                }
              />
              {validation.touched.group_description &&
              validation.errors.group_description ? (
                <FormFeedback type="invalid">
                  {validation.errors.group_description}
                </FormFeedback>
              ) : null}
            </div>

            <p className="mt-4">
              <b>Group Rules</b>
            </p>

            <Row>
              <Col lg={4}>
                <div className="mb-3">
                  <Label htmlFor="formrow-InputState">Wager Source</Label>
                  <Input
                    type="select"
                    id="wager_source"
                    name="wager_source"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.wager_source || ""}
                    invalid={
                      validation.touched.wager_source &&
                      validation.errors.wager_source
                        ? true
                        : false
                    }
                  >
                    <option defaultValue>Choose...</option>
                    <option value="real funds">Real Money</option>
                    <option value="loyalty point">Loyalty Point</option>
                    <option value="vip funds">Vip funds</option>
                    <option value="bonus funds">Bonus Funds</option>
                  </Input>
                  {validation.touched.wager_source &&
                  validation.errors.wager_source ? (
                    <FormFeedback type="invalid">
                      {validation.errors.wager_source}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={4}>
                <div className="mb-3">
                  <Label htmlFor="account_type">Account Type</Label>
                  <Input
                    type="select"
                    id="account_type"
                    className="form-control"
                    name="account_type"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.account_type || ""}
                    invalid={
                      validation.touched.account_type &&
                      validation.errors.account_type
                        ? true
                        : false
                    }
                  >
                    <option defaultValue>Choose...</option>
                    <option value="funded">Funded Accounts</option>
                    <option value="free">Free Accounts</option>
                  </Input>
                  {validation.touched.account_type &&
                  validation.errors.account_type ? (
                    <FormFeedback type="invalid">
                      {validation.errors.account_type}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={4}>
                <div className="mb-3">
                  <Label htmlFor="min_balance">Min. Real Money Balance</Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="min_balance"
                    name="min_balance"
                    placeholder="Enter amount"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.min_balance || ""}
                    invalid={
                      validation.touched.min_balance &&
                      validation.errors.min_balance
                        ? true
                        : false
                    }
                  />
                  {validation.touched.min_balance &&
                  validation.errors.min_balance ? (
                    <FormFeedback type="invalid">
                      {validation.errors.min_balance}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <p className="mt-4">
              <b>Deposit Rules</b>
            </p>
            <div className="mb-3">
              <div>
                <Row>
                  <Col lg={4}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="deposit_rule"
                        id="ftd"
                        value="ftd"
                        defaultChecked
                      />
                      <label className="form-check-label" htmlFor="ftd">
                        First Time Deposit
                      </label>
                    </div>
                  </Col>

                  <Col>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="deposit_rule"
                        id="redeposit"
                        value="redeposit"
                      />
                      <label className="form-check-label" htmlFor="redeposit">
                        Redeposit
                      </label>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="deposit_rule"
                        id="anydeposit"
                        value="any"
                      />
                      <label className="form-check-label" htmlFor="anydeposit">
                        Any Deposit
                      </label>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="mb-3">
              <div>
                <Input
                  type="number"
                  className="form-control"
                  id="minimum_amount"
                  style={{
                    width: 100,
                    display: "inline-block",
                    margin: "0 5px",
                  }}
                  placeholder="Minimum deposit"
                />{" "}
                amount within
                <Input
                  type="number"
                  className="form-control"
                  id="minimum_amount_days"
                  style={{
                    width: 100,
                    display: "inline-block",
                    margin: "0 5px",
                  }}
                  placeholder=""
                />{" "}
                days
              </div>
            </div>

            <p className="mt-4">
              <b>Registration Rules</b>
            </p>
            <div className="form-check form-checkbox">
              <input
                type="checkbox"
                className="form-check-input"
                id="register_within"
                style={{ marginTop: 12 }}
              />
              <label className="form-check-label" htmlFor="register_within">
                <div>
                  Registration within{" "}
                  <Input
                    type="number"
                    className="form-control"
                    id="register_within_days"
                    style={{
                      width: 70,
                      display: "inline-block",
                      margin: "0 5px",
                    }}
                    placeholder=""
                  />{" "}
                  days
                </div>
              </label>
            </div>
            <div className="form-check form-checkbox">
              <input
                type="checkbox"
                className="form-check-input"
                id="register_after"
                style={{ marginTop: 12 }}
              />
              <label className="form-check-label" htmlFor="register_after">
                <div>
                  Registration after{" "}
                  <Input
                    type="number"
                    className="form-control"
                    id="register_after_days"
                    style={{
                      width: 70,
                      display: "inline-block",
                      margin: "0px 5px 0 12px",
                    }}
                    placeholder=""
                  />{" "}
                  days
                </div>
              </label>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => setOpenAddGroupModal(false)}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={validation.handleSubmit}
            disabled={createBingoRoomsLoading}
            loading={createBingoRoomsLoading ? "true" : "false"}
          >
            Save changes
          </button>
        </div>
      </Modal>
    </div>
  );
};
BingoRooms.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default BingoRooms;
