// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import {
  Modal,
  Col,
  Row,
  Label,
  Form,
  Input,
  FormFeedback,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import api from "../../../api";
import { Link } from "react-router-dom";

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "../GroupsTable";
import CustomModal from "../../../reusables/CustomModal";
import { Card } from "antd";

const JackpotPatterns = () => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [allPatterns, setAllPatterns] = useState([]);
  const [patternParams, setPatternParams] = useState([]);
  const [validationError, setValidationError] = useState(null);
  const [getPatternsError, setGetPatternsError] = useState(null);
  const [getPatternsLoading, setGetPatternsLoading] = useState(false);
  const [createPatternsLoading, setCreatePatternsLoading] = useState(false);
  const [createPatternsError, setCreatePatternsError] = useState(false);
  const [deletePatternsLoading, setDeletePatternsLoading] = useState(false);
  const [customModal, setCustomModal] = useState(null);
  const [editPattern, setEditPattern] = useState(null);

  useEffect(() => {
    getAllBingoPatterns();
  }, []);

  useEffect(() => {
    editPattern &&
      setPatternParams(
        editPattern.pattern_params.map(val => ({ label: val.name, value: val }))
      );
  }, [editPattern]);

  useEffect(() => {
    getPatternsError &&
      setTimeout(() => {
        setGetPatternsError(null);
      }, 3000);
    createPatternsError &&
      setTimeout(() => {
        setCreatePatternsError(null);
      }, 3000);
    validationError &&
      setTimeout(() => {
        setValidationError(null);
      }, 3000);
  });

  const getAllBingoPatterns = () => {
    setGetPatternsLoading(true);
    api.games
      .getAllJackpotPatterns()
      .then(res => {
        setGetPatternsLoading(false);
        if (res.status === 200) {
          setAllPatterns(res.data.data);
        }
      })
      .catch(err => {
        setGetPatternsLoading(false);
        setError(err.response?.data.message || err.message);
      });
  };

  const createPattern = payload => {
    setCreatePatternsLoading(true);
    api.games
      .createJackpotPattern(payload)
      .then(res => {
        setCreatePatternsLoading(false);
        if (res.status === 201) {
          getAllBingoPatterns();
          setCustomModal(
            <CustomModal
              title="Succes!"
              content="Jackpot Pattern Created Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreatePatternsLoading(false);
        setCreatePatternsError(err.response?.data.message || err.message);
      });
  };

  const handleEditPattern = (id, payload) => {
    setCreatePatternsLoading(true);
    api.games
      .updateJackpotPattern(id, payload)
      .then(res => {
        setCreatePatternsLoading(false);
        if (res.status === 200) {
          getAllBingoPatterns();
          setCustomModal(
            <CustomModal
              title="Success!"
              content="Jackpot Pattern Edited Successfully!"
              onOk={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                setEditPattern(null);
                validation.handleReset();
              }}
              closeModal={() => {
                setCustomModal(null);
                setOpenAddGroupModal(false);
                validation.handleReset();
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setCreatePatternsLoading(false);
        setCreatePatternsError(err.response?.data.message || err.message);
      });
  };

  const deleteBingoPattern = id => {
    setDeletePatternsLoading(true);
    api.games
      .deleteJackpotPattern(id)
      .then(res => {
        setDeletePatternsLoading(false);
        if (res.status === 200) {
          getAllBingoPatterns();
          setCustomModal(
            <CustomModal
              title="Succes!"
              content="Jackpot Pattern Deleted Successfully!"
              onOk={() => {
                setCustomModal(null);
              }}
              closeModal={() => {
                setCustomModal(null);
              }}
              okText="Ok"
              visible={true}
            />
          );
        }
      })
      .catch(err => {
        setDeletePatternsLoading(false);
        setCreatePatternsError(err.response?.data.message || err.message);
      });
  };

  const handleDeletePattern = data => {
    setCustomModal(
      <CustomModal
        title="Delete Jackpot Pattern?"
        loading={deletePatternsLoading}
        content={<p>Do you want to delete this jackpot pattern?</p>}
        onOk={() => {
          deleteBingoPattern(data.game_jackpot_pattern_id);
        }}
        okText="Delete"
        closeModal={() => setCustomModal(false)}
        cancelText="Cancel"
        visible={true}
      />
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Pattern Name",
        accessor: "pattern_name",
      },
      {
        Header: "Pattern Params",
        accessor: "pattern_params",
        Cell: data =>
          data?.value.map(param => (
            <span key={param.name} style={{ margin: "0 10px" }}>
              {param.name}
            </span>
          )),
      },
      {
        Header: "Actions",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  setOpenAddGroupModal(true);
                  setEditPattern(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleDeletePattern(data);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      pattern_name: editPattern?.pattern_name || "",
      pattern_params: "",
    },
    validationSchema: Yup.object({
      pattern_name: Yup.string().required("Please enter pattern name"),
    }),
    onSubmit: values => {
      if (!patternParams)
        return setValidationError("Please select pattern params");
      values["pattern_params"] = patternParams.map(pattern => pattern.value);
      editPattern
        ? handleEditPattern(editPattern.game_jackpot_pattern_id, values)
        : createPattern(values);
    },
  });

  //meta title
  document.title = "Jackpot Pattern";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Bingo" breadcrumbItem="Jackpot Pattern" />

        {customModal}

        {getPatternsError && (
          <Alert
            color="danger"
            fade={true}
            isOpen={!!getPatternsError}
            toggle={() => setGetPatternsError(null)}
          >
            {getPatternsError}
          </Alert>
        )}
        <Card loading={getPatternsLoading}>
          <TableContainer
            columns={columns}
            data={allPatterns}
            addButtonText="Create Jackpot Pattern"
            isGlobalFilter={true}
            setShowDetailsModal={() => {}}
            isAddOptions={true}
            customPageSize={10}
            className="custom-header-css"
            addNewGroup={() => setOpenAddGroupModal(true)}
          />
        </Card>
      </div>

      <Modal
        size="lg"
        isOpen={openAddGroupModal}
        toggle={() => {
          setPatternParams([]);
          setEditPattern(null);
          setOpenAddGroupModal(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add New Pattern
          </h5>
          <button
            type="button"
            onClick={() => {
              setPatternParams([]);
              setEditPattern(null);
              setOpenAddGroupModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {validationError && (
            <Alert
              color="danger"
              fade={true}
              isOpen={!!validationError}
              toggle={() => setValidationError(null)}
            >
              {validationError}
            </Alert>
          )}
          {createPatternsError && (
            <Alert
              color="danger"
              fade={true}
              isOpen={!!createPatternsError}
              toggle={() => setValidationError(null)}
            >
              {createPatternsError}
            </Alert>
          )}
          <Form>
            <div className="mb-3">
              <Row>
                <Col lg={6}>
                  <Label htmlFor="game_type">
                    <b>Pattern Name</b>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="pattern_name"
                    name="pattern_name"
                    placeholder="Enter Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.pattern_name || ""}
                    invalid={
                      validation.touched.pattern_name &&
                      validation.errors.pattern_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.pattern_name &&
                  validation.errors.pattern_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.pattern_name}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="reward_round_count">
                      <b>Pattern Parameters</b>
                    </Label>
                    <Select
                      isMulti={true}
                      value={patternParams}
                      isOptionDisabled={val =>
                        patternParams.find(
                          pattern => pattern.label === val.label
                        ) !== undefined
                      }
                      onChange={val => {
                        setPatternParams(val);
                      }}
                      options={optionGroup}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => setOpenAddGroupModal(false)}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            loading={createPatternsLoading ? "true" : "false"}
            className="btn btn-primary"
            onClick={validation.handleSubmit}
          >
            Save changes
          </button>
        </div>
      </Modal>
    </div>
  );
};
JackpotPatterns.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default JackpotPatterns;

const optionGroup = [
  {
    options: [
      { label: "one line", value: { name: "one line", slug: "1L" } },
      { label: "two lines", value: { name: "two lines", slug: "2L" } },
      { label: "three lines", value: { name: "three lines", slug: "3L" } },
      { label: "four lines", value: { name: "four lines", slug: "4L" } },
      { label: "full house", value: { name: "full house", slug: "FH" } },
    ],
  },
];
